<template>
    <v-container style="height: 100%">
        <v-empty-state
            headline="404"
            title="Page not found"
            text="The page you were looking for does not exist"
        >
            <template #actions>
                <v-btn class="button-bg-secondary" to="/home">{{
                    $t("common.gotoHome")
                }}</v-btn>
            </template>
        </v-empty-state>
        <v-btn fixed flat @click="dialog = true" class="btn"> Found it! </v-btn>
        <v-dialog v-model="dialog" max-width="450">
            <v-card>
                <v-card-title class="headline">:D</v-card-title>
                <v-card-text>
                    <canvas
                        width="400"
                        height="400"
                        ref="game"
                        class="game-canvas"
                    ></canvas>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script setup>
import { ref, watch } from "vue";

let dialog = ref(false);

let game = ref(null);

let grid = 16;
let count = 0;

let snake = {
    x: 160,
    y: 160,
    dx: grid,
    dy: 0,
    cells: [],
    maxCells: 4,
};

let apple = {
    x: 320,
    y: 320,
};

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

function loop() {
    requestAnimationFrame(loop);

    if (!game.value) {
        return;
    }

    if (++count < 6) {
        return;
    }

    count = 0;
    game.value
        .getContext("2d")
        .clearRect(0, 0, game.value.width, game.value.height);

    snake.x += snake.dx;
    snake.y += snake.dy;

    if (
        snake.x < 0 ||
        snake.x >= game.value.width ||
        snake.y < 0 ||
        snake.y >= game.value.height
    ) {
        snake.x = 160;
        snake.y = 160;
        snake.cells = [];
        snake.maxCells = 4;
        snake.dx = grid;
        snake.dy = 0;

        apple.x = getRandomInt(0, 25) * grid;
        apple.y = getRandomInt(0, 25) * grid;

        return;
    }

    snake.cells.unshift({ x: snake.x, y: snake.y });

    if (snake.cells.length > snake.maxCells) {
        snake.cells.pop();
    }

    game.value.getContext("2d").fillStyle = "red";
    game.value.getContext("2d").fillRect(apple.x, apple.y, grid - 1, grid - 1);

    game.value.getContext("2d").fillStyle = "green";
    snake.cells.forEach(function (cell, index) {
        game.value
            .getContext("2d")
            .fillRect(cell.x, cell.y, grid - 1, grid - 1);

        if (cell.x === apple.x && cell.y === apple.y) {
            snake.maxCells++;

            apple.x = getRandomInt(0, 25) * grid;
            apple.y = getRandomInt(0, 25) * grid;
        }

        for (var i = index + 1; i < snake.cells.length; i++) {
            if (cell.x === snake.cells[i].x && cell.y === snake.cells[i].y) {
                snake.x = 160;
                snake.y = 160;
                snake.cells = [];
                snake.maxCells = 4;
                snake.dx = grid;
                snake.dy = 0;

                apple.x = getRandomInt(0, 25) * grid;
                apple.y = getRandomInt(0, 25) * grid;
            }
        }
    });
}

function handleKeydown(event) {
    if (event.key === "ArrowUp" && snake.dy === 0) {
        snake.dy = -grid;
        snake.dx = 0;
    } else if (event.key === "ArrowDown" && snake.dy === 0) {
        snake.dy = grid;
        snake.dx = 0;
    } else if (event.key === "ArrowLeft" && snake.dx === 0) {
        snake.dx = -grid;
        snake.dy = 0;
    } else if (event.key === "ArrowRight" && snake.dx === 0) {
        snake.dx = grid;
        snake.dy = 0;
    }
}

watch(
    () => dialog.value,
    () => {
        if (dialog.value) {
            window.addEventListener("keydown", handleKeydown);
            requestAnimationFrame(loop);
        }
        if (!dialog.value) {
            game.value = null;
        }
    }
);
</script>

<style scoped>
.game-canvas {
    border: 1px solid black;
}

.btn {
    position: fixed;
    background-color: rgb(var(--v-theme-background));
    max-height: 5px;
    max-width: 5px;
    padding: 0 !important;
    font-size: 0.3rem;
    right: 0px;
    bottom: 0px;
    min-width: 32px;
}
</style>
