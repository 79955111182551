<template>
    <Filters @applyFilters="applyFilters" :clientId="clientId" />
    <List :users="isLoading ? [] : filteredUsers" :loading="isLoading" />
</template>

<script setup lang="ts">
import {
    getCallsForEmployee,
    getCallsTakenForEmployee,
    getDecisionMakersForEmployee,
    getRdvForEmployee,
} from "@/commons/reporting";
import { db } from "@/main";
import { collection, getDocs, query } from "firebase/firestore";
import { computed, ref } from "vue";
import Filters from "./Filters.vue";
import List from "./List.vue";

const users = ref<any[]>([]);
const isLoading = ref(false);
const filters = ref({
    FirstName: "",
    LastName: "",
    OrderId: [],
    startDate: new Date(),
    endDate: new Date(),
});

const props = defineProps({
    clientId: {
        type: String,
        required: true,
    },
});

const filteredUsers = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return users.value;
    }
    return users.value.filter((prospect: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return value.some((v) => v === prospect[key]);
            }
            if (
                typeof value === "number" &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return prospect[key] === value;
            }
            if (
                typeof value === "string" &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return prospect[key]
                    .toLowerCase()
                    .includes(value.toLowerCase());
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return value.some(
                    (v) =>
                        (v as string).toLowerCase() ===
                        prospect[key].toLowerCase()
                );
            }
            return true;
        })
    );
});

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    await setupFirestoreListener();
}

async function addCountsToUsers() {
    users.value = await Promise.all(
        users.value.map(async (user) => {
            let totalCalls = 0;
            let totalPickedUp = 0;
            let totalDecisionMakers = 0;
            let totalRdv = 0;

            totalCalls += await getCallsForEmployee(
                user.id,
                filters.value.startDate,
                filters.value.endDate,
                filters.value.OrderId,
                [props.clientId]
            );
            totalPickedUp += await getCallsTakenForEmployee(
                user.id,
                filters.value.startDate,
                filters.value.endDate,
                filters.value.OrderId,
                [props.clientId]
            );
            totalDecisionMakers += await getDecisionMakersForEmployee(
                user.id,
                filters.value.startDate,
                filters.value.endDate,
                filters.value.OrderId,
                [props.clientId]
            );
            totalRdv += await getRdvForEmployee(
                user.id,
                filters.value.startDate,
                filters.value.endDate,
                filters.value.OrderId,
                [props.clientId]
            );

            return {
                ...user,
                totalCalls: totalCalls || "0",
                totalPickedUp: totalPickedUp || "0",
                totalDecisionMakers: totalDecisionMakers || "0",
                totalRdv: totalRdv || "0",
                rateOnPickedUp: totalPickedUp
                    ? Number(((totalRdv / totalPickedUp) * 100).toFixed(2)) + ""
                    : "0",
                rateOnDecisionMakers: totalDecisionMakers
                    ? Number(
                          ((totalRdv / totalDecisionMakers) * 100).toFixed(2)
                      ) + ""
                    : "0",
            };
        })
    );
    isLoading.value = false;
}

async function setupFirestoreListener() {
    isLoading.value = true;
    users.value = [];
    const ordersCollection = collection(db, "Users");
    const q = query(ordersCollection);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
        users.value.push({
            id: doc.id,
            FirstName: doc.data().FirstName,
            LastName: doc.data().LastName.toUpperCase(),
        });
    });
    await addCountsToUsers();
    users.value = users.value.filter((user) => user.totalCalls > 0);
}
</script>
