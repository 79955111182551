<template>
    <Dialog
        :title="$t('popupMyDayReport.title')"
        width="60%"
        @close="$emit('close')"
    >
        <template #text>
            <div>
                <OrdersCard class="pb-5" @orders="handleOrders" />
                <Span class="bold">{{ $t("popupMyDayReport.report") }}</Span>
                <InputReport
                    class="pt-3"
                    :dataOrders="orders"
                    :isLoading="!ordersLoaded"
                    @inputData="handleInputData"
                />
                <v-row justify="space-between" style="height: 100%">
                    <v-col cols="auto">
                        <Span class="bold">{{
                            $t("popupMyDayReport.isHalfDay")
                        }}</Span>
                    </v-col>
                    <v-col cols="auto pbt-0">
                        <RadioButton
                            class="mr-4"
                            :text="$t('calls.yes')"
                            :selected="isHalfDay === true"
                            @click="isHalfDay = true"
                        />
                        <RadioButton
                            :text="$t('calls.no')"
                            :selected="isHalfDay === false"
                            @click="isHalfDay = false"
                        />
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #actions>
            <v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                        class="button-bg-secondary bold-button"
                        @click="validationDialog = true"
                        :disabled="!dataToSubmit.length"
                        :text="$t('popupMyDayReport.send')"
                        prepend-icon=""
                    />
                </v-col>
            </v-row>
            <ValidationReport
                v-model="validationDialog"
                :dataToSubmit="dataToSubmit"
                :isHalfDay="isHalfDay"
                @success="sendSuccess"
                @close="validationDialog = false"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { Dialog, Span } from "@/components/customVuetify";
import RadioButton from "@/components/customVuetify/RadioButton.vue";
import { useSnackbar } from "@/composables/useSnackbar";
import "@/vue3-treeselect.min.css";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import InputReport from "./InputReport.vue";
import OrdersCard from "./OrdersCardFinishDay.vue";
import ValidationReport from "./ValidationReport.vue";

const { t: $t } = useI18n();
const emit = defineEmits(["close", "confirm"]);
const { showSnackbar } = useSnackbar();

const isHalfDay = ref(false);
const validationDialog = ref(false);
const orders = ref<any>([]);
const ordersLoaded = ref(false);
const dataToSubmit = ref<any>([]);

function handleOrders(receivedOrders: any) {
    orders.value = receivedOrders;
    ordersLoaded.value = true;
}

function handleInputData(receivedData: any) {
    dataToSubmit.value = receivedData;
}

function sendSuccess() {
    showSnackbar(
        $t("popupMyDayReport.sendReportSuccess"),
        3000,
        "var(--ys-green)"
    );
    emit("close");
}
</script>
