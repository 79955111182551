export interface Division {
    [key: string]: {
        description: string;
        section: string;
    };
}

export const DivisionCodes = {
    "01": {
        description:
            "Culture et production animale, chasse et services annexes",
        section: "A",
    },
    "02": {
        description: "Sylviculture et exploitation forestière",
        section: "A",
    },
    "03": {
        description: "Pêche et aquaculture",
        section: "A",
    },
    "05": {
        description: "Extraction de houille et de lignite",
        section: "B",
    },
    "06": {
        description: "Extraction d'hydrocarbures",
        section: "B",
    },
    "07": {
        description: "Extraction de minerais métalliques",
        section: "B",
    },
    "08": {
        description: "Autres industries extractives",
        section: "B",
    },
    "09": {
        description: "Services de soutien aux industries extractives",
        section: "B",
    },
    "10": {
        description: "Industries alimentaires",
        section: "C",
    },
    "11": {
        description: "Fabrication de boissons",
        section: "C",
    },
    "12": {
        description: "Fabrication de produits à base de tabac",
        section: "C",
    },
    "13": {
        description: "Fabrication de textiles",
        section: "C",
    },
    "14": {
        description: "Industrie de l'habillement",
        section: "C",
    },
    "15": {
        description: "Industrie du cuir et de la chaussure",
        section: "C",
    },
    "16": {
        description:
            "Travail du bois et fabrication d'articles en bois et en liège, à l'exception des meubles ; fabrication d'articles en vannerie et sparterie",
        section: "C",
    },
    "17": {
        description: "Industrie du papier et du carton",
        section: "C",
    },
    "18": {
        description: "Imprimerie et reproduction d'enregistrements",
        section: "C",
    },
    "19": {
        description: "Cokéfaction et raffinage",
        section: "C",
    },
    "20": {
        description: "Industrie chimique",
        section: "C",
    },
    "21": {
        description: "Industrie pharmaceutique",
        section: "C",
    },
    "22": {
        description: "Fabrication de produits en caoutchouc et en plastique",
        section: "C",
    },
    "23": {
        description: "Fabrication d'autres produits minéraux non métalliques",
        section: "C",
    },
    "24": {
        description: "Métallurgie",
        section: "C",
    },
    "25": {
        description:
            "Fabrication de produits métalliques, à l'exception des machines et des équipements",
        section: "C",
    },
    "26": {
        description:
            "Fabrication de produits informatiques, électroniques et optiques",
        section: "C",
    },
    "27": {
        description: "Fabrication d'équipements électriques",
        section: "C",
    },
    "28": {
        description: "Fabrication de machines et équipements n.c.a.",
        section: "C",
    },
    "29": {
        description: "Industrie automobile",
        section: "C",
    },
    "30": {
        description: "Fabrication d'autres matériels de transport",
        section: "C",
    },
    "31": {
        description: "Fabrication de meubles",
        section: "C",
    },
    "32": {
        description: "Autres industries manufacturières",
        section: "C",
    },
    "33": {
        description: "Réparation et installation de machines et d'équipements",
        section: "C",
    },
    "35": {
        description:
            "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
        section: "D",
    },
    "36": {
        description: "Captage, traitement et distribution d'eau",
        section: "E",
    },
    "37": {
        description: "Collecte et traitement des eaux usées",
        section: "E",
    },
    "38": {
        description:
            "Collecte, traitement et élimination des déchets ; récupération",
        section: "E",
    },
    "39": {
        description: "Dépollution et autres services de gestion des déchets",
        section: "E",
    },
    "41": {
        description: "Construction de bâtiments",
        section: "F",
    },
    "42": {
        description: "Génie civil",
        section: "F",
    },
    "43": {
        description: "Travaux de construction spécialisés",
        section: "F",
    },
    "45": {
        description: "Commerce et réparation d'automobiles et de motocycles",
        section: "G",
    },
    "46": {
        description:
            "Commerce de gros, à l'exception des automobiles et des motocycles",
        section: "G",
    },
    "47": {
        description:
            "Commerce de détail, à l'exception des automobiles et des motocycles",
        section: "G",
    },
    "49": {
        description: "Transports terrestres et transport par conduites",
        section: "H",
    },
    "50": {
        description: "Transports par eau",
        section: "H",
    },
    "51": {
        description: "Transports aériens",
        section: "H",
    },
    "52": {
        description: "Entreposage et services auxiliaires des transports",
        section: "H",
    },
    "53": {
        description: "Activités de poste et de courrier",
        section: "H",
    },
    "55": {
        description: "Hébergement",
        section: "I",
    },
    "56": {
        description: "Restauration",
        section: "I",
    },
    "58": {
        description: "Édition",
        section: "J",
    },
    "59": {
        description:
            "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale",
        section: "J",
    },
    "60": {
        description: "Programmation et diffusion",
        section: "J",
    },
    "61": {
        description: "Télécommunications",
        section: "J",
    },
    "62": {
        description: "Programmation, conseil et autres activités informatiques",
        section: "J",
    },
    "63": {
        description: "Services d'information",
        section: "J",
    },
    "64": {
        description:
            "Activités des services financiers, hors assurance et caisses de retraite",
        section: "K",
    },
    "65": {
        description: "Assurance",
        section: "K",
    },
    "66": {
        description:
            "Activités auxiliaires de services financiers et d'assurance",
        section: "K",
    },
    "68": {
        description: "Activités immobilières",
        section: "L",
    },
    "69": {
        description: "Activités juridiques et comptables",
        section: "M",
    },
    "70": {
        description: "Activités des sièges sociaux ; conseil de gestion",
        section: "M",
    },
    "71": {
        description:
            "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques",
        section: "M",
    },
    "72": {
        description: "Recherche-développement scientifique",
        section: "M",
    },
    "73": {
        description: "Publicité et études de marché",
        section: "M",
    },
    "74": {
        description:
            "Autres activités spécialisées, scientifiques et techniques",
        section: "M",
    },
    "75": {
        description: "Activités vétérinaires",
        section: "M",
    },
    "77": {
        description: "Activités de location et location-bail",
        section: "N",
    },
    "78": {
        description: "Activités liées à l'emploi",
        section: "N",
    },
    "79": {
        description:
            "Activités des agences de voyage, voyagistes, services de réservation et activités connexes",
        section: "N",
    },
    "80": {
        description: "Enquêtes et sécurité",
        section: "N",
    },
    "81": {
        description: "Services relatifs aux bâtiments et aménagement paysager",
        section: "N",
    },
    "82": {
        description:
            "Activités administratives et autres activités de soutien aux entreprises",
        section: "N",
    },
    "84": {
        description:
            "Administration publique et défense ; sécurité sociale obligatoire",
        section: "O",
    },
    "85": {
        description: "Enseignement",
        section: "P",
    },
    "86": {
        description: "Activités pour la santé humaine",
        section: "Q",
    },
    "87": {
        description: "Hébergement médico-social et social",
        section: "Q",
    },
    "88": {
        description: "Action sociale sans hébergement",
        section: "Q",
    },
    "90": {
        description: "Activités créatives, artistiques et de spectacle",
        section: "R",
    },
    "91": {
        description:
            "Bibliothèques, archives, musées et autres activités culturelles",
        section: "R",
    },
    "92": {
        description: "Organisation de jeux de hasard et d'argent",
        section: "R",
    },
    "93": {
        description: "Activités sportives, récréatives et de loisirs",
        section: "R",
    },
    "94": {
        description: "Activités des organisations associatives",
        section: "S",
    },
    "95": {
        description:
            "Réparation d'ordinateurs et de biens personnels et domestiques",
        section: "S",
    },
    "96": {
        description: "Autres services personnels",
        section: "S",
    },
    "97": {
        description:
            "Activités des ménages en tant qu'employeurs de personnel domestique",
        section: "T",
    },
    "98": {
        description:
            "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
        section: "T",
    },
    "99": {
        description:
            "Activités des organisations et organismes extraterritoriaux",
        section: "U",
    },
};
