<template>
    <v-app-bar location="bottom" color="white" flat class="bottom-bar">
        <v-row justify="space-between">
            <v-btn
                class="button-bg-secondary flip-icon"
                prependIcon="mdi-phone-forward"
                :text="$t('calls.lastCall')"
                :disabled="!canGoToLastCall"
                @click="goToLastCall"
            />
            <v-col cols="auto">
                <v-btn
                    class="button-bg-white"
                    :text="$t('calls.backToMenu')"
                    @click="$router.push({ name: 'calls' })"
                />
                <v-btn
                    class="button-bg-white"
                    :text="$t('calls.call_script')"
                    @click="openInNewTab"
                    style="margin-left: 10px"
                />
            </v-col>
            <v-btn
                class="button-bg-secondary"
                prependIcon="mdi-phone-forward"
                :text="$t('calls.nextCall')"
                :disabled="!canGoToNextCall"
                @click="goToNextCall"
            />
        </v-row>
    </v-app-bar>
</template>

<script setup lang="ts">
import { db } from "@/main";
import router from "@/router";
import { store } from "@/store";
import { getAuth } from "firebase/auth";
import { addDoc, collection, getDocs, limit, query } from "firebase/firestore";
import { computed, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
const { t: $t } = useI18n();
const emit = defineEmits(["update"]);
let scriptId: any = null;
const canGoToNextCall = computed(() => store.getters.canGoToNextCall);
const canGoToLastCall = computed(() => store.getters.canGoToLastCall);

const props = defineProps({
    orderId: String,
});

async function openInNewTab() {
    if (!props.orderId) {
        console.error("Missing required param 'orderId'");
        return;
    }

    try {
        const scriptQuery = query(
            collection(db, `Orders/${props.orderId}/Scripts`),
            limit(1)
        );
        const script = await getDocs(scriptQuery);

        if (script.empty) {
            console.error("No scripts found, creating a new script");
            scriptId = await createNewScript(props.orderId);
        } else {
            scriptId = script.docs[0].id;
        }

        if (!scriptId) {
            console.error("Missing required param 'scriptId'");
            return;
        }

        const url = router.resolve({
            name: "callScript",
            params: { orderId: props.orderId, scriptId },
        }).href;
        window.open(url, "_blank");
    } catch (error) {
        console.error("Error opening script in new tab:", error);
    }
}

async function createNewScript(orderId: string): Promise<string> {
    const auth = getAuth();
    if (!auth.currentUser) {
        console.error("User not logged in");
        return "";
    }

    const newScriptRef = collection(db, `Orders/${orderId}/Scripts`);
    const userId = auth.currentUser.uid;
    const newScriptData = {
        Intro: $t("orders.script.introductionContent"),
        ValueProposition: $t("orders.script.valuePropositionContent"),
        ObjectionHandler: $t("orders.script.objectionHandlerContent"),
        MandatoryHabits: $t("orders.script.mandatoryHabitsContent"),
        DesiredInterlocutor: $t("orders.script.desiredInterlocutorContent"),
        EndOfCall: $t("orders.script.endOfCallContent"),
        Subject: $t("orders.script.subjectContent"),
        KeyArguments: $t("orders.script.keyArgumentsContent"),
        CreatorID: userId,
        DateOfCreation: new Date(),
        LastModificationDate: new Date(),
        LastModifierID: userId,
        NumberOfModifications: 0,
    };

    try {
        const docRef = await addDoc(newScriptRef, newScriptData);
        return docRef.id;
    } catch (error) {
        console.error("Error creating new script:", error);
        throw error;
    }
}

function goToNextCall() {
    store.commit("incrementCurrentProspectIndex");
    const nextProspectId =
        store.state.prospectIds[store.state.currentProspectIndex].prospectId;
    emit("update", "slide-right");
    router.replace({ name: "callInterface", params: { id: nextProspectId } });
}

function goToLastCall() {
    store.commit("decrementCurrentProspectIndex");
    const lastProspectId =
        store.state.prospectIds[store.state.currentProspectIndex].prospectId;
    emit("update", "slide-left");
    router.replace({ name: "callInterface", params: { id: lastProspectId } });
}

function handleKeydown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    if (
        (target.tagName === "INPUT" && !target.readOnly) ||
        (target.tagName === "TEXTAREA" && !target.readOnly)
    ) {
        return;
    }

    switch (event.key) {
        case "ArrowRight":
            if (canGoToNextCall.value) {
                goToNextCall();
            }
            break;
        case "ArrowLeft":
            if (canGoToLastCall.value) {
                goToLastCall();
            }
            break;
    }
}

onMounted(() => {
    window.addEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
    window.removeEventListener("keydown", handleKeydown);
    store.commit("clearProspectIds");
    store.commit("clearProspectIndex");
});
</script>

<style scoped>
.v-row {
    padding: 0 3vw;
}

.bottom-bar {
    color: rgb(var(--v-theme-text)) !important;
    background-color: rgb(var(--v-theme-bar)) !important;
    box-shadow: 0 1px 4px -1px rgb(var(--v-theme-text)) !important;
}
</style>
