<template>
    <v-expansion-panels v-if="reversedCallHistory.length">
        <v-expansion-panel>
            <v-expansion-panel-title :static="true" class="title">
                {{ $t("calls.callHistory") }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <div
                    class="comments-div"
                    v-for="(call, index) in reversedCallHistory"
                    :key="index"
                >
                    <v-divider />
                    <v-row justify="space-between">
                        <v-col cols="auto" class="pb-0 pt-0">
                            <v-row>
                                <v-col cols="auto" class="no-padding-bot">
                                    <Span class="bold">
                                        {{ $t("calls.call") }}
                                        {{
                                            reversedCallHistory.length - index
                                        }}</Span
                                    >
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="auto"
                                    style="padding-top: 0; padding-bottom: 0"
                                >
                                    <Span>
                                        {{
                                            formatTimestampTommhhddmmyyyy(
                                                call.date
                                            )
                                        }}</Span
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <v-btn
                                v-if="
                                    call.date.toMillis() >
                                    new Date().getTime() - 600000
                                "
                                :class="
                                    readyToEdit[call.id]
                                        ? 'button-bg-secondary'
                                        : 'button-bg-white'
                                "
                                style="border-color: transparent !important"
                                icon="mdi-pencil-outline"
                                flat
                                :loading="isLoadingEdit"
                                @click.stop="
                                    readyToEdit[call.id]
                                        ? editCall(call)
                                        : (readyToEdit[call.id] = true)
                                "
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                :class="
                                    readyToDelete[call.id]
                                        ? 'button-bg-red'
                                        : 'button-red'
                                "
                                icon="mdi-trash-can-outline"
                                flat
                                :loading="isLoadingDelete"
                                @click.stop="
                                    readyToDelete[call.id]
                                        ? deleteCall(call.id.toString())
                                        : (readyToDelete[call.id] = true)
                                "
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" class="pt-0 pb-0">
                            <v-row justify="space-between">
                                <v-col cols="auto" class="pt-0 pb-0">
                                    <Span>{{ $t("calls.pickedUp") }}</Span>
                                </v-col>
                                <v-col cols="auto" class="pt-0 pb-0">
                                    <Checkbox
                                        v-model="call.pickedUp"
                                        :readonly="!readyToEdit[call.id]"
                                        class="disable-message"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="pt-0 pb-0">
                            <v-row justify="space-between">
                                <v-col cols="auto" class="pt-0 pb-0">
                                    <Span>{{ $t("calls.decisionMaker") }}</Span>
                                </v-col>
                                <v-col cols="auto" class="pt-0 pb-0">
                                    <Checkbox
                                        v-model="call.decisionMaker"
                                        :readonly="!readyToEdit[call.id]"
                                        class="disable-message"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="pb-4">
                        <v-col cols="3" class="no-padding-bot">
                            <TextArea
                                v-model="call.employeeId"
                                :label="$t('calls.operator')"
                                :readonly="true"
                                :disableMessage="true"
                            />
                        </v-col>
                        <v-col cols="3" class="no-padding-bot">
                            <TextArea
                                v-model="call.nextAction"
                                :label="$t('calls.nextActionText')"
                                :readonly="true"
                                :disableMessage="true"
                            />
                        </v-col>
                        <v-col cols="6" class="no-padding-bot">
                            <TextArea
                                v-model="call.comments"
                                :label="$t('calls.comment')"
                                :readonly="!readyToEdit[call.id]"
                                :disableMessage="true"
                            />
                        </v-col>
                    </v-row>
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup lang="ts">
import {
    formatTimestampTommhhddmmyyyy,
    getTrigram,
} from "@/commons/convertion";
import { getUserLastFirstNameFromId } from "@/commons/firebase";
import { Span } from "@/components/customVuetify";
import Checkbox from "@/components/customVuetify/Checkbox.vue";
import TextArea from "@/components/customVuetify/TextArea.vue";
import { db } from "@/main";
import { CallHistory, NextAction } from "@/types";
import {
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    updateDoc,
} from "firebase/firestore";
import { computed, onMounted, ref, toRaw, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t: $t } = useI18n();

const props = defineProps<{
    orderId: string;
}>();
const emit = defineEmits(["deleted"]);

const readyToDelete = ref<any>({});
const readyToEdit = ref<any>({});

const isLoadingEdit = ref(false);
const isLoadingDelete = ref(false);

const route = useRoute();
const prospectId = ref(route.params.id as string);

const callHistory = ref<CallHistory[]>([]);
const reversedCallHistory = computed(() => {
    return toRaw([...callHistory.value].reverse());
});

const getNextActionName = (nextAction: NextAction) => {
    switch (nextAction) {
        case 1:
            return $t("calls.nextAction.call");
        case 2:
            return $t("calls.nextAction.meeting");
        case 3:
            return $t("calls.nextAction.mail");
        case 4:
            return $t("calls.nextAction.ko");
        default:
            return "";
    }
};

async function editCall(call: any) {
    isLoadingEdit.value = true;
    const docRef = doc(
        db,
        "Orders",
        props.orderId,
        "Prospects",
        prospectId.value,
        "Calls",
        call.id.toString()
    );
    await updateDoc(docRef, {
        Comments: call.comments,
        DecisionMaker: call.decisionMaker,
        PickedUp: call.pickedUp,
    });
    isLoadingEdit.value = false;
    readyToEdit.value[call.id] = false;
}

async function deleteCall(callId: string) {
    isLoadingDelete.value = true;
    try {
        if (!props.orderId || !prospectId.value) {
            return;
        }

        const callDocRef = doc(
            db,
            "Orders",
            props.orderId,
            "Prospects",
            prospectId.value,
            "Calls",
            callId
        );

        await deleteDoc(callDocRef);
        emit("deleted");
    } catch (error) {
        console.error(error);
    } finally {
        isLoadingDelete.value = false;
    }
}

async function getCallsHistory(ProspectId: string) {
    try {
        if (!props.orderId || !prospectId.value) {
            return;
        }

        const callsCollectionRef = collection(
            db,
            "Orders",
            props.orderId,
            "Prospects",
            ProspectId,
            "Calls"
        );

        onSnapshot(callsCollectionRef, async (snapshot) => {
            const callsPromises = snapshot.docs.map(async (doc) => {
                const callData = doc.data();
                if (callData) {
                    let fullName = await getUserLastFirstNameFromId(
                        callData.EmployeeId
                    );
                    let [lastName, firstName] = fullName.split(" ");
                    return {
                        id: doc.id,
                        pickedUp: callData.PickedUp,
                        decisionMaker: callData.DecisionMaker,
                        comments:
                            callData.Comments == null || callData.Comments == ""
                                ? "N/A"
                                : callData.Comments,
                        employeeId: getTrigram(firstName, lastName) || "N/A",
                        nextAction:
                            getNextActionName(
                                callData.NextAction as NextAction
                            ) || "N/A",
                        date:
                            callData.Date == null || callData.Date == ""
                                ? "N/A"
                                : callData.Date,
                    };
                }
            });

            const calls = (await Promise.all(callsPromises)).filter(Boolean);

            callHistory.value = calls
                .map((call: any) => ({ ...call, id: parseInt(call.id) }))
                .sort((a, b) => a.id - b.id);
        });
    } catch (error) {
        console.error(error);
    }
}

watch(
    () => route.params.id,
    async (newProspectId) => {
        prospectId.value = newProspectId as string;
        await getCallsHistory(prospectId.value);
    },
    { immediate: true }
);

onMounted(() => {
    getCallsHistory(prospectId.value);
});
</script>

<style scoped>
.title {
    font-size: 1.6rem;
    font-weight: bold;
    color: rgb(var(--v-theme-text));
}
</style>
