<template>
    <Dialog
        :title="$t('login.changePassword')"
        width="35%"
        @submit="changePassword"
        @close="close"
    >
        <template #text>
            <PopupTextField
                v-model="currentPassword"
                :label="$t('login.currentPassword')"
                :type="showCurrentPassword ? 'text' : 'password'"
                :append-inner-icon="
                    showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append-inner="showCurrentPassword = !showCurrentPassword"
                :rules="[rules.required]"
            />
            <PopupTextField
                v-model="newPassword"
                :label="$t('login.newPassword')"
                :type="showNewPassword ? 'text' : 'password'"
                :append-inner-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showNewPassword = !showNewPassword"
                :rules="[rules.required, rules.password]"
            />
            <PopupTextField
                v-model="repeatNewPassword"
                :label="$t('login.repeatNewPassword')"
                :type="showRepeatNewPassword ? 'text' : 'password'"
                :append-inner-icon="
                    showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append-inner="
                    showRepeatNewPassword = !showRepeatNewPassword
                "
                :rules="[rules.required, rules.password, (v: any) => v === newPassword || 'Passwords must match']"
            />
        </template>
        <template #actions>
            <v-btn
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
            >
                <template v-slot:prepend>
                    <v-icon>mdi-check</v-icon>
                </template>
                {{ $t("common.confirm") }}</v-btn
            >
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from "@/components/customVuetify/Dialog.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import rules from "@/rules";
import {
    EmailAuthProvider,
    getAuth,
    reauthenticateWithCredential,
    updatePassword,
} from "firebase/auth";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const currentPassword = ref("");
const newPassword = ref("");
const repeatNewPassword = ref("");
const showCurrentPassword = ref(false);
const showNewPassword = ref(false);
const showRepeatNewPassword = ref(false);
const isLoading = ref(false);

const emit = defineEmits(["close", "success"]);

function close() {
    currentPassword.value = "";
    newPassword.value = "";
    repeatNewPassword.value = "";
    showCurrentPassword.value = false;
    showNewPassword.value = false;
    showRepeatNewPassword.value = false;
    emit("close");
}

const changePassword = async () => {
    if (newPassword.value !== repeatNewPassword.value) {
        return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user || !user.email) {
        return;
    }
    const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword.value
    );

    try {
        isLoading.value = true;
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword.value);
        emit("success");
        close();
    } catch (error: any) {
        alert(error.message);
    } finally {
        isLoading.value = false;
    }
};
</script>

<style scoped>
.v-btn {
    margin-left: auto;
    margin-right: auto;
}
</style>
