<template>
    <Dialog
        :title="$t('extracts.callDetails')"
        width="75vw"
        :isLoading="isDataLoading"
        :isActions="false"
        @close="close"
    >
        <template #text>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.firstName"
                        v-model="call.FirstName"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.lastName"
                        v-model="call.LastName"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.phone"
                        v-model="call.Phone"
                        :readonly="readonly"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.call"
                        v-model="call.Mail"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.position"
                        v-model="call.Position"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        textArea
                        label="extracts.company"
                        v-model="call.Company"
                        :readonly="true"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.callDate"
                        v-model="call.CallDate"
                        :readonly="true"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.employee"
                        v-model="call.Employee"
                        :readonly="true"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        textArea
                        label="extracts.order"
                        v-model="call.Order"
                        :readonly="true"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <CardTextField
                        pbt0
                        fieldSize="10"
                        label="extracts.comment"
                        v-model="call.Comments"
                        fullRow
                        textArea
                        :readonly="readonly"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-spacer />
                <v-col cols="4" class="text-end">
                    <v-btn
                        v-if="readonly"
                        class="button-bg-white"
                        @click="readonly = false"
                        prependIcon="mdi-pencil-outline"
                        :text="$t('common.modifyInfos')"
                    />
                    <v-btn
                        v-else
                        class="button-bg-secondary"
                        :loading="isLoading"
                        @click="updateData"
                        prependIcon="mdi-check"
                        :text="$t('common.validateModifications')"
                    />
                </v-col>
            </v-row>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import CardTextField from "@/components/customVuetify/CardTextField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import { db } from "@/main";
import { doc, updateDoc } from "firebase/firestore";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const readonly = ref(true);
const isLoading = ref(false);
const isDataLoading = ref(false);

const emit = defineEmits(["close"]);

const props = defineProps({
    callProp: {
        type: Object,
        required: false,
        default: () => ({}),
    },
});

const call = ref<any>({});

watch(
    () => props.callProp,
    (newVal) => {
        call.value = { ...newVal };
    },
    { immediate: true }
);

function close() {
    readonly.value = true;
    isLoading.value = false;
    emit("close");
}

async function updateData() {
    isLoading.value = true;
    const docRef = doc(
        db,
        "Orders",
        call.value.OrderId,
        "Prospects",
        call.value.ProspectId,
        "Calls",
        call.value.DocId
    );

    await updateDoc(docRef, {
        FirstName: call.value.FirstName,
        LastName: call.value.LastName,
        Phone: call.value.Phone,
        Mail: call.value.Mail,
        Position: call.value.Position,
        Comments: call.value.Comments,
    });
    isLoading.value = false;
    readonly.value = true;
}
</script>

<style scoped>
.v-row {
    height: 100% !important;
}

.v-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
</style>
