<template>
    <AutocompleteField
        v-model="selectedDivision"
        :items="filteredDivisionCodes"
        :item-title="displayText"
        item-value="code"
        :label="$t('orders.division')"
        clearable
    />
</template>
<script setup lang="ts">
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import { DivisionCodes } from "@/types";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t: $t } = useI18n();
const props = defineProps({
    sections: {
        type: String,
        default: () => "",
    },
    modelValue: {
        type: Array as () => string[],
        default: () => [],
    },
});
const emit = defineEmits(["update:modelValue", "newval"]);
const selectedDivision = ref<string[]>(props.modelValue);

const filteredDivisionCodes = computed(() => {
    return Object.entries(DivisionCodes)
        .filter(([, { section }]) => section === props.sections)
        .map(([code, { description }]) => ({
            code,
            description,
        }));
});

const displayText = computed(
    () => (division: { code: string; description: string } | null) =>
        division ? `${division.code} - ${division.description}` : ""
);

watch(
    () => props.modelValue,
    (newValue) => {
        selectedDivision.value = newValue;
    }
);

watch(
    () => filteredDivisionCodes.value,
    () => {
        selectedDivision.value = selectedDivision.value.filter((val) =>
            filteredDivisionCodes.value.some(
                (classobj) => classobj.code === val
            )
        );
        emit("newval", selectedDivision.value);
    }
);

watch(
    () => selectedDivision.value,
    (newValue) => {
        emit("update:modelValue", newValue);
    }
);
</script>
