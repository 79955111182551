<template>
    <List
        :items="calls"
        :headers="headers"
        @rowClicked="showCall"
        :sort-by="[{ key: 'Company', order: 'asc' }]"
        :loading="loading"
    />
    <CallDetails
        :callProp="selectedCall"
        v-model="showDialog"
        @close="showDialog = false"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import CallDetails from "./CallDetails.vue";

const { t: $t } = useI18n();

defineProps({
    calls: Array as () => DocumentData[],
    loading: Boolean,
});

const selectedCall: any = ref();
const showDialog = ref(false);

function showCall(call: any) {
    selectedCall.value = call;
    showDialog.value = true;
}

const headers = [
    { title: $t("extracts.company"), value: "Company" },
    { title: $t("extracts.callNumber"), value: "CallNumber" },
    { title: $t("extracts.postalCode"), value: "PostalCode" },
    { title: $t("extracts.city"), value: "City" },
    { title: $t("extracts.contact"), value: "Contact" },
    { title: $t("extracts.comment"), value: "Comments" },
    { title: $t("extracts.employee"), value: "Employee" },
];
</script>
