<template>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Name"
                :disableMessage="true"
                :label="$t('db.prospectName')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.Address"
                :disableMessage="true"
                :label="$t('db.address')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.City"
                :disableMessage="true"
                :label="$t('db.city')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.PostalCode"
                :disableMessage="true"
                :label="$t('db.postalCode')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <ApeField
                v-model="filters.APECode"
                :label="$t('db.apeCode')"
                :disableMessage="true"
                multiple
            />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <Span style="font-weight: bold">{{ prospectsNumber }}&nbsp;</Span
            ><Span>{{ $t("db.prospectsInDB") }}</Span>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="2">
            <AutocompleteField
                v-model="filters.OrderId"
                :items="orders"
                :label="$t('calls.order')"
                item-title="name"
                item-value="id"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.returnToOrders')"
                prepend-icon="mdi-arrow-left"
                @click="$router.push({ name: 'db' })"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { getActiveOrderListNameId } from "@/commons/firebase";
import ApeField from "@/components/ApeField.vue";
import { Span } from "@/components/customVuetify";
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import TextField from "@/components/customVuetify/TextField.vue";
import { store } from "@/store";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["applyFilters"]);

const filters = ref({
    Name: "",
    Address: "",
    City: "",
    PostalCode: "",
    APECode: null as unknown as any,
    OrderId: null,
});

const orders = ref<any[]>([]);

defineProps({
    prospectsNumber: Number,
});

watch(
    () => store.state.currentClient,
    async (client) => {
        if (client) {
            const result = await getActiveOrderListNameId();
            orders.value = Array.isArray(result.value) ? result.value : [];
            filters.value.OrderId = null;
        }
    }
);

onMounted(async () => {
    emit("applyFilters", filters.value);
    const result = await getActiveOrderListNameId();
    orders.value = Array.isArray(result.value) ? result.value : [];
});
</script>
