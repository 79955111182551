<template>
    <List
        :items="prospects"
        :headers="headers"
        :sort-by="[{ key: 'Name', order: 'asc' }]"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { Prospect } from "@/types";
import { DocumentData } from "firebase/firestore";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

defineProps({
    prospects: Array as () => Prospect[] | DocumentData[],
});

const headers = [
    { title: $t("prospects.name"), value: "Name" },
    { title: $t("db.address"), value: "Address" },
    { title: $t("db.city"), value: "City" },
    { title: $t("prospects.department"), value: "Department" },
    { title: $t("db.postalCode"), value: "PostalCode" },
    { title: $t("db.apeCode"), value: "APECode" },
];
</script>
