<template>
    <AutocompleteField
        v-model="selectedApe"
        :items="filteredApeCodes"
        :item-title="displayText"
        item-value="code"
        :label="$t('common.APECode')"
        clearable
    />
</template>
<script setup lang="ts">
import { APECodes, Ape } from "@/types";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import AutocompleteField from "./customVuetify/AutocompleteField.vue";

const { t: $t } = useI18n();

const props = defineProps({
    classes: {
        type: Array as () => string[],
        default: () => [],
    },
    restricted: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Array as () => string[],
        default: () => [],
    },
});

const emit = defineEmits(["update:modelValue", "newval"]);
const selectedApe = ref<string[]>(props.modelValue);

const formatClassToApe = (classCode: string) =>
    classCode.replace(".", "") + "[A-Z]";

const filteredApeCodes = computed(() => {
    if (
        (!props.classes || props.classes.length === 0) &&
        props.restricted === false
    ) {
        return Object.entries(APECodes).map(([code, description]) => ({
            code,
            description,
        }));
    }
    const classPatterns = props.classes
        .map(formatClassToApe)
        .map((pattern) => new RegExp("^" + pattern));
    return Object.entries(APECodes)
        .filter(([code]) => classPatterns.some((pattern) => pattern.test(code)))
        .map(([code, description]) => ({
            code,
            description,
        }));
});

const displayText = computed(
    () => (ape: Ape | null) => ape ? `${ape.code} - ${ape.description}` : ""
);

watch(
    () => props.modelValue,
    (newValue) => {
        selectedApe.value = newValue;
    }
);

watch(
    () => filteredApeCodes.value,
    () => {
        selectedApe.value = selectedApe.value.filter((val) =>
            filteredApeCodes.value.some((classobj) => classobj.code === val)
        );
        emit("newval", selectedApe.value);
    }
);

watch(
    () => selectedApe.value,
    (newValue) => {
        emit("update:modelValue", newValue);
    }
);
</script>
