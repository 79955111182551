<template>
    <v-app-bar location="bottom" color="white" flat class="bottom-bar">
        <v-row justify="center">
            <v-col cols="auto">
                <v-btn
                    v-if="readonly"
                    class="button-bg-white"
                    @click="toggleReadonly"
                    prependIcon="mdi-pencil-outline"
                    :text="$t('common.modifyInfos')"
                    style="margin-left: 10px"
                />
                <v-btn
                    v-else-if="admin && !readonly"
                    class="button-bg-secondary"
                    :loading="isLoading"
                    @click="handleUpdateAndToggleReadonly"
                    prependIcon="mdi-check"
                    :text="$t('common.submitChangeForValidation')"
                    style="margin-left: 10px"
                />
                <v-btn
                    v-else
                    class="button-bg-secondary"
                    :loading="isLoading"
                    @click="handleUpdateAndToggleReadonly"
                    prependIcon="mdi-check"
                    :text="$t('common.validateModifications')"
                    style="margin-left: 10px"
                />
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script setup lang="ts">
import { ref } from "vue";

const emit = defineEmits(["updateData", "toggleReadonly", "updateComplete"]);

const readonly = ref(true);
const isLoading = ref(false);
const admin = ref(false);

function toggleReadonly() {
    emit("toggleReadonly");
    readonly.value = !readonly.value;
}

function handleUpdateAndToggleReadonly() {
    emit("updateData");
    toggleReadonly();
}

// Si utilisé, a bouger dans index.vue

// const canGoToNextScript = computed(() => store.getters.canGoToNextScript);
// const canGoToLastScript = computed(() => store.getters.canGoToLastScript);

/*async function createAndGoToNewScript() {
    let lastScript =
        store.state.scriptIds.length > 0
            ? store.state.scriptIds[store.state.scriptIds.length - 1]
            : { scriptId: "0" };

    const userId = auth.currentUser ? auth.currentUser.uid : "";

    const lastScriptId = lastScript.scriptId || "0";
    const newScriptId = (parseInt(lastScriptId) + 1).toString();
    const newScriptRef = doc(db, `Orders/${orderId}/Scripts/${newScriptId}`);
    const newScriptData = {
        Intro: "Bonjour Madame/monsieur,PRÉNOM NOM de la société\nJe souhaiterais parler au Responsable de site / logistique est ce que c'est bien vous ?\nJe souhaiterais parler avec Monsieur/Madame X s'il vous plaît",
        ValueProposition:
            "Je me suis dit que ça pouvait être intéressant de planifier un rapide premier échange en visio et j'aurais aimé savoir quels sont vos disponibilités courant du mois de ..ou.. ? Plutôt début ou fin de journée ?",
        ObjectionHandler:
            "- A quel moment est-il disponible ?\n- Quel est le nom/prénom de la personne pour la recontacter\n- Quel est son numéro de téléphone ?\n- Quand êtes-vous disponible ?\n- C'est pour un échange découverte\nDe pouvoir comparer ce que vous avez déjà et pouvoir échanger sur le sujet\nL'échange n'engage à rien, vous pouvez couper quand vous le souhaitez\nSi ce n'est pas le bon moment leur proposer un échange pour nous présenter (proposer de les rappeler)\nDéjà pu échanger avec lui début d'année, il/elle m'avait demandé de rappeler à la fin de l'été",
        MandatoryHabits:
            "Quel est votre nom/prénom ?\nQuel est votre mail ?\nQuel est votre numéro de portable ?\nJe vous envoie de suite une invitation outlook. Je vous confirme le rdv du JJ/MM/AAAA à 00h00 avez-vous d'autres questions ?",
        DesiredInterlocutor: "- Responsable",
        EndOfCall:
            "Questions ouvertes : Quel, Comment, Qui, Quand, Pourquoi\nBien comfirmer l'orthographe du nom, prénom mail et numéro de téléphone",
        Subject: "Pour échanger sur..",
        KeyArguments: "",
        CreatorID: userId,
        DateOfCreation: new Date(),
        LastModificationDate: new Date(),
        LastModifierID: "",
        NumberOfModifications: 0,
    };
    try {
        await setDoc(newScriptRef, newScriptData);
        console.log("New script created successfully!");
    } catch (error) {
        console.error("Error creating new script:", error);
    }
}

function goToNextScript() {
    store.commit("incrementCurrentScriptIndex");
    const nextScriptId =
        store.state.scriptIds[store.state.currentScriptIndex].scriptId;
    emit("update", "slide-right");
    router.replace({ name: "callscript", params: { id: nextScriptId } });
}

function goToLastScript() {
    store.commit("decrementCurrentScriptIndex");
    const lastScriptId =
        store.state.scriptIds[store.state.currentScriptIndex].scriptId;
    emit("update", "slide-left");
    router.replace({ name: "callscript", params: { id: lastScriptId } });
}

function handleKeydown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    if (
        (target.tagName === "INPUT" && !target.readOnly) ||
        (target.tagName === "TEXTAREA" && !target.readOnly)
    ) {
        return;
    }

    switch (event.key) {
        case "ArrowRight":
            if (canGoToNextScript.value) {
                goToNextScript();
            }
            break;
        case "ArrowLeft":
            if (canGoToLastScript.value) {
                goToLastScript();
            }
            break;
    }
}

async function deleteScript() {
    confirmDeleteDialog.value = false;
    isLoading.value = true;

    if (!scriptId || !orderId) {
        console.error("Missing required param 'scriptId' or 'orderId'");
        isLoading.value = false;
        return;
    }

    const docRef = doc(db, `Orders/${orderId}/Scripts/${scriptId}`);

    try {
        await deleteDoc(docRef);
        console.log("Script deleted successfully!");
        router.replace({ name: "callInterface", params: { id: orderId } });
    } catch (error) {
        console.error("Error deleting script:", error);
    } finally {
        isLoading.value = false;
    }
}*/

// onMounted(() => {
//     window.addEventListener("keydown", handleKeydown);
// });

// onUnmounted(() => {
//     window.removeEventListener("keydown", handleKeydown);
//     // store.commit("clearScriptIds");
//     // store.commit("clearScriptIndex");
// });
</script>

<style scoped>
.v-row {
    padding: 0 3vw;
}

.bottom-bar {
    color: rgb(var(--v-theme-text)) !important;
    background-color: rgb(var(--v-theme-bar)) !important;
    box-shadow: 0 1px 4px -1px rgb(var(--v-theme-text)) !important;
}
</style>
