<template>
    <v-row class="list-row">
        <v-col>
            <v-card class="list-card">
                <v-data-table
                    v-bind="$attrs"
                    v-model:page="options.page"
                    class="data-table"
                    :items-per-page="options.itemsPerPage"
                    :headers="headersWithButton"
                    :items="items"
                    :fixed-header="true"
                    ref="listRowRef"
                    :no-data-text="$t('common.noDataTextList')"
                    :loading-text="$t('common.loadingTextList')"
                >
                    <template v-slot:item="{ item, index }">
                        <tr
                            :class="[
                                index % 2 === 0 ? 'even-row' : 'odd-row',
                                noClick
                                    ? ''
                                    : 'v-data-table__tr v-data-table__tr--clickable',
                            ]"
                            @click="!noClick && emit('rowClicked', item)"
                        >
                            <td v-for="header in headers" :key="header.value">
                                <template v-if="header.value === 'prepend'">
                                    <slot name="prepend" :item="item"></slot>
                                </template>
                                <template v-else>
                                    {{
                                        item[header.value]
                                            ? item[header.value]
                                            : "N/A"
                                    }}
                                </template>
                            </td>
                            <td v-if="showButton">
                                <div class="button">
                                    <slot name="button" :item="item"></slot>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template #bottom>
                        <v-pagination
                            v-model="options.page"
                            :length="pageNumber"
                            :total-visible="5"
                            density="compact"
                            class="pagination"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";

const emit = defineEmits(["rowClicked", "buttonClicked"]);

const pageNumber = computed(() => {
    return Math.ceil(props.items.length / options.itemsPerPage);
});

const headersWithButton = computed(() => {
    if (props.showButton) {
        return [
            ...props.headers.map((header) => ({
                ...header,
                sortable: header.sortable ?? true,
            })),
            { title: "", value: "button", sortable: false },
        ];
    }
    return props.headers.map((header) => ({
        ...header,
        sortable: header.sortable ?? true,
    }));
});

const options = reactive({
    page: 1,
    itemsPerPage: 40,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});

const props = defineProps({
    items: {
        type: Array as () => Array<Record<string, any>>,
        default: () => [],
    },
    headers: {
        type: Array as () => Array<{
            title: string;
            value: string;
            sortable?: boolean;
        }>,
        default: () => [],
    },
    showButton: {
        type: Boolean,
        default: false,
    },
    buttonText: {
        type: String,
        default: "",
    },
    prependIcon: {
        type: String,
        default: "",
    },
    noClick: {
        type: Boolean,
        default: false,
    },
});

const windowHeight = ref(window.innerHeight);
const listRowRef = ref(null);

// watch(        Used to set the number of items per page based on the height of the table. Keep just in case
//     () => windowHeight.value,
//     async () => {
//         await nextTick();
//         if (listRowRef.value) {
//             // const listRef = listRowRef.value as any;
//             await nextTick();
//             // const tableHeight = listRef.$el.clientHeight;
//             // const rowHeight = 50;
//             // options.itemsPerPage = Math.floor((tableHeight - 90) / rowHeight);
//             options.itemsPerPage = 40;
//         }
//     },
//     { immediate: true }
// );

window.addEventListener("resize", () => {
    windowHeight.value = window.innerHeight;
});
</script>

<style scoped>
.list-row {
    height: 100%;
}

.v-col {
    height: 100%;
}

.list-card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    height: 100%;
    overflow-y: auto;
}

.data-table {
    height: 100%;
    color: rgb(var(--v-theme-text));
}

.even-row {
    background-color: rgb(var(--v-theme-even_row));
}

.odd-row {
    background-color: rgb(var(--v-theme-odd_row));
}

.v-pagination {
    color: rgb(var(--v-theme-button));
}

.no-click {
    cursor: default !important;
}

.button {
    display: flex;
    justify-content: flex-end;
}
</style>
