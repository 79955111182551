<template>
    <div class="container">
        <Title :title="$t('teams.title')" />
        <Filters
            @applyFilters="applyFilters"
            @addClient="createDialog = true"
        />
        <List :clients="filteredClients" />
    </div>
</template>

<script setup lang="ts">
import { formatPrice, formatSiret } from "@/commons/convertion";
import { getAssignationsForClient } from "@/commons/firebase";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { IdName, Sizes } from "@/types";
import { collection, onSnapshot } from "firebase/firestore";
import { computed, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const clients = ref<any[]>([]);
const createDialog = ref(false);
const filters = ref({});

const filteredClients = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return clients.value;
    }
    return clients.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

onMounted(() => {
    const clientCollection = collection(db, "Clients");
    const unsubscribe = onSnapshot(clientCollection, async (snapshot) => {
        clients.value = await Promise.all(
            snapshot.docs.map(async (doc) => {
                const client = { id: doc.id, ...doc.data() } as any;
                if (client.Capital) {
                    client.Capital = formatPrice(client.Capital);
                }
                if (client.Turnover) {
                    client.Turnover = formatPrice(client.Turnover);
                }
                if (client.SIRET) {
                    client.SIRET = formatSiret(client.SIRET);
                }
                if (client.Size) {
                    client.Size = Sizes.find(
                        (size) => size.id === client.Size
                    )?.name;
                }
                const assignedUsers = (await getAssignationsForClient(
                    client.id
                )) as unknown as IdName[];
                client.Assignments = assignedUsers
                    .map((user) => user.name)
                    .join(", ");
                return client;
            })
        );
    });

    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsubscribe();
        });
    });
});

onSnapshot(collection(db, "Users"), async () => {
    clients.value.forEach(async (client) => {
        const assignedUsers = (await getAssignationsForClient(
            client.id
        )) as unknown as IdName[];
        client.Assignments = assignedUsers.map((user) => user.name).join(", ");
    });
});
</script>
