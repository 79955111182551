<template>
    <v-row justify="space-between" class="pb-2">
        <v-col cols="auto">
            <Span>{{ $t("calls.salutation") }}</Span>
        </v-col>
        <v-col cols="auto">
            <RadioButton
                class="mr-4"
                :text="$t('calls.mr')"
                :selected="callInfos.Salutation === Salutations.Mr"
                @click="callInfos.Salutation = Salutations.Mr"
            />
            <RadioButton
                :text="$t('calls.mrs')"
                :selected="callInfos.Salutation === Salutations.Mrs"
                @click="callInfos.Salutation = Salutations.Mrs"
            />
        </v-col>
    </v-row>
    <CardTextField
        v-model="callInfos.LastName"
        label="calls.lastName"
        :rules="[rules.required]"
    />
    <CardTextField
        v-model="callInfos.FirstName"
        label="calls.firstName"
        :rules="[rules.required]"
    />
    <CardTextField
        v-model="callInfos.Phone"
        label="calls.phone"
        :rules="[rules.required]"
    />
    <CardTextField
        v-model="callInfos.Mail"
        label="calls.mail"
        :rules="[rules.required]"
    />
    <CardTextField v-model="callInfos.Position" label="calls.position" />
    <v-row>
        <v-col cols="6" class="pt-0 pb-0" style="margin-bottom: 22px">
            <Span>{{ $t("calls.rdvType") }}</Span>
        </v-col>
        <v-col cols="6" class="pt-0 pb-0">
            <SelectField
                v-model="callInfos.RdvType"
                :items="rdvTypeItems"
                :label="$t('calls.rdvType')"
                item-title="name"
                item-value="id"
                :returnObject="false"
                :rules="[rules.required]"
            />
        </v-col>
    </v-row>
    <v-row v-if="callInfos.RdvType == 3">
        <v-col cols="6" class="pt-0 pb-0" style="margin-bottom: 22px">
            <Span>{{ $t("calls.meetingAddress") }}</Span>
        </v-col>
        <v-col cols="6" class="pt-0 pb-0">
            <AddressField
                v-model="callInfos.AppointmentAddress"
                :label="$t('calls.meetingAddress')"
                @address-selected="onAddressSelected"
            />
        </v-col>
    </v-row>
    <CardTextField v-model="callInfos.Salesman" label="calls.salesman" />
    <!--v-row>
        <v-col cols="6" class="pt-0 pb-0">
            <Span>{{ $t("calls.associate") }}</Span>
        </v-col>
        <v-col cols="6" class="pt-0 pb-0">
            <AutocompleteField
                v-model="selectedAssociate"
                :items="associateList"
                :label="$t('calls.associate')"
                :item-title="(item: UserDB) => `${item.FirstName} ${item.LastName}`"
                return-object
            />
        </v-col>
    </v-row-->
    <v-row v-if="false">
        <v-col cols="12" class="d-flex align-stretch">
            <Calendly
                :url="selectedAssociate?.Calendly.Link"
                :userId="selectedAssociate?.DocId"
                @dates="setDates"
                :disabled="!selectedAssociate"
            />
        </v-col>
    </v-row>
    <CardTextField
        v-if="selectedAssociate"
        v-model="callInfos.AppointmentDate"
        label="calls.date"
        :readonly="readonlyDate"
    />
    <v-row>
        <v-col cols="6" style="padding-top: 0">
            <Span>{{ $t("calls.date/time") }}</Span>
        </v-col>
        <v-col cols="3" class="pbt-0">
            <TextField
                v-model="meetingDate"
                :label="$t('calls.date')"
                type="date"
                :rules="[rules.required]"
                :disabled="callInfos.DateToBeDefined"
            />
        </v-col>
        <v-col cols="3" class="pbt-0">
            <TextField
                v-model="meetingHour"
                :label="$t('calls.hour')"
                type="time"
                :rules="[rules.required]"
                :disabled="callInfos.DateToBeDefined"
            />
        </v-col>
    </v-row>
    <v-row justify="space-between">
        <v-col cols="auto" style="padding-top: 0">
            <Span>{{ $t("calls.dateToBeDefined") }}</Span>
        </v-col>
        <v-col cols="auto" style="padding-top: 0">
            <RadioButton
                class="mr-4"
                :text="$t('calls.yes')"
                :selected="callInfos.DateToBeDefined"
                @click="callInfos.DateToBeDefined = true"
            />
            <RadioButton
                :text="$t('calls.no')"
                :selected="!callInfos.DateToBeDefined"
                @click="callInfos.DateToBeDefined = false"
            />
        </v-col>
    </v-row>
    <!--v-row>
        <v-col cols="6">
            <Span>{{ $t("calls.prospectInterest") }}</Span>
        </v-col>
        <v-col cols="6" class="space-between">
            <v-btn
                v-for="(color, index) in interestColors"
                :key="index"
                :value="index + 1"
                icon
                flat
                :color="color"
                class="border"
                :class="{
                    'border-selected': callInfos.ProspectInterest === index + 1,
                }"
                @click="
                    callInfos.ProspectInterest =
                        callInfos.ProspectInterest === index + 1 ? 0 : index + 1
                "
            />
        </v-col>
    </v-row-->
    <Comment @save="saveCall" :rules="[rules.required]" />
</template>

<script setup lang="ts">
import { getCalendlyUserListFromClient } from "@/commons/firebase";
import Calendly from "@/components/CalendlyPopup.vue";
import { Span, TextField } from "@/components/customVuetify";
import AddressField from "@/components/customVuetify/AddressField.vue";
import CardTextField from "@/components/customVuetify/CardTextField.vue";
import RadioButton from "@/components/customVuetify/RadioButton.vue";
import SelectField from "@/components/customVuetify/SelectField.vue";
import rules from "@/rules";
import { store } from "@/store";
import { Call, IdName, UserDB, rdvTypes } from "@/types";
import { ProspectInterest, Salutations } from "@/types/call";
import { Ref, inject, onMounted, ref, watch } from "vue";
import Comment from "./Comment.vue";

const callInfos = ref<Partial<Call>>({
    Salutation: 0 as Salutations,
    FirstName: "",
    LastName: "",
    Phone: "",
    Mail: "",
    Position: "",
    AssociateId: "",
    Salesman: "",
    AppointmentDate: null,
    AppointmentAddress: "",
    RdvType: null as unknown as number,
    DateToBeDefined: false,
    ProspectInterest: 0 as ProspectInterest,
});

const emit = defineEmits(["save"]);

const currentClient = ref<IdName>(null as unknown as IdName);
const associateList = ref<UserDB[]>([]);
const selectedAssociate = ref<UserDB>(null as unknown as UserDB);
const displayDate = ref<string>("");
const readonlyDate = ref(true);
const rdvTypeItems = Object.entries(rdvTypes).map(([id, name]) => ({
    name,
    id: parseInt(id),
}));

const meetingDate = ref<string>("");
const meetingHour = ref<string>("");

function setDates(dates: { startTime: Date; endTime: Date }) {
    callInfos.value.AppointmentDate = dates.startTime;
    displayDate.value = dates.startTime.toLocaleString();
}

const callData = inject("callData") as Ref<Partial<Call>>;

function onAddressSelected(addressComponents: any) {
    const route = getAddressComponent(addressComponents, "route");
    const address = getAddressComponent(addressComponents, "street_number");
    const city = getAddressComponent(addressComponents, "locality");
    const postalCode = getAddressComponent(addressComponents, "postal_code");
    const country = getAddressComponent(addressComponents, "country");
    callInfos.value.AppointmentAddress = `${address} ${route}, ${postalCode} ${city}, ${country}`;
}

function getAddressComponent(components: any, type: any) {
    for (let i = 0; i < components.length; i++) {
        if (components[i].types.includes(type)) {
            return components[i].long_name;
        }
    }
    return "";
}

watch(
    callInfos,
    (newVal: Partial<Call>) => {
        for (const key in newVal) {
            if (
                key in callData.value &&
                newVal[key as keyof Partial<Call>] !== undefined &&
                newVal[key as keyof Partial<Call>] !==
                    callData.value[key as keyof Partial<Call>]
            ) {
                callData.value[key as keyof Partial<Call>] = newVal[
                    key as keyof Partial<Call>
                ] as any;
            }
        }
    },
    { deep: true }
);

watch(
    selectedAssociate,
    (newVal: UserDB) => {
        if (newVal) {
            callInfos.value.AssociateId = newVal.DocId;
        }
    },
    { deep: true }
);

watch(
    meetingDate,
    () => {
        if (meetingDate.value && meetingHour.value) {
            const [hour, minute] = meetingHour.value.split(":");
            const [year, month, day] = meetingDate.value.split("-");
            callData.value.AppointmentDate = new Date(
                Number(year),
                Number(month) - 1,
                Number(day),
                Number(hour),
                Number(minute)
            );
        }
    },
    { deep: true }
);

watch(
    meetingHour,
    () => {
        if (meetingDate.value && meetingHour.value) {
            const [hour, minute] = meetingHour.value.split(":");
            const [year, month, day] = meetingDate.value.split("-");
            callData.value.AppointmentDate = new Date(
                Number(year),
                Number(month) - 1,
                Number(day),
                Number(hour),
                Number(minute)
            );
        }
    },
    { deep: true }
);

function saveCall() {
    if (meetingDate.value && meetingHour.value) {
        const [hour, minute] = meetingHour.value.split(":");
        const [year, month, day] = meetingDate.value.split("-");
        callData.value.AppointmentDate = new Date(
            Number(year),
            Number(month) - 1,
            Number(day),
            Number(hour),
            Number(minute)
        );
    }
    emit("save");
}

onMounted(async () => {
    if (store.state.currentClient) {
        currentClient.value = store.state.currentClient as IdName;
    }
    associateList.value = await getCalendlyUserListFromClient(
        currentClient.value.id
    );
});
</script>

<style scoped>
.border {
    border: 0.5px solid black !important;
}

.border-selected {
    border: 3px solid rgb(var(--v-theme-secondary)) !important;
}
</style>
