export default {
    required: (value: string) => !!value || "Champ requis.",
    email: (value: string) => {
        const pattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return pattern.test(value) || "Invalid e-mail.";
    },
    minLength: (length: number) => (value: string) =>
        value.length >= length || `Minimum length is ${length}.`,
    maxLength: (length: number) => (value: string) =>
        value.length <= length || `Maximum length is ${length}.`,
    numeric: (value: string) => {
        const pattern = /^-?\d+\.?\d*$/;
        return pattern.test(value) || "Must be a number.";
    },
    integer: (value: string) => {
        const pattern = /^-?\d+$/;
        return pattern.test(value) || "Must be an integer.";
    },
    positive: (value: string) => {
        const pattern = /^\d+\.?\d*$/;
        return pattern.test(value) || "Must be a positive number.";
    },
    url: (value: string) => {
        const pattern =
            /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        return pattern.test(value) || "Invalid URL.";
    },
    password: (value: string) => {
        const pattern = /^.{6,}$/;
        if (!value) {
            return true;
        }
        return (
            pattern.test(value) ||
            "Password must be at least 6 characters long."
        );
    },
};
