<template>
    <Dialog
        v-model="createClientOpen"
        :title="$t('prospects.create')"
        width="60vw"
        :clickOutside="false"
        @submit="createProspect"
        @close="close()"
    >
        <template #text>
            <v-row>
                <v-col cols="6">
                    <CardTextField
                        v-model="prospect.Name"
                        :disableMessage="true"
                        :label="$t('prospects.name')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
                <v-col cols="6">
                    <CardTextField
                        v-model="prospect.Phone"
                        :disableMessage="true"
                        :label="$t('prospects.phone')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <CardTextField
                        v-model="prospect.Comment"
                        :disableMessage="true"
                        :label="$t('prospects.comments')"
                        clearable
                        fieldSize="10"
                        textArea
                        pbt0
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <Span class="pb-0 pt-0">{{ $t("prospects.address") }}</Span>
                </v-col>
                <v-col cols="4">
                    <AddressField
                        v-model="prospect.Address"
                        :disableMessage="true"
                        :label="$t('prospects.address')"
                        @address-selected="onAddressSelected"
                    />
                </v-col>
                <v-col cols="6">
                    <CardTextField
                        v-model="prospect.City"
                        :disableMessage="true"
                        :label="$t('prospects.city')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <CardTextField
                        v-model="prospect.PostalCode"
                        :disableMessage="true"
                        :label="$t('prospects.postalCode')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
                <v-col cols="6">
                    <CardTextField
                        v-model="department"
                        :disableMessage="true"
                        readonly
                        :label="$t('prospects.department')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <CardTextField
                        v-model="region"
                        :disableMessage="true"
                        readonly
                        :label="$t('prospects.region')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
                <v-col cols="2">
                    <Span>{{ $t("prospects.size") }}</Span>
                </v-col>
                <v-col cols="4">
                    <SizeField
                        v-model="prospect.Size"
                        :disableMessage="true"
                        :label="$t('prospects.size')"
                        clearable
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <Span>{{ $t("prospects.apeCode") }}</Span>
                </v-col>
                <v-col cols="4">
                    <ApeField
                        v-model="prospect.APECode"
                        :disableMessage="true"
                        :label="$t('prospects.apeCode')"
                        clearable
                    />
                </v-col>
                <v-col cols="6">
                    <CardTextField
                        v-model="prospect.Turnover"
                        :disableMessage="true"
                        :label="$t('prospects.turnover')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <CardTextField
                        v-model="prospect.Mail"
                        :disableMessage="true"
                        :label="$t('prospects.mail')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
                <v-col cols="6">
                    <CardTextField
                        v-model="prospect.Website"
                        :disableMessage="true"
                        :label="$t('prospects.website')"
                        fieldSize="8"
                        pbt0
                    />
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-spacer></v-spacer>
            <v-btn
                class="button-bg-secondary"
                type="submit"
                prepend-icon="mdi-check"
                :text="$t('common.create')"
                :loading="isLoading"
                :disabled="isLoading"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { getCurrentUser } from "@/commons/firebase";
import ApeField from "@/components/ApeField.vue";
import SizeField from "@/components/SizeField.vue";
import { Span } from "@/components/customVuetify";
import AddressField from "@/components/customVuetify/AddressField.vue";
import CardTextField from "@/components/customVuetify/CardTextField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import { db } from "@/main";
import { defaultProspect } from "@/types";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const createClientOpen = ref(false);

const props = defineProps<{
    orderId: string;
}>();

const department = ref("");
const region = ref("");

const prospect = ref({ ...defaultProspect });

function close() {
    prospect.value = { ...defaultProspect };
    department.value = "";
    region.value = "";
    emit("close");
}

const isLoading = ref(false);

function onAddressSelected(addressComponents: any) {
    const route = getAddressComponent(addressComponents, "route");
    prospect.value.Address =
        getAddressComponent(addressComponents, "street_number") + " " + route;
    prospect.value.City = getAddressComponent(addressComponents, "locality");
    prospect.value.PostalCode = getAddressComponent(
        addressComponents,
        "postal_code"
    );
    region.value = getAddressComponent(
        addressComponents,
        "administrative_area_level_1"
    );
    department.value = getAddressComponent(
        addressComponents,
        "administrative_area_level_2"
    );
}

function getAddressComponent(components: any, type: any) {
    for (let i = 0; i < components.length; i++) {
        if (components[i].types.includes(type)) {
            return components[i].long_name;
        }
    }
    return "";
}

async function createProspect() {
    try {
        isLoading.value = true;
        const currentUser = await getCurrentUser();
        const order = (await getDoc(doc(db, "Orders", props.orderId))).data();
        if (!order) {
            throw new Error("Order not found");
        }
        const newProspect = {
            ...prospect.value,
            AuthorId: currentUser.DocId,
            ClientId: order.ClientId,
            CreationDate: new Date(),
        };
        await addDoc(
            collection(db, "Orders", props.orderId, "Prospects"),
            newProspect
        );
        close();
    } catch (error) {
        console.error("Error creating prospect: ", error);
    } finally {
        isLoading.value = false;
    }
}
</script>

<style scoped>
.v-col {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.v-row {
    height: auto !important;
}
</style>
