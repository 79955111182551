<template>
    <List
        :items="prospects"
        :headers="headers"
        :showButton="true"
        :sort-by="[{ key: 'Name', order: 'asc' }]"
        @rowClicked="editProspect"
    >
        <template #button="{ item }">
            <v-btn
                :class="
                    readyToDelete[item.DocId] ? 'button-bg-red' : 'button-red'
                "
                icon="mdi-trash-can-outline"
                @click.stop="
                    readyToDelete[item.DocId]
                        ? deleteProspect(item)
                        : (readyToDelete[item.DocId] = true)
                "
                flat
            />
        </template>
    </List>
    <EditProspect
        v-if="selectedProspectId && editDialog == true"
        :prospectId="selectedProspectId"
        :orderId="orderId"
        v-model="editDialog"
        @close="editDialog = false"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { db } from "@/main";
import {
    DocumentData,
    collection,
    deleteDoc,
    doc,
    getDocs,
} from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import EditProspect from "./EditProspect.vue";

const { t: $t } = useI18n();

const editDialog = ref(false);

defineProps({
    prospects: Array as () => DocumentData[],
    orderId: { type: String, required: true },
});

const readyToDelete = ref<any>({});
const selectedProspectId = ref();

function editProspect(prospect: any) {
    selectedProspectId.value = prospect.DocId;
    editDialog.value = true;
}

async function deleteProspect(item: any) {
    const docRef = doc(db, "Orders", item.OrderId, "Prospects", item.DocId);

    const subcollectionNames = ["Contacts", "Calls"];

    for (const name of subcollectionNames) {
        const subcollectionRef = collection(docRef, name);
        const subcollectionSnapshot = await getDocs(subcollectionRef);

        for (const doc of subcollectionSnapshot.docs) {
            await deleteDoc(doc.ref);
        }
    }
    try {
        await deleteDoc(docRef);
        readyToDelete.value[item.DocId] = false;
    } catch (error) {
        console.error("Error removing document: ", error);
    }
}

const headers = [
    { title: $t("prospects.name"), value: "Name" },
    { title: $t("prospects.turnover"), value: "Turnover" },
    { title: $t("prospects.address"), value: "Address" },
    { title: $t("prospects.city"), value: "City" },
    {
        title: $t("prospects.department"),
        value: "Department",
    },
    { title: $t("prospects.postalCode"), value: "PostalCode" },
    { title: $t("prospects.apeCode"), value: "APECode" },
];
</script>
