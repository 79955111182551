<template>
    <v-dialog
        :max-width="width"
        @click:outside="clickOutside ? close() : 0"
        :persistent="!clickOutside"
    >
        <v-card class="dialog-card" :style="{ height: height }">
            <v-card-title
                v-if="isTitle"
                class="d-flex justify-space-between align-center"
                :style="{ paddingBottom: isDivider ? '0.9em' : '0' }"
            >
                <Span class="popup-title-text">{{ title }}</Span>
                <v-btn
                    v-if="closable"
                    @click="close"
                    elevation="0"
                    class="close-button"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider v-if="isDivider"></v-divider>
            <div class="flex">
                <v-form ref="form" class="flex" @submit.prevent="submit">
                    <v-card-text
                        class="card-text"
                        :style="{
                            paddingTop: isDivider ? '2em' : '0.5em !important',
                        }"
                    >
                        <div class="center-content" v-if="isLoading">
                            <v-progress-circular
                                indeterminate
                                :color="`rgb(var(--v-theme-secondary))`"
                            ></v-progress-circular>
                        </div>
                        <slot v-if="!isLoading" name="text"></slot>
                    </v-card-text>
                    <v-card-actions v-if="isActions" class="card-actions">
                        <slot v-if="!isLoading" name="actions"></slot>
                    </v-card-actions>
                </v-form>
            </div>
        </v-card>
        <slot name="footer"></slot>
    </v-dialog>
</template>

<script setup lang="ts">
import { Span } from "@/components/customVuetify";
import { ref } from "vue";

defineProps({
    title: String,
    width: String,
    height: String,
    isLoading: Boolean,
    isDivider: {
        type: Boolean,
        default: true,
    },
    isTitle: {
        type: Boolean,
        default: true,
    },
    isActions: {
        type: Boolean,
        default: true,
    },
    closable: {
        type: Boolean,
        default: true,
    },
    clickOutside: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(["close", "submit"]);

const close = () => {
    emit("close");
};

const form = ref<any>(null);

function submit() {
    if (!form.value.isValid) return;
    emit("submit");
}
</script>

<style scoped>
.popup-title-text {
    font-weight: bold;
    font-size: 1.4rem;
    color: rgb(var(--v-theme-text)) !important;
}

.close-button {
    width: 2em;
    min-width: 2em;
}

.dialog-card {
    padding: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.226) !important;
    border-radius: 0.5em !important;
}

.flex {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
}

.card-text {
    padding-top: 2em !important;
    padding-bottom: 0em;
}

.card-text ::v-slotted(*) {
    padding-bottom: 0.5em;
}

.card-text ::v-slotted(.v-row) {
    height: 5.5em;
}

.card-actions {
    padding: 0.5rem 1rem !important;
}

.v-card-title {
    padding-bottom: 0.9em !important;
}
</style>
