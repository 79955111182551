export interface IdName {
    id: string;
    name: string;
}

export enum States {
    active = 1,
    inactive = 2,
    pending = 3,
    achived = 4,
    ongoing = 5,
}

export interface FirestoreDoc {
    DocId: string;
}
