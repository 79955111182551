<template>
    <div class="container">
        <v-row>
            <v-col cols="6" class="multiple-cards">
                <Intro v-model="textFields.intro" :readonly="readonly" />
                <ValueProposition
                    v-model="textFields.valueProposition"
                    :readonly="readonly"
                />
                <EndOfCall
                    v-model="textFields.endOfCall"
                    :readonly="readonly"
                />
                <MandatoryHabits
                    v-model="textFields.mandatoryHabits"
                    :readonly="readonly"
                />
            </v-col>
            <v-col cols="3" class="multiple-cards">
                <DesiredInterlocutor
                    v-model="textFields.desiredInterlocutor"
                    :readonly="readonly"
                />
                <ObjectionHandler
                    v-model="textFields.objectionHandler"
                    :readonly="readonly"
                />
            </v-col>
            <v-col cols="3" class="multiple-cards">
                <Subject v-model="textFields.subject" :readonly="readonly" />
                <KeyArguments
                    v-model="textFields.keyArguments"
                    :readonly="readonly"
                />
            </v-col>
        </v-row>
    </div>
    <BottomBar
        @update="changeState"
        @updateData="updateData"
        @toggleReadonly="readonly = !readonly"
    />
</template>

<script setup lang="ts">
import { db } from "@/main";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import BottomBar from "./BottomBar.vue";
import DesiredInterlocutor from "./DesiredInterlocutor.vue";
import EndOfCall from "./EndOfCall.vue";
import Intro from "./Intro.vue";
import KeyArguments from "./KeyArguments.vue";
import MandatoryHabits from "./MandatoryHabits.vue";
import ObjectionHandler from "./ObjectionHandler.vue";
import Subject from "./Subject.vue";
import ValueProposition from "./ValueProposition.vue";

const showOverlay = ref(false);
const transitionName = ref("");
const transitionKey = ref(0);
const isLoading = ref(false);
const textFields = ref({
    valueProposition: "",
    intro: "",
    endOfCall: "",
    mandatoryHabits: "",
    desiredInterlocutor: "",
    objectionHandler: "",
    subject: "",
    keyArguments: "",
});

const readonly = ref(true);

const route = useRoute();
const orderId = route.params.orderId as string;
const scriptId = route.params.scriptId as string;

onMounted(async () => {
    if (!scriptId || !orderId) {
        console.error("Missing required param 'scriptId' or 'orderId'");
        return;
    }

    const docRef = doc(db, "Orders", orderId, "Scripts", scriptId);

    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            textFields.value.valueProposition = data.ValueProposition || "";
            textFields.value.intro = data.Intro || "";
            textFields.value.endOfCall = data.EndOfCall || "";
            textFields.value.mandatoryHabits = data.MandatoryHabits || "";
            textFields.value.desiredInterlocutor =
                data.DesiredInterlocutor || "";
            textFields.value.objectionHandler = data.ObjectionHandler || "";
            textFields.value.subject = data.Subject || "";
            textFields.value.keyArguments = data.KeyArguments || "";
        } else {
            console.error("No such document!");
        }
    } catch (error) {
        console.error("Error getting document:", error);
    }
});

async function updateData() {
    isLoading.value = true;

    if (!scriptId || !orderId) {
        console.error("Missing required param 'scriptId' or 'orderId'");
        isLoading.value = false;
        return;
    }

    const docRef = doc(db, `Orders/${orderId}/Scripts/${scriptId}`);

    try {
        const docSnap = await getDoc(docRef);
        let numberOfModifications = 0;
        if (docSnap.exists()) {
            const data = docSnap.data();
            numberOfModifications = data.NumberOfModifications || 0;
        }

        numberOfModifications += 1;

        const updatedData = {
            scriptId,
            ValueProposition: textFields.value.valueProposition,
            Intro: textFields.value.intro,
            EndOfCall: textFields.value.endOfCall,
            MandatoryHabits: textFields.value.mandatoryHabits,
            DesiredInterlocutor: textFields.value.desiredInterlocutor,
            ObjectionHandler: textFields.value.objectionHandler,
            Subject: textFields.value.subject,
            KeyArguments: textFields.value.keyArguments,
            NumberOfModifications: numberOfModifications,
        };
        await updateDoc(docRef, updatedData);
        console.log("Document updated successfully!");
    } catch (error) {
        console.error("Error updating document:", error);
    } finally {
        isLoading.value = false;
    }
}

function changeState(name: string) {
    showOverlay.value = false;
    transitionName.value = name;
    transitionKey.value++;
}
</script>

<style scoped>
.v-row {
    align-items: start;
}

.multiple-cards > .card:not(:last-child) {
    margin-bottom: 1rem;
}
</style>
