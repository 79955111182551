<template>
    <List
        :items="meetings"
        :headers="headers"
        :sort-by="[{ key: 'Company', order: 'asc' }]"
        @rowClicked="showMeeting"
    />
    <MeetingDetails
        :meetingProps="selectedMeeting"
        v-model="showDialog"
        @close="showDialog = false"
    />
</template>

<script setup lang="ts">
import MeetingDetails from "@/components/MeetingDetails.vue";
import List from "@/components/customVuetify/List.vue";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const showDialog = ref(false);
const selectedMeeting: any = ref();

function showMeeting(meeting: any) {
    selectedMeeting.value = meeting;
    showDialog.value = true;
}

defineProps({
    meetings: Array as () => DocumentData[],
});

const headers = [
    { title: $t("meetings.type"), value: "Type" },
    { title: $t("meetings.company"), value: "Company" },
    { title: $t("meetings.contact"), value: "Contact" },
    { title: $t("meetings.date"), value: "Date" },
    { title: $t("meetings.hour"), value: "Hour" },
    { title: $t("meetings.comment"), value: "Comments" },
];
</script>
