<template>
    <v-row class="filters-padding-top">
        <v-col cols="auto">
            <v-btn class="button-bg-secondary">
                {{ $t("orders.actives") }}</v-btn
            >
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-white"
                @click="$router.push('/orders/archived')"
            >
                {{ $t("common.archives") }}</v-btn
            >
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <v-btn
                prependIcon="mdi-plus"
                :text="$t('orders.create')"
                class="button-bg-white"
                @click="emit('addOrder')"
            />
        </v-col>
    </v-row>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Name"
                :disableMessage="true"
                :label="$t('orders.name')"
                :clearable="true"
            ></TextField>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import TextField from "@/components/customVuetify/TextField.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["addOrder", "applyFilters"]);

const filters = ref({
    Name: "",
});

onMounted(() => {
    emit("applyFilters", filters.value);
});
</script>
