<template>
    <v-row>
        <v-col>
            <Span class="comment-title">{{ $t("calls.comment") }}</Span>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <TextArea
                v-model="callInfos.Comments"
                :label="$t('calls.comment')"
                outlined
                clearable
                :disableMessage="rules ? true : false"
            />
        </v-col>
    </v-row>
    <v-row justify="end">
        <v-col cols="auto">
            <v-btn class="button-bg-secondary" @click="saveCall">
                {{ $t("common.save") }}</v-btn
            >
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { Span } from "@/components/customVuetify";
import TextArea from "@/components/customVuetify/TextArea.vue";
import { Call } from "@/types";
import { Ref, inject, ref, watch } from "vue";

const callInfos = ref<Partial<Call>>({
    Comments: "",
});

const callData = inject("callData") as Ref<Partial<Call>>;

defineProps({
    rules: {
        type: Array,
        default: () => [],
    },
});

watch(
    callInfos,
    (newVal: Partial<Call>) => {
        for (const key in newVal) {
            if (
                key in callData.value &&
                newVal[key as keyof Partial<Call>] !== undefined &&
                newVal[key as keyof Partial<Call>] !==
                    callData.value[key as keyof Partial<Call>]
            ) {
                callData.value[key as keyof Partial<Call>] = newVal[
                    key as keyof Partial<Call>
                ] as any;
            }
        }
    },
    { deep: true }
);

const emit = defineEmits(["save"]);

function saveCall() {
    emit("save");
}
</script>

<style scoped>
.comment-title {
    font-size: 1rem;
    font-weight: bold !important;
    padding-top: 1rem;
}
</style>
