<template>
    <Dialog
        :title="$t('clients.edit')"
        width="60vw"
        :isLoading="isDataLoading"
        @submit="editClient"
        @close="$emit('close')"
    >
        <template #text>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Name"
                        :label="$t('clients.name')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Address"
                        :label="$t('clients.address')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.City"
                        :label="$t('clients.city')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.PostalCode"
                        :label="$t('clients.postalCode')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Country"
                        :label="$t('clients.country')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Region"
                        :label="$t('clients.region')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Department"
                        :label="$t('clients.department')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Capital"
                        :label="$t('clients.capital')"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.SIRET"
                        :label="$t('clients.siret')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.APECode"
                        :label="$t('clients.apeCode')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <SizeField
                        v-model="client.Size"
                        :label="$t('clients.size')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Turnover"
                        :label="$t('clients.turnover')"
                    />
                </v-col>
            </v-row>
            <!--v-row style="height: 100%">
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.primaryColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Primary"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.secondaryColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Secondary"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.textColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Text"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
            </v-row>
            <v-row style="height: 100%">
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.backgroundColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Background"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.darkBackgroundColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.BackgroundDark"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="pb-0 pt-0">
                            <v-file-input
                                v-model="logo"
                                density="compact"
                                variant="outlined"
                                :bg-color="`rgb(var(--v-theme-field))`"
                                :label="$t('clients.logo')"
                                accept="image/*"
                                :show-size="true"
                                prepend-icon=""
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            class="pb-0 pt-0 d-flex justify-center"
                        >
                            <v-img
                                v-if="client.LogoUrl"
                                :src="client.LogoUrl"
                                clearable
                                max-height="200"
                                max-width="200"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row-->
        </template>
        <template #actions>
            <v-spacer></v-spacer>
            <v-btn
                class="button-bg-secondary"
                @click="archiveClient"
                :loading="isLoading"
                :disabled="isLoading"
                prepend-icon="mdi-archive"
                :text="$t('common.archive')"
            />
            <v-btn
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
                prepend-icon="mdi-check"
                :text="$t('common.edit')"
            />
        </template>
        <template #footer>
            <PasswordConfirmationDialog
                v-model="confirmDeleteDialog"
                @success="deleteClient"
                @close="confirmDeleteDialog = false"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import PasswordConfirmationDialog from "@/components/PasswordConfirmationDialog.vue";
import SizeField from "@/components/SizeField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import { db } from "@/main";
import rules from "@/rules";
import { defaultClient } from "@/types/client";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import { deleteObject, getStorage, ref as storageRef } from "firebase/storage";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const confirmDeleteDialog = ref(false);

const props = defineProps({
    clientId: {
        type: String,
        required: true,
    },
});

const client = ref({ ...defaultClient });

const colors = ref({
    Primary: "",
    Secondary: "",
    Text: "",
    Background: "",
    BackgroundDark: "",
});

const isLoading = ref(false);
const isDataLoading = ref(false);
const clientDocId = ref("");

const logo = ref<File | null>(null);

const MAX_FILE_SIZE = 10 * 1048576;

async function archiveClient() {
    try {
        isLoading.value = true;
        const clientId = clientDocId.value;
        const clientDoc = doc(db, "Clients", clientId);
        client.value.Colors = { ...colors.value };

        await addDoc(collection(db, "ArchivedClients"), client.value);

        await deleteDoc(clientDoc);

        emit("close");
    } catch (error) {
        console.error("Error archiving client: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function editClient() {
    try {
        isLoading.value = true;
        const clientId = clientDocId.value;
        const clientDoc = doc(db, "Clients", clientId);

        // if (logo.value instanceof File) {
        //     const storage = getStorage();
        //     const logoRef = storageRef(storage, `Logos/${clientId}`);
        //     await uploadBytes(logoRef, logo.value);
        //     const logoDownloadUrl = await getDownloadURL(logoRef);
        //     client.value.LogoUrl = logoDownloadUrl;
        // }
        //client.value.Colors = { ...colors.value };

        await updateDoc(clientDoc, client.value);

        emit("close");
    } catch (error) {
        console.error("Error editing client: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function deleteClient() {
    confirmDeleteDialog.value = false;
    try {
        isLoading.value = true;
        const clientId = clientDocId.value;
        const clientDoc = doc(db, "Clients", clientId);
        if (client.value.LogoUrl) {
            const storage = getStorage();
            const logoRef = storageRef(storage, `Logos/${clientId}`);
            await deleteObject(logoRef);
        }
        await deleteDoc(clientDoc);
        emit("close");
    } catch (error) {
        console.error("Error deleting user: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function getClientData() {
    isDataLoading.value = true;
    const clientDoc = doc(db, "Clients", props.clientId);
    const clientSnapshot = await getDoc(clientDoc);
    if (clientSnapshot.exists()) {
        const clientInfos = clientSnapshot.data();
        clientDocId.value = clientSnapshot.id;
        client.value = { ...clientInfos } as any;
    }
    if (client.value.Colors) {
        colors.value = { ...client.value.Colors };
    }
    isDataLoading.value = false;
}

watch(logo, async (newLogo) => {
    if (logo.value && logo.value.size > MAX_FILE_SIZE) {
        alert($t("clients.fileTooLarge"));
        logo.value = null;
        return;
    }
    if (newLogo instanceof File) {
        const url = URL.createObjectURL(newLogo);
        client.value.LogoUrl = url;
    }
});

watch(
    [props],
    async ([newProps]) => {
        if (newProps.clientId) {
            await getClientData();
        }
    },
    { deep: true }
);

onMounted(async () => {
    if (props.clientId) {
        await getClientData();
    }
});
</script>
