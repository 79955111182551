<template>
    <v-row>
        <v-col cols="12">
            <v-card class="card" rounded="lg">
                <v-card-title class="card-title">{{
                    formatDate(new Date())
                }}</v-card-title>
                <v-card-text style="padding-left: 0; padding-right: 0">
                    <v-row no-gutters v-if="!isLoading && isData">
                        <v-col
                            :cols="orders.length > 1 ? 6 : 12"
                            v-for="(order, index) in orders"
                            :key="index"
                            class="pt-3 pb-2"
                            :class="{
                                'pl-2': index % 2 !== 0,
                                'pr-2': index % 2 === 0,
                            }"
                        >
                            <v-card class="card blue-bg">
                                <v-card-title
                                    class="card-title text-center"
                                    style="padding-bottom: 0"
                                    >{{ order.name }}</v-card-title
                                >
                                <v-card-title
                                    class="card-title text-center"
                                    style="padding-top: 0"
                                    >{{ order.segment }}</v-card-title
                                >
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.callsMade")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalCalls
                                    }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.callsTaken")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalCallsTaken
                                    }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.numberDecisionMakers")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalDecisionMakers
                                    }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.rdvTaken")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalRdv
                                    }}</v-col>
                                </v-row>
                                <v-divider />
                                <v-row>
                                    <v-col cols="6">
                                        <Span>{{
                                            $t("home.rateOnTaken")
                                        }}</Span>
                                    </v-col>
                                    <v-col cols="6">
                                        <Span>{{
                                            $t("home.rateOnDecisionMakers")
                                        }}</Span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <Span class="rate">{{
                                            order.rdvOverCallsTaken
                                        }}</Span>
                                    </v-col>
                                    <v-col cols="6">
                                        <Span class="rate">{{
                                            order.rdvOverDecisionMakers
                                        }}</Span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="isLoading">
                        <v-col cols="12" class="text-center">
                            <v-progress-circular
                                indeterminate
                                :color="`rgb(var(--v-theme-secondary))`"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row v-if="!isLoading && !isData">
                        <v-col cols="12" class="text-center">
                            {{ $t("home.noData") }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { getCurrentClient } from "@/commons/firebase";
import { getOrdersWorkedOnForDay } from "@/commons/reporting";
import { Span } from "@/components/customVuetify";
import { getAuth } from "firebase/auth";
import { onMounted, ref, watch } from "vue";

const auth = getAuth();
const orders = ref<any>([]);
const isLoading = ref(false);
const isData = ref(false);
const currentClient = getCurrentClient();

const formatDate = (date: Date) => {
    const formattedDate = date.toLocaleDateString("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    });

    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

async function getData() {
    isLoading.value = true;
    if (!auth.currentUser) {
        return;
    }
    orders.value = await getOrdersWorkedOnForDay(
        currentClient.value?.id,
        auth.currentUser.uid,
        new Date(),
        new Date()
    );
    isData.value = orders.value.length > 0;
    isLoading.value = false;
}

watch(currentClient, async () => {
    await getData();
});

onMounted(async () => {
    await getData();
});
</script>

<style scoped>
.card {
    padding: 1.5vh 1.5vw;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.rate {
    font-weight: bold;
    font-size: 2rem;
}

.blue-bg {
    background-color: rgb(var(--v-theme-background));
    box-shadow: none;
}

.card-title {
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.card-text {
    font-size: 3rem;
    font-weight: bold;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
</style>
