import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import { store } from "./store";

import "@mdi/font/css/materialdesignicons.css";
import "@mdi/light-font/css/materialdesignicons-light.css";
import {
    browserLocalPersistence,
    getAuth,
    setPersistence,
} from "firebase/auth";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { createI18n } from "vue-i18n";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import router from "./router";

import algoliasearch from "algoliasearch";

const algoliaClient = algoliasearch(
    "BNHYKNVXR0",
    "b6ee1c7b9743bf08a6ab25f918d5969a"
);

const indexProspects = algoliaClient.initIndex("Prospects");
const indexContacts = algoliaClient.initIndex("Contacts");

export { indexContacts, indexProspects };

const firebaseConfig = {
    apiKey: "AIzaSyDgn54neL6VqPuouCWsEtwnQCbL9Qq7Thw",
    authDomain: "youscalesaas.firebaseapp.com",
    projectId: "youscalesaas",
    storageBucket: "youscalesaas.appspot.com",
    messagingSenderId: "790988051605",
    appId: "1:790988051605:web:e8dad2a04dc23f6487f79c",
    measurementId: "G-1C1XKB5S8M",
};

let app;
try {
    app = initializeApp(firebaseConfig);
} catch (error) {
    console.error("Firebase initialization error", error);
}

if (!app) {
    throw new Error("Firebase initialization error");
}

export const analytics = getAnalytics(app);

export const db = getFirestore(); // uncomment only for production
// export const db = initializeFirestore(app, {}, "test"); // uncomment only for development

const messages = {
    en: require("@/locales/en.json"),
    fr: require("@/locales/fr.json"),
};

const i18n = createI18n({
    locale: "fr",
    legacy: false,
    messages,
});

const ys_blue = "#1a1d34";
const ys_secondary = "#5964b6";
const black = "#000000";
const white = "#ffffff";
const ys_background = "#f0f5f8";
const background_dark = ys_blue;

export const vuetify = createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                colors: {
                    primary: ys_blue,
                    secondary: ys_secondary,
                    bar: white,
                    background: ys_background,
                    text: ys_blue,
                    text_secondary: ys_secondary,
                    button: ys_secondary,
                    button_white: white,
                    even_row: "#f6f7fb",
                    odd_row: white,
                    field: white,
                    scrollbar_bg: "#f1f1f1",
                    scrollbar: "#999",
                },
            },
            dark: {
                colors: {
                    primary: ys_blue,
                    secondary: ys_secondary,
                    bar: black,
                    background: background_dark,
                    text: white,
                    text_secondary: white,
                    button: white,
                    button_white: background_dark,
                    even_row: background_dark,
                    odd_row: black,
                    field: black,
                    surface: black,
                    scrollbar_bg: "#2b2b2b",
                    scrollbar: "#555",
                },
            },
        },
    },
});

(async function init() {
    const auth = getAuth();
    await setPersistence(auth, browserLocalPersistence);
    createApp(App).use(router).use(vuetify).use(i18n).use(store).mount("#app");
})();
