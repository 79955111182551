export const Trigrams = [
    { initials: "HZO", id: "pn7TjyDcxGUcWzjtTmIwXU5tvzU2" },
    { initials: "LHE", id: "kKF6TVWIPIZKoT1BIwr3PGz4MwG3" },
    { initials: "LSI", id: "k4ARrgbjACbHp3O7vE1flJJFEXs2" },
    { initials: "CDE", id: "MLEFrKenjwU8zRy2lGf0CqDb3VH3" },
    { initials: "UVI", id: "EflkWlxkxvc4JsF5ZXI0qlV8Lhk1" },
    { initials: "EIS", id: "CY3sbInwLjYWFS32JjwdxCskQ5o1" },
    { initials: "EGE", id: "Akmg8eKtuvXa8dUKdLTXva2Kk8W2" },
    { initials: "AWE", id: "x3IcPJU033My3pAcROrLbsxYUpl1" },
    { initials: "CTI", id: "6oaMjFFh6QTdPB3T7r3uQclJB903" },
    { initials: "FHO", id: "u5uDdzK38ITKerPQjrW3GfRFnH73" },
    { initials: "LRO", id: "i4IoqJJrx8XQFqsE1afj5TcOHTh2" },
];
