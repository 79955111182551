import { ref, watch } from "vue";

const snackbar = ref({
    show: false,
    message: "",
    timeout: 3000,
    color: "green",
});

const showSnackbar = (message: string, timeout = 3000, color = "green") => {
    snackbar.value.message = message;
    snackbar.value.timeout = timeout;
    snackbar.value.color = color;
    snackbar.value.show = true;
};

watch(
    () => snackbar.value.show,
    (newValue) => {
        if (newValue) {
            setTimeout(() => {
                snackbar.value.show = false;
            }, snackbar.value.timeout);
        }
    }
);

export function useSnackbar() {
    return {
        snackbar,
        showSnackbar,
    };
}
