<template>
    <div v-if="welcomeTitle != ''" class="container">
        <Title :title="welcomeTitle" />
        <Cards class="padding-bottom" />
        <Title :title="$t('home.meetingsTitle')" />
        <Meetings :meetings="meetingList" />
        <Title :title="$t('home.myDay')" />
        <OrdersCard />
    </div>
    <PopupClientSpace
        v-model="isClientSpaceVisible"
        @close="isClientSpaceVisible = false"
    />
</template>

<script setup lang="ts">
import { getCurrentUser } from "@/commons/firebase";
import { getMeetingsFromClient } from "@/commons/reporting";
import PopupClientSpace from "@/components/PopupClientSpace.vue";
import Title from "@/components/customVuetify/Title.vue";
import { store } from "@/store";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Cards from "./Cards.vue";
import Meetings from "./Meetings.vue";
import OrdersCard from "./OrdersCard.vue";

const { t: $t } = useI18n();

const isClientSpaceVisible = ref(false);

const user = ref();
const meetingList = ref<any>([]);

const welcomeTitle = computed(() => {
    if (user.value) {
        return `${$t("home.welcome")} ${user.value.FirstName} ${
            user.value.LastName
        }`;
    }
    return "";
});

watch(
    () => store.state.currentClient,
    async (newClient) => {
        if (newClient) {
            meetingList.value = await getMeetingsFromClient(
                user.value.DocId,
                newClient.id,
                new Date(),
                new Date()
            );
        }
    }
);

onMounted(async () => {
    if (!store.state.hasSeenPopup) {
        isClientSpaceVisible.value = true;
    }
    user.value = await getCurrentUser();
    meetingList.value = await getMeetingsFromClient(
        user.value.DocId,
        store.state.currentClient.id,
        new Date(),
        new Date()
    );
});
</script>

<style scoped>
.padding-bottom {
    padding-bottom: 1rem;
}
</style>
