<template>
    <List :items="prospects" :headers="headers" noClick :showButton="true">
        <template #prepend="{ item }">
            <v-avatar
                v-if="item.ProspectInterest"
                :color="getColorFromProspectInterest(item.ProspectInterest)"
                size="20"
            />
        </template>
        <template #button="{ item }">
            <v-btn
                class="button-bg-green call-button"
                icon="mdi-phone-in-talk-outline"
                @click.stop="goToInterfaceCall(item)"
            />
        </template>
    </List>
</template>

<script setup lang="ts">
import { getColorFromProspectInterest } from "@/commons/convertion";
import List from "@/components/customVuetify/List.vue";
import router from "@/router";
import { store } from "@/store";
import { DocumentData } from "firebase/firestore";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const props = defineProps({
    prospects: Array as () => any[] | DocumentData[],
});

function goToInterfaceCall(item: any) {
    const prospectIds = props.prospects?.map((prospect) => ({
        prospectId: prospect.DocId,
        orderId: prospect.OrderId,
    }));
    store.commit("setProspectIds", prospectIds);
    const prospectIndex = prospectIds?.findIndex(
        (prospectId) => prospectId.prospectId === item.DocId
    );
    store.commit("setProspectIndex", prospectIndex);
    router.push({ name: "callInterface", params: { id: item.DocId } });
}

const headers = [
    { title: "", value: "prepend", sortable: false },
    { title: $t("prospects.company"), value: "Name" },
    { title: $t("prospects.size"), value: "Size" },
    { title: $t("prospects.apeCode"), value: "APECode" },
    { title: $t("prospects.region"), value: "Region" },
    { title: $t("prospects.postalCode"), value: "PostalCode" },
    { title: $t("prospects.turnover"), value: "Turnover" },
    { title: $t("prospects.phone"), value: "Phone" },
    { title: $t("prospects.callNumber"), value: "CallNumber" },
    { title: $t("prospects.callDate"), value: "CallDate" },
];
</script>

<style scoped>
.call-button {
    height: 38px !important;
    width: 38px !important;
}
</style>
