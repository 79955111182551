<template>
    <v-expansion-panels v-if="progressHistory.length">
        <v-expansion-panel style="border: 1px solid black; box-shadow: none">
            <v-expansion-panel-title :static="true" class="title">
                {{ $t("orders.orderProgressHistory") }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-divider />
                <div
                    class="comments-div"
                    v-for="(progress, index) in progressHistory"
                    :key="index"
                >
                    <v-row justify="space-between">
                        <v-col cols="auto" class="pb-0 pt-0">
                            <v-row>
                                <v-col cols="auto" class="no-padding-bot">
                                    <Span class="bold">
                                        {{ $t("orders.remark") }}
                                        {{
                                            progressHistory.length - index
                                        }}</Span
                                    >
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="auto"
                                    style="padding-top: 0; padding-bottom: 0"
                                >
                                    <Span>
                                        {{
                                            formatTimestampTommhhddmmyyyy(
                                                progress.Date
                                            )
                                        }}</Span
                                    >
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <v-btn
                                v-if="
                                    progress.Date.toMillis() >
                                    new Date().getTime() - 600000
                                "
                                :class="
                                    readyToDelete[progress.id]
                                        ? 'button-bg-red'
                                        : 'button-red'
                                "
                                icon="mdi-trash-can-outline"
                                flat
                                :loading="isLoadingDelete"
                                @click.stop="
                                    readyToDelete[progress.id]
                                        ? deleteProgress(progress.id.toString())
                                        : (readyToDelete[progress.id] = true)
                                "
                            />
                        </v-col>
                    </v-row>
                    <v-row class="pb-4">
                        <v-col cols="2" class="no-padding-bot">
                            <TextArea
                                v-model="progress.Trigram"
                                :label="$t('orders.collaborator')"
                                :readonly="true"
                                :disableMessage="true"
                            />
                        </v-col>
                        <v-col cols="10" class="no-padding-bot">
                            <TextArea
                                v-model="progress.Comments"
                                :label="$t('orders.comment')"
                                :disableMessage="true"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="pb-4">
                        <v-col cols="6" class="no-padding-bot">
                            <TextArea
                                v-model="progress.Departments"
                                :label="$t('orders.departments')"
                                :readonly="true"
                                :disableMessage="true"
                            />
                        </v-col>
                        <v-col cols="6" class="no-padding-bot">
                            <TextArea
                                v-model="progress.Website"
                                :label="$t('orders.website')"
                                :disableMessage="true"
                            />
                        </v-col>
                    </v-row>
                    <v-divider v-if="index != progressHistory.length - 1" />
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup lang="ts">
import {
    formatTimestampTommhhddmmyyyy,
    getTrigramFromId,
} from "@/commons/convertion";
import { Span } from "@/components/customVuetify";
import TextArea from "@/components/customVuetify/TextArea.vue";
import { db } from "@/main";
import { departmentRegionTypeMapping } from "@/types";
import {
    collection,
    deleteDoc,
    doc,
    getDocs,
    orderBy,
    query,
} from "firebase/firestore";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const props = defineProps<{
    orderId: string;
}>();
const emit = defineEmits(["deleted"]);

const readyToDelete = ref<any>({});

const isLoadingDelete = ref(false);

const progressHistory = ref<any[]>([]);

async function deleteProgress(docId: string) {
    isLoadingDelete.value = true;
    try {
        if (!props.orderId) {
            return;
        }

        const progressDocRef = doc(
            db,
            "Orders",
            props.orderId,
            "OrderProgress",
            docId
        );

        await deleteDoc(progressDocRef);
        progressHistory.value = progressHistory.value.filter(
            (progress) => progress.id !== docId
        );
        emit("deleted");
    } catch (error) {
        console.error(error);
    } finally {
        isLoadingDelete.value = false;
    }
}

async function getOrderProgressHistory() {
    try {
        if (!props.orderId) {
            return;
        }

        const progressHistoryCollection = collection(
            db,
            "Orders",
            props.orderId,
            "OrderProgress"
        );
        const q = query(progressHistoryCollection, orderBy("Date", "desc"));

        const progressHistorySnapshot = await getDocs(q);

        const promises = progressHistorySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const departments = data.Departments.map((department: any) => {
                return departmentRegionTypeMapping[department].department;
            });

            return {
                id: doc.id,
                ...data,
                Trigram: await getTrigramFromId(data.UserId),
                Departments: departments,
            };
        });
        progressHistory.value = await Promise.all(promises);
    } catch (error) {
        console.error(error);
    }
}

onMounted(async () => {
    await getOrderProgressHistory();
});
</script>

<style scoped>
.title {
    font-size: 1.6rem;
    font-weight: bold;
    color: rgb(var(--v-theme-text));
}
</style>
