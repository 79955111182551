<template>
    <Card :title="$t('calls.notepadTitle')">
        <template #content>
            <v-row>
                <v-col>
                    <TextArea
                        :placeholder="$t('calls.notepadText')"
                        :disableMessage="true"
                        rows="3"
                    />
                </v-col>
            </v-row>
        </template>
    </Card>
</template>

<script setup lang="ts">
import Card from "@/components/customVuetify/Card.vue";
import TextArea from "@/components/customVuetify/TextArea.vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
</script>
