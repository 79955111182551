import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { IdName } from "./types";

interface StoreState {
    theme: string | null;
    prospectIds: { prospectId: string; orderId: string }[];
    scriptIds: { scriptId: string; orderId: string }[];
    currentProspectIndex: number;
    currentScriptIndex: number;
    currentClient: IdName;
    selectedDepartmentIds: string[];
    hasSeenPopup: boolean;
    callsFilters: any;
    reportingStartDate: string;
    reportingEndDate: string;
    extractStartDate: string;
    extractEndDate: string;
}

export const store = createStore<StoreState>({
    state: {
        theme: "light",
        prospectIds: [],
        scriptIds: [],
        currentProspectIndex: -1,
        currentScriptIndex: -1,
        currentClient: { id: "", name: "" },
        selectedDepartmentIds: [],
        hasSeenPopup: false,
        callsFilters: null,
        reportingStartDate: new Date().toISOString().slice(0, 10),
        reportingEndDate: new Date().toISOString().slice(0, 10),
        extractStartDate: new Date().toISOString().slice(0, 10),
        extractEndDate: new Date().toISOString().slice(0, 10),
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme;
        },
        setCallsFilters(state, filters) {
            state.callsFilters = filters;
        },
        setReportingStartDate(state, startDate) {
            state.reportingStartDate = startDate;
        },
        setReportingEndDate(state, endDate) {
            state.reportingEndDate = endDate;
        },
        setExtractStartDate(state, startDate) {
            state.extractStartDate = startDate;
        },
        setExtractEndDate(state, endDate) {
            state.extractEndDate = endDate;
        },
        setHasSeenPopup(state, hasSeenPopup) {
            state.hasSeenPopup = hasSeenPopup;
        },
        setSelectedDepartmentIds(state, departmentIds) {
            state.selectedDepartmentIds = departmentIds;
        },
        setProspectIds(state, prospectIds) {
            state.prospectIds = prospectIds;
        },
        setProspectIndex(state, index) {
            state.currentProspectIndex = index;
        },
        setClient(state, client) {
            state.currentClient = client;
        },
        clearProspectIds(state) {
            state.prospectIds = [];
        },
        clearProspectIndex(state) {
            state.currentProspectIndex = -1;
        },
        incrementCurrentProspectIndex(state) {
            if (state.currentProspectIndex < state.prospectIds.length - 1) {
                state.currentProspectIndex++;
            }
        },
        decrementCurrentProspectIndex(state) {
            if (state.currentProspectIndex > 0) {
                state.currentProspectIndex--;
            }
        },
    },
    getters: {
        canGoToNextCall(state) {
            return state.currentProspectIndex < state.prospectIds.length - 1;
        },
        canGoToLastCall(state) {
            return state.currentProspectIndex > 0;
        },
        canGoToNextScript(state) {
            return state.currentScriptIndex < state.scriptIds.length - 1;
        },
        canGoToLastScript(state) {
            return state.currentScriptIndex > 0;
        },
    },
    plugins: [createPersistedState()],
});
