<template>
    <div class="graph-container">
        <v-row>
            <v-col cols="9">
                <v-card flat>
                    <Title :title="$t('graphs.callsOnPickedUp')" noBold />
                    <Filters
                        @applyFilters="applyFilters"
                        :clientId="clientId"
                        class="padding-left-right"
                    />
                    <DoubleHistogram :data="data" />
                </v-card>
            </v-col>
            <v-col cols="3" class="cards-col">
                <v-row class="fill-height mb-3">
                    <v-col cols="12" class="card-col">
                        <v-card
                            class="fill-width fill-height mb-2 card-dark justify-center"
                            flat
                        >
                            <Span class="card-text">{{ meanCalls }}</Span>
                            <Span class="card-subtext">{{
                                $t("graphs.averageCalls")
                            }}</Span>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="fill-height">
                    <v-col cols="12" class="card-col">
                        <v-card
                            class="fill-width fill-height card-light justify-center"
                            flat
                        >
                            <Span class="card-text">{{ meanPickedUp }}</Span>
                            <Span class="card-subtext">{{
                                $t("graphs.averagePickedUp")
                            }}</Span>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
import { getCallsAndPickedUpForOrderOrClient } from "@/commons/reporting";
import DoubleHistogram from "@/components/D3/DoubleHistogram.vue";
import { Span } from "@/components/customVuetify";
import Title from "@/components/customVuetify/Title.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import Filters from "./Filters.vue";

const filters = ref({
    OrderId: [],
    startDate: new Date(),
    endDate: new Date(),
});

const route = useRoute();
const clientId = route.params.id as string;

const data = ref<{ date: Date; higherValue: number; lowerValue: number }[]>([]);
const meanCalls = ref("");
const meanPickedUp = ref("");

function formatNumber(num: number): string {
    const integerPart = Math.floor(num);
    const decimalPart = num - integerPart;
    if (decimalPart === 0) {
        return integerPart.toString();
    } else {
        return num.toFixed(2);
    }
}

async function getData() {
    const callsDataFetched = ref(
        await getCallsAndPickedUpForOrderOrClient(
            filters.value.startDate,
            filters.value.endDate,
            clientId,
            filters.value.OrderId
        )
    );
    data.value = callsDataFetched.value.map((d: any) => ({
        date: d.date,
        higherValue: d.calls,
        lowerValue: d.pickedUp,
    }));

    const callsData = callsDataFetched.value.filter((d: any) => d.calls > 0);
    const pickedUpData = callsDataFetched.value.filter(
        (d: any) => d.pickedUp > 0
    );
    const totalCalls = callsData.length;
    const totalPickedUp = pickedUpData.length;
    meanCalls.value = formatNumber(
        callsData.reduce((acc: number, curr: any) => acc + curr.calls, 0) /
            totalCalls
    );
    meanPickedUp.value = formatNumber(
        pickedUpData.reduce(
            (acc: number, curr: any) => acc + curr.pickedUp,
            0
        ) / totalPickedUp
    );
}

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    getData();
}
</script>

<style scoped>
.v-card {
    height: 100% !important;
}

.cards-col {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-col {
    padding: 0;
    height: 100%;
}

.card-dark {
    background-color: rgb(var(--v-theme-primary));
    color: white;
    display: flex;
    flex-direction: column;
}

.card-light {
    background-color: rgb(var(--v-theme-secondary));
    color: rgb(var(--v-theme-primary));
    display: flex;
    flex-direction: column;
}

.card-text {
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    line-height: normal;
    color: white !important;
}

.card-subtext {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: white !important;
}

.padding-left-right {
    padding-left: 3.25vw;
    padding-right: 3.25vw;
}
</style>
