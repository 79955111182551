<template>
    <Dialog
        :title="$t('db.importProspects')"
        width="40%"
        :closable="!isLoading"
        :clickOutside="!isLoading"
        @submit="processFile"
        @close="$emit('close')"
    >
        <template #text>
            <v-row class="alert alert-row">
                <v-col class="text-center alert">
                    <v-icon class="pr-4 red">mdi-alert-outline</v-icon>
                    <Span class="bold">{{ $t("db.warningImport") }}</Span>
                </v-col>
            </v-row>
            <v-file-input
                v-model="selectedFile"
                :accept="'.xlsx, .xls, .csv, .ods'"
                density="compact"
                :label="$t('common.chooseAFile')"
                variant="outlined"
                :rules="[rules.required]"
                :bg-color="`rgb(var(--v-theme-field))`"
                :show-size="true"
            />
        </template>
        <template #actions>
            <div class="center-content">
                <v-btn
                    class="button-bg-secondary"
                    :text="$t('common.import')"
                    prepend-icon="mdi-file-export-outline"
                    type="submit"
                    :disabled="!selectedFile"
                    :loading="isLoading"
                />
            </div>
            <AlertDuplicate
                v-model="isAlertDuplicateOpen"
                :prospectDB="currentProspectDB"
                :prospectImport="currentDuplicateProspect"
                @rejectAll="
                    isRejectAll = true;
                    handleDuplicate('reject');
                "
                @reject="handleDuplicate('reject')"
                @accept="handleDuplicate('accept')"
                @acceptAll="
                    isAcceptAll = true;
                    handleDuplicate('accept');
                "
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { getSizeStringFromCode } from "@/commons/convertion";
import { AlertDuplicate } from "@/components";
import { Dialog, Span } from "@/components/customVuetify";
import { db } from "@/main";
import rules from "@/rules";
import { store } from "@/store";
import { IdName } from "@/types";
import * as ExcelJS from "exceljs";
import { getAuth } from "firebase/auth";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import Papa from "papaparse";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t: $t } = useI18n();

const emit = defineEmits(["close", "importSuccess", "importFail"]);

const route = useRoute();
const orderId = ref(route.params.id as string);
const isLoading = ref(false);
let resolveDuplicate: (value: string) => void;

const customHeaders = [
    "None",
    "None",
    "AuthorId",
    "CreationDate",
    "None",
    "None",
    "None",
    "Region",
    "Department",
    "Name",
    "Size",
    "Turnover",
    "APECode",
    "None",
    "Address",
    "City",
    "PostalCode",
    "Website",
    "Phone",
    "Mail",
    "Comment",
    "FirstName",
    "LastName",
    "Position",
    "PersonalMail",
    "PersonalPhone",
];

const isAlertDuplicateOpen = ref(false);
const selectedFile = ref<File | undefined>();
const currentClient = ref<IdName | null>(null);
const isRejectAll = ref(false);
const isAcceptAll = ref(false);

const auth = getAuth();

const currentDuplicateProspect = ref({
    Name: "",
    PostalCode: "",
    Address: "",
});
const currentProspectDB = ref({
    Name: "",
    PostalCode: "",
    Address: "",
});

function handleDuplicate(action: string) {
    isAlertDuplicateOpen.value = false;
    if (resolveDuplicate) {
        resolveDuplicate(action);
    }
}

async function processFile() {
    isLoading.value = true;
    try {
        if (!selectedFile.value) throw new Error("No file selected");
        const fileType = selectedFile.value.name.split(".").pop();
        if (fileType === "csv") {
            Papa.parse(selectedFile.value, {
                complete: async function (results) {
                    await processData(results.data);
                },
            });
        } else if (["xls", "xlsx", "ods"].includes(fileType as string)) {
            const workbook = new ExcelJS.Workbook();
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                const buffer = e.target?.result as ArrayBuffer;
                await workbook.xlsx.load(buffer);
                const worksheet = workbook.worksheets[0];
                const jsonData = worksheet.getSheetValues().slice(1);
                await processData(jsonData);
            };
            fileReader.readAsArrayBuffer(selectedFile.value);
        } else {
            emit("importFail");
        }
    } catch (error) {
        emit("importFail");
    }
}

async function processData(jsonData: any[]) {
    const data = jsonData.slice(1);
    const currentUserId = auth.currentUser ? auth.currentUser.uid : "";

    for (const row of data) {
        if (
            row === undefined ||
            (row && row.length === 0) ||
            row.every(
                (cell: any) => cell == null || cell === "" || /^\s*$/.test(cell)
            )
        ) {
            continue;
        }

        let prospect: Record<string, any> = {};
        console.log("prospect", row);
        customHeaders.forEach((header, index) => {
            if (header == "AuthorId") {
                prospect[header] = currentUserId;
            } else if (header == "CreationDate") {
                prospect[header] = new Date();
            } else if (
                (header == "PersonalPhone" || header == "PostalCode") &&
                row[index] != null
            ) {
                prospect[header] = row[index].toString();
            } else if (header == "Size") {
                const transformedSize = getSizeStringFromCode(row[index]);
                prospect[header] = transformedSize;
            } else if (
                typeof row[index] === "object" &&
                (index === 17 || index === 19)
            ) {
                prospect[header] = row[index].text;
            } else if (index === 18) {
                if (typeof row[index] === "object") {
                    prospect[header] = row[index].text;
                } else if (row[index] != null && row[index] != undefined) {
                    prospect[header] = row[index].toString();
                }
            } else {
                prospect[header] = row[index] ?? null;
            }
        });

        const ordersSnapshot = await getDocs(
            query(
                collection(db, "Orders", orderId.value, "Prospects"),
                where("PostalCode", "==", prospect["PostalCode"]),
                where("Name", "==", prospect["Name"]),
                where("Address", "==", prospect["Address"])
            )
        );

        if (!ordersSnapshot.empty) {
            if (isRejectAll.value) {
                continue;
            }

            currentProspectDB.value = {
                Name: ordersSnapshot.docs[0].data().Name.toString(),
                PostalCode: ordersSnapshot.docs[0].data().PostalCode.toString(),
                Address: ordersSnapshot.docs[0].data().Address.toString(),
            };
            currentDuplicateProspect.value = {
                Name: prospect["Name"].toString(),
                PostalCode: prospect["PostalCode"].toString(),
                Address: prospect["Address"].toString(),
            };

            if (!isAcceptAll.value) {
                isAlertDuplicateOpen.value = true;
                const userResponse = await new Promise<string>((resolve) => {
                    resolveDuplicate = resolve;
                });
                if (userResponse === "reject" || isRejectAll.value) {
                    continue;
                }
            }
        }
        await createProspectAndContact(prospect);
    }
    isRejectAll.value = false;
    isAcceptAll.value = false;
    isLoading.value = false;
    emit("importSuccess");
    emit("close");
}

async function createProspectAndContact(prospect: Record<string, any>) {
    const orderDocRef = doc(db, "Orders", orderId.value);
    const prospectsCollectionRef = collection(orderDocRef, "Prospects");

    prospect["ClientId"] = currentClient.value?.id;
    prospect["Called"] = false;

    delete prospect["None"];
    delete prospect["Region"];
    delete prospect["Department"];

    const contact = {
        FirstName: prospect["FirstName"],
        LastName: prospect["LastName"],
        Phone: prospect["PersonalPhone"],
        Mail: prospect["PersonalMail"],
        Position: prospect["Position"],
    };

    delete prospect["FirstName"];
    delete prospect["LastName"];
    delete prospect["PersonalPhone"];
    delete prospect["PersonalMail"];
    delete prospect["Position"];

    const prospectDocRef = await addDoc(prospectsCollectionRef, prospect);
    const contactsCollectionRef = collection(prospectDocRef, "Contacts");
    if (
        contact.FirstName ||
        contact.LastName ||
        contact.Phone ||
        contact.Mail ||
        contact.Position
    ) {
        await addDoc(contactsCollectionRef, contact);
    }
}

onMounted(() => {
    currentClient.value = store.state.currentClient;
});
</script>

<style scoped>
.alert {
    padding: 0;
    height: auto !important;
}

.alert-row {
    padding-bottom: 12px !important;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
