<template>
    <v-row justify="space-between" class="pb-2">
        <v-col cols="auto">
            <Span>{{ $t("calls.salutation") }}</Span>
        </v-col>
        <v-col cols="auto">
            <RadioButton
                class="mr-4"
                :text="$t('calls.mr')"
                :selected="callInfos.Salutation === Salutations.Mr"
                @click="callInfos.Salutation = Salutations.Mr"
            />
            <RadioButton
                :text="$t('calls.mrs')"
                :selected="callInfos.Salutation === Salutations.Mrs"
                @click="callInfos.Salutation = Salutations.Mrs"
            />
        </v-col>
    </v-row>
    <CardTextField v-model="callInfos.LastName" label="calls.lastName" />
    <CardTextField v-model="callInfos.FirstName" label="calls.firstName" />
    <CardTextField v-model="callInfos.Phone" label="calls.phone" />
    <CardTextField v-model="callInfos.Mail" label="calls.mail" />
    <CardTextField v-model="callInfos.Position" label="calls.position" />
    <v-row justify="space-between">
        <v-col cols="auto" class="pt-0">
            <Span>{{ $t("calls.sendMail") }}</Span>
        </v-col>
        <v-col cols="auto" class="pt-0">
            <RadioButton
                class="mr-4"
                :text="$t('calls.yes')"
                :selected="callInfos.SendMail"
                @click="callInfos.SendMail = true"
                :disabled="callInfos.Mail === ''"
            />
            <RadioButton
                :text="$t('calls.no')"
                :selected="!callInfos.SendMail"
                @click="callInfos.SendMail = false"
            />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            <Span>{{ $t("calls.callAgainOn") }}</Span>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <TextField
                v-model="callAgainDateTime.date"
                type="date"
                disableMessage
            />
        </v-col>
        <v-col cols="auto">
            <TextField
                v-model="callAgainDateTime.time"
                type="time"
                disableMessage
            />
        </v-col>
    </v-row>
    <!--v-row>
        <v-col cols="6">
            <Span>{{ $t("calls.prospectInterest") }}</Span>
        </v-col>
        <v-col cols="6" class="space-between">
            <v-btn
                v-for="(color, index) in interestColors"
                :key="index"
                :value="index + 1"
                icon
                flat
                :color="color"
                class="border"
                :class="{
                    'border-selected': callInfos.ProspectInterest === index + 1,
                }"
                @click="
                    callInfos.ProspectInterest =
                        callInfos.ProspectInterest === index + 1 ? 0 : index + 1
                "
            />
        </v-col>
    </v-row-->
    <Comment @save="saveCall" />
</template>

<script setup lang="ts">
import { Span, TextField } from "@/components/customVuetify";
import CardTextField from "@/components/customVuetify/CardTextField.vue";
import RadioButton from "@/components/customVuetify/RadioButton.vue";
import { Call } from "@/types";
import { ProspectInterest, Salutations } from "@/types/call";
import { Ref, inject, ref, watch } from "vue";
import Comment from "./Comment.vue";

const callInfos = ref<Partial<Call>>({
    Salutation: 0 as Salutations,
    FirstName: "",
    LastName: "",
    Phone: "",
    Mail: "",
    Position: "",
    SendMail: false,
    CallAgainDate: null,
    ProspectInterest: 0 as ProspectInterest,
});

const callAgainDateTime = ref({
    date: "",
    time: "",
});

const emit = defineEmits(["save"]);

const callData = inject("callData") as Ref<Partial<Call>>;

watch(
    callInfos,
    (newVal: Partial<Call>) => {
        for (const key in newVal) {
            if (
                key in callData.value &&
                newVal[key as keyof Partial<Call>] !== undefined &&
                newVal[key as keyof Partial<Call>] !==
                    callData.value[key as keyof Partial<Call>]
            ) {
                callData.value[key as keyof Partial<Call>] = newVal[
                    key as keyof Partial<Call>
                ] as any;
            }
        }
    },
    { deep: true }
);

watch(
    callAgainDateTime,
    (newVal) => {
        if (newVal.date && newVal.time) {
            callData.value.CallAgainDate = new Date(
                `${newVal.date}T${newVal.time}`
            );
        }
    },
    { deep: true }
);

function saveCall() {
    emit("save");
}
</script>

<style scoped>
.border {
    border: 0.5px solid black !important;
}

.border-selected {
    border: 3px solid rgb(var(--v-theme-secondary)) !important;
}
</style>
