<template>
    <v-row class="filters-padding-top">
        <v-col cols="auto">
            <v-btn
                class="button-bg-white"
                :text="$t('extracts.meetings')"
                @click="$router.push('/extracts/meetings')"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn class="button-bg-secondary" :text="$t('extracts.mails')" />
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-white"
                :text="$t('extracts.calls')"
                @click="$router.push('/extracts/calls')"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-green"
                :text="$t('extracts.titleMails')"
                prepend-icon="mdi-file-document-plus-outline"
                :disabled="!filteredMails.length"
                @click="fetchAndProcessData"
            />
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pb-0 pt-0">
            <Checkbox v-model="mailsArchived">
                <template v-slot:label>
                    <v-tooltip
                        :text="$t('extracts.showMailsAdditionally')"
                        location="top"
                    >
                        <template #activator="{ props }">
                            <div v-bind="props" ref="targetRef">
                                {{ $t("extracts.showArchivedMails") }}
                            </div>
                        </template>
                    </v-tooltip>
                </template>
            </Checkbox>
        </v-col>
    </v-row>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Company"
                :disableMessage="true"
                :label="$t('extracts.company')"
                :clearable="true"
            />
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.Contact"
                :disableMessage="true"
                :label="$t('extracts.contact')"
                :clearable="true"
            />
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.Employee"
                :disableMessage="true"
                :label="$t('extracts.employee')"
                :clearable="true"
            />
        </v-col>
        <v-spacer />
        <v-col cols="4">
            <DateFilter
                v-model:start-date="startDate"
                v-model:end-date="endDate"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import {
    getDateObjFromDateString,
    getSizeStringFromCode,
} from "@/commons/convertion";
import { getOrderFromId } from "@/commons/firebase";
import Checkbox from "@/components/customVuetify/Checkbox.vue";
import TextField from "@/components/customVuetify/TextField.vue";
import DateFilter from "@/components/DateFilter.vue";
import { db } from "@/main";
import { store } from "@/store";
import { APECodes, SectionCodes } from "@/types";
import * as ExcelJS from "exceljs";
import { doc, getDoc } from "firebase/firestore";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const emit = defineEmits(["applyFilters"]);

const startDate = ref(store.state.extractStartDate);
const endDate = ref(store.state.extractEndDate);
const mailsArchived = ref(true);
const isLoading = ref(false);

const props = defineProps({
    filteredMails: {
        type: Array,
        required: true,
    },
});

const filters = ref({
    Company: "",
    Contact: "",
    Employee: "",
    startDate: ref(new Date(store.state.extractStartDate)),
    endDate: ref(new Date(store.state.extractEndDate)),
    ArchivedMails: true,
});

function concatWithCommas(strings: string[]): string {
    if (strings.length === 0) {
        return "";
    }
    if (strings.length === 1) {
        return strings[0];
    }
    return strings.join(", ");
}

async function fetchAndProcessData() {
    isLoading.value = true;
    try {
        const response = await fetch("/excels/TemplateExtractionMail.xlsx");
        if (!response.ok) {
            throw new Error("Error fetching Excel template file.");
        }
        const arrayBuffer = await response.arrayBuffer();

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);

        let worksheet = workbook.getWorksheet(1);

        if (!worksheet) {
            throw new Error("Error loading Excel template file.");
        }

        const sortedMails = props.filteredMails
            .slice()
            .sort((a: any, b: any) => {
                const dateA = getDateObjFromDateString(a.CallDate);
                const dateB = getDateObjFromDateString(b.CallDate);
                return (dateA as any) - (dateB as any);
            });

        const rowPromises = sortedMails.map(async (item: any, index: any) => {
            const companyData = await getCompanyDataFromFirebase(
                item.OrderId,
                item.ProspectId
            );
            let APECodeDetail = (await getOrderFromId(item.OrderId)).data();

            //fonction de transformation d'un ape en designations
            const sectionCode = APECodeDetail.Sections || "";
            const sectionDescription =
                SectionCodes[sectionCode as keyof typeof SectionCodes] || "";
            const codeApes = APECodeDetail.ApeCodes || "";
            const codeApes2 = concatWithCommas(codeApes);
            const classCode = APECodeDetail.ApeCodes || "";
            const classDescription =
                APECodes[classCode as keyof typeof APECodes] || "";

            const row = worksheet.getRow(index + 3);
            row.getCell(1).value = index + 1;
            row.getCell(2).value = item.CallDate || "";
            row.getCell(3).value = item.Company || "";
            row.getCell(4).value = sectionDescription || "";
            row.getCell(5).value = codeApes2 || "";
            row.getCell(6).value = classDescription || "";
            row.getCell(7).value = companyData?.Turnover || "";
            row.getCell(8).value =
                getSizeStringFromCode(companyData?.Size) || "";
            row.getCell(9).value = companyData?.Address || "";
            row.getCell(10).value = companyData?.City || "";
            row.getCell(11).value = companyData?.PostalCode || "";
            row.getCell(12).value = companyData?.Phone || "";
            row.getCell(13).value = companyData?.Website || "";
            row.getCell(14).value = item.LastName || "";
            row.getCell(15).value = item.FirstName || "";
            row.getCell(16).value = item.Position || "";
            row.getCell(17).value = item.Phone || "";
            row.getCell(18).value = item.Mail || "";
            row.getCell(19).value = item.Comments || "";
            row.commit();
        });

        await Promise.all(rowPromises);

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "ExtractionMail.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Error processing Excel file:", error);
    } finally {
        isLoading.value = false;
    }
}

async function getCompanyDataFromFirebase(orderId: string, prospectId: string) {
    const docRef = doc(db, "Orders", orderId, "Prospects", prospectId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}

watch(
    () => store.state.extractStartDate,
    (newVal) => {
        if (newVal !== undefined) {
            startDate.value = newVal;
        }
    }
);

watch(
    () => store.state.extractEndDate,
    (newVal) => {
        if (newVal !== undefined) {
            endDate.value = newVal;
        }
    }
);

watch(mailsArchived, (newVal) => {
    filters.value.ArchivedMails = newVal;
    emit("applyFilters", filters.value);
});

watch(startDate, (newVal) => {
    store.commit("setExtractStartDate", newVal);
    filters.value.startDate = new Date(newVal);
    emit("applyFilters", filters.value);
});

watch(endDate, (newVal) => {
    store.commit("setExtractEndDate", newVal);
    filters.value.endDate = new Date(newVal);
    emit("applyFilters", filters.value);
});

onMounted(async () => {
    emit("applyFilters", filters.value);
});
</script>
