<template>
    <List
        :items="orders"
        :headers="headers"
        noClick
        :sort-by="[{ key: 'Name', order: 'asc' }]"
        :showButton="true"
    >
        <template #button="{ item }">
            <v-col cols="auto">
                <v-btn
                    class="button-bg-secondary"
                    :text="$t('db.prospectState')"
                    prepend-icon="mdi-database-outline"
                    @click="openProgressPopup(item)"
                />
            </v-col>
            <v-col cols="auto">
                <v-btn
                    class="button-bg-secondary"
                    :text="$t('db.addProspects')"
                    prepend-icon="mdi-database-outline"
                    @click.stop="$emit('buttonClicked', item)"
                />
            </v-col>
        </template>
    </List>
    <OrderProgressPopup
        v-model="progressPopup"
        :orderId="orderId"
        @close="progressPopup = false"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import OrderProgressPopup from "@/components/OrderProgress/OrderProgressPopup.vue";
import { Order } from "@/types";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const progressPopup = ref(false);
const orderId = ref("");

defineEmits(["buttonClicked"]);

defineProps({
    orders: Array as () => Order[] | DocumentData[],
});

function openProgressPopup(item: any) {
    orderId.value = item.DocId;
    progressPopup.value = true;
}

const headers = [
    { title: $t("db.orderName"), value: "Name" },
    { title: $t("db.prospectNumber"), value: "TotalNumber" },
    { title: $t("db.alreadyCalled"), value: "AlreadyCalled" },
    { title: $t("db.leftToCall"), value: "LeftToCall" },
    { title: $t("db.totalRdv"), value: "TotalRdv" },
    { title: $t("db.mailsToSend"), value: "TotalMail" },
    { title: $t("db.prospectsKo"), value: "TotalKo" },
];
</script>
