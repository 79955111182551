<template>
    <v-card class="login-card" elevation="4">
        <v-card-title class="card-title">{{ $t("login.first") }}</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" @submit.prevent="changePassword">
                <TextField
                    v-model="currentPassword"
                    :label="$t('login.currentPassword')"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    :append-inner-icon="
                        showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append-inner="
                        showCurrentPassword = !showCurrentPassword
                    "
                    :rules="[rules.required, rules.password]"
                ></TextField>

                <TextField
                    v-model="newPassword"
                    :label="$t('login.newPassword')"
                    :type="showNewPassword ? 'text' : 'password'"
                    :append-inner-icon="
                        showNewPassword ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append-inner="showNewPassword = !showNewPassword"
                    :rules="[rules.required, rules.password]"
                ></TextField>

                <TextField
                    v-model="repeatNewPassword"
                    :label="$t('login.repeatNewPassword')"
                    :type="showRepeatNewPassword ? 'text' : 'password'"
                    :append-inner-icon="
                        showRepeatNewPassword ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append-inner="
                        showRepeatNewPassword = !showRepeatNewPassword
                    "
                    :rules="[
                        rules.required,
                        rules.password,
                        (v: any) => v === newPassword || 'Passwords must match',
                    ]"
                ></TextField>

                <v-btn
                    :disabled="!valid || isLoading"
                    :loading="isLoading"
                    class="button-bg-secondary"
                    type="submit"
                    >{{ $t("common.confirm") }}</v-btn
                >
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
import TextField from "@/components/customVuetify/TextField.vue";
import router from "@/router";
import rules from "@/rules";
import axios from "axios";
import {
    EmailAuthProvider,
    getAuth,
    reauthenticateWithCredential,
    updatePassword,
} from "firebase/auth";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

let valid = ref(true);
let currentPassword = ref("");
let newPassword = ref("");
let repeatNewPassword = ref("");
let showCurrentPassword = ref(false);
let showNewPassword = ref(false);
let showRepeatNewPassword = ref(false);
const isLoading = ref(false);

const changePassword = async () => {
    if (newPassword.value !== repeatNewPassword.value) {
        return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user || !user.email) {
        return;
    }
    const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword.value
    );

    try {
        isLoading.value = true;
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword.value);
        const idToken = await user.getIdToken();
        await axios.post(
            "https://europe-west1-youscalesaas.cloudfunctions.net/setUserClaimsHttp",
            { uid: user.uid },
            { headers: { Authorization: `Bearer ${idToken}` } }
        );
        const newCredential = EmailAuthProvider.credential(
            user.email,
            newPassword.value
        );
        await reauthenticateWithCredential(user, newCredential);
        router.push("/home");
    } catch (error: any) {
        alert(error.message);
    } finally {
        isLoading.value = false;
    }
};
</script>

<style scoped>
.login-card {
    top: 25%;
    position: relative;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}
.card-title {
    white-space: normal !important;
    overflow: visible !important;
}
</style>
