<template>
    <Title :title="$t('reporting.clientDetails')" />
    <Filters @applyFilters="applyFilters" />
    <List :users="isLoading ? [] : filteredUsers" :loading="isLoading" />
</template>

<script setup lang="ts">
import { getActiveOrderListNameId } from "@/commons/firebase";
import {
    getCallsForClient,
    getCallsTakenForClient,
    getDecisionMakersForClient,
    getRdvForClient,
} from "@/commons/reporting";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import { collection, getDocs, query } from "firebase/firestore";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const clients = ref<any[]>([]);
const isLoading = ref(false);
const filters = ref({
    Name: "",
    startDate: new Date(),
    endDate: new Date(),
});

const filteredUsers = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return clients.value;
    }
    return clients.value.filter((prospect: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return value.some((v) => v === prospect[key]);
            }
            if (
                typeof value === "number" &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return prospect[key] === value;
            }
            if (
                typeof value === "string" &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return prospect[key]
                    .toLowerCase()
                    .includes(value.toLowerCase());
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return value.some(
                    (v) =>
                        (v as string).toLowerCase() ===
                        prospect[key].toLowerCase()
                );
            }
            return true;
        })
    );
});

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    await setupFirestoreListener();
}

const orderIds = await getActiveOrderListNameId();

watch(
    () => store.state.currentClient,
    async (client) => {
        if (client) {
            const result = await getActiveOrderListNameId();
            orderIds.value = Array.isArray(result.value) ? result.value : [];
            setupFirestoreListener();
        }
    }
);

async function addCountsToUsers() {
    clients.value = await Promise.all(
        clients.value.map(async (client) => {
            let totalCalls = 0;
            let totalPickedUp = 0;
            let totalDecisionMakers = 0;
            let totalRdv = 0;

            totalCalls += await getCallsForClient(
                client.id,
                filters.value.startDate,
                filters.value.endDate
            );
            totalPickedUp += await getCallsTakenForClient(
                client.id,
                filters.value.startDate,
                filters.value.endDate
            );
            totalDecisionMakers += await getDecisionMakersForClient(
                client.id,
                filters.value.startDate,
                filters.value.endDate
            );
            totalRdv += await getRdvForClient(
                client.id,
                filters.value.startDate,
                filters.value.endDate
            );

            return {
                ...client,
                totalCalls: totalCalls || "0",
                totalPickedUp: totalPickedUp || "0",
                totalDecisionMakers: totalDecisionMakers || "0",
                totalRdv: totalRdv || "0",
                rateOnPickedUp: totalPickedUp
                    ? Number(((totalRdv / totalPickedUp) * 100).toFixed(2)) + ""
                    : "0",
                rateOnDecisionMakers: totalDecisionMakers
                    ? Number(
                          ((totalRdv / totalDecisionMakers) * 100).toFixed(2)
                      ) + ""
                    : "0",
            };
        })
    );
    isLoading.value = false;
}

async function setupFirestoreListener() {
    isLoading.value = true;
    clients.value = [];
    const ordersCollection = collection(db, "Clients");
    const q = query(ordersCollection);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
        clients.value.push({
            id: doc.id,
            Name: doc.data().Name,
        });
    });
    await addCountsToUsers();
    clients.value = clients.value.filter((client) => client.totalCalls > 0);
}
</script>
