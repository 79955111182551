<template>
    <div class="container">
        <Title :title="$t('orders.title')" />
        <Filters @applyFilters="applyFilters" @addOrder="createDialog = true" />
        <v-row>
            <v-col cols="auto">
                <Span class="titles bold">{{ $t("orders.toDo") }}</Span>
            </v-col>
        </v-row>
        <List :orders="filteredOrdersToDo">
            <template #button="{ item }">
                <v-tooltip :text="$t('orders.ongoing')" location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            class="button-bg-secondary list-button"
                            style="margin-right: 10px"
                            icon="mdi-autorenew"
                            v-bind="props"
                            @click.stop="setOngoing(item)"
                        />
                    </template>
                </v-tooltip>
                <v-tooltip :text="$t('orders.done')" location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            class="button-bg-secondary list-button"
                            icon="mdi-check"
                            v-bind="props"
                            @click.stop="setDone(item)"
                        />
                    </template>
                </v-tooltip>
            </template>
        </List>
        <v-row>
            <v-col cols="auto">
                <Span class="titles bold">{{ $t("orders.ongoing") }}</Span>
            </v-col>
        </v-row>
        <List :orders="filteredOrdersOngoing">
            <template #button="{ item }">
                <v-btn
                    class="button-bg-secondary"
                    :text="$t('db.prospectState')"
                    prepend-icon="mdi-database-outline"
                    @click.stop="openProgressPopup(item)"
                    style="margin-right: 10px"
                />
                <v-tooltip :text="$t('orders.toDo')" location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            class="button-bg-secondary list-button"
                            style="margin-right: 10px"
                            icon="mdi-format-list-bulleted"
                            v-bind="props"
                            @click.stop="setToDo(item)"
                        />
                    </template>
                </v-tooltip>
                <v-tooltip :text="$t('orders.done')" location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            class="button-bg-secondary list-button"
                            icon="mdi-check"
                            v-bind="props"
                            @click.stop="setDone(item)"
                        />
                    </template>
                </v-tooltip>
            </template>
        </List>
        <v-row>
            <v-col cols="auto">
                <Span class="titles bold">{{ $t("orders.done") }}</Span>
            </v-col>
        </v-row>
        <List :orders="filteredOrdersDone">
            <template #button="{ item }">
                <v-tooltip :text="$t('orders.toDo')" location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            class="button-bg-secondary list-button"
                            style="margin-right: 10px"
                            icon="mdi-format-list-bulleted"
                            v-bind="props"
                            @click.stop="setToDo(item)"
                        />
                    </template>
                </v-tooltip>
                <v-tooltip :text="$t('orders.ongoing')" location="top">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            class="button-bg-secondary list-button"
                            icon="mdi-autorenew"
                            v-bind="props"
                            @click.stop="setOngoing(item)"
                        />
                    </template>
                </v-tooltip>
            </template>
        </List>
    </div>
    <CreateOrder v-model="createDialog" @close="createDialog = false" />
    <OrderProgressPopup
        v-model="progressPopup"
        :orderId="orderId"
        @close="progressPopup = false"
    />
</template>

<script setup lang="ts">
import OrderProgressPopup from "@/components/OrderProgress/OrderProgressPopup.vue";
import { getCurrentClient } from "@/commons/firebase";
import { Span } from "@/components/customVuetify";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { Order } from "@/types";
import {
    collection,
    doc,
    onSnapshot,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import CreateOrder from "./CreateOrder.vue";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();
const progressPopup = ref(false);
const orderId = ref();

defineEmits(["buttonClicked"]);

const orders = ref<Order[]>([]);
const ordersToDo = computed(() =>
    orders.value.filter((order) => order.State === 1)
);
const ordersOngoing = computed(() =>
    orders.value.filter((order) => order.State === 2)
);
const ordersDone = computed(() =>
    orders.value.filter((order) => order.State === 3)
);
const createDialog = ref(false);
const currentClient = getCurrentClient();
const filters = ref({});

const filteredOrdersToDo = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return ordersToDo.value;
    }
    return ordersToDo.value.filter((order: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                order[key] &&
                typeof order[key] === "string"
            ) {
                return order[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

const filteredOrdersOngoing = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return ordersOngoing.value;
    }
    return ordersOngoing.value.filter((order: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                order[key] &&
                typeof order[key] === "string"
            ) {
                return order[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

const filteredOrdersDone = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return ordersDone.value;
    }
    return ordersDone.value.filter((order: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                order[key] &&
                typeof order[key] === "string"
            ) {
                return order[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

function openProgressPopup(item: any) {
    orderId.value = item.id;
    progressPopup.value = true;
}

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

function setOngoing(order: any) {
    order.State = 2;
    updateOrder(order);
}

function setDone(order: any) {
    order.State = 3;
    updateOrder(order);
}

function setToDo(order: any) {
    order.State = 1;
    updateOrder(order);
}

function updateOrder(order: any) {
    const orderCollection = collection(db, "Orders");
    const orderData = { ...order };
    delete orderData.id;
    updateDoc(doc(orderCollection, order.id), orderData);
}

function fetchOrders() {
    if (currentClient.value) {
        const orderCollection = collection(db, "Orders");
        const ordersQuery = query(
            orderCollection,
            where("ClientId", "==", currentClient.value.id)
        );
        const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
            orders.value = snapshot.docs.map((doc) => {
                const order = { id: doc.id, ...doc.data() } as any;
                return order;
            });
        });

        watchEffect((onInvalidate) => {
            onInvalidate(() => {
                unsubscribe();
            });
        });
    }
}

watch(currentClient, () => {
    fetchOrders();
});

onMounted(() => {
    fetchOrders();
});
</script>

<style scoped>
.titles {
    font-size: 1.6rem;
    color: rgb(var(--v-theme-text));
}

.list-button {
    height: 38px !important;
    width: 38px !important;
}
</style>
