<template>
    <v-autocomplete
        v-bind="$attrs"
        class="popup-autocomplete"
        :class="disableMessage ? 'disable-message' : ''"
        :items="translatedItems"
        item-title="name"
        item-value="value"
        :return-object="returnObject"
        density="compact"
        :bg-color="`rgb(var(--v-theme-field))`"
        variant="outlined"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
    items: {
        type: Array,
        required: true,
    },
    disableMessage: Boolean,
    returnObject: Boolean,
    isI18n: Boolean,
    noAlphaSort: Boolean,
});

const { t } = useI18n();

const translatedItems = computed(() => {
    let items: any = props.items;
    if (props.noAlphaSort || !items[0] || !items[0].name) {
        return items;
    }
    if (!items.every((item: any) => item.name && item.id)) {
        return items;
    }
    if (props.isI18n) {
        return props.items.map((item: any) => ({
            ...item,
            name: t(item.name),
        }));
    }
    return [...items].sort((a: any, b: any) => a.name.localeCompare(b.name));
});
</script>

<style scoped>
.popup-autocomplete {
    color: rgb(var(--v-theme-text)) !important;
}
</style>
