<template>
    <div class="container">
        <Title
            :title="$t('meetingFollowup.followupStateMeetingDetailsTitle')"
        />
        <Filters
            @applyFilters="applyFilters"
            :filteredMeetings="filteredMeetings"
        />
        <List
            :meetings="filteredMeetings"
            :loading="isLoading"
            @selectMeeting="selectMeeting"
        />
        <MeetingFollowupPopup
            v-model="statePopup"
            :selectedMeetingState="selectedMeetingState"
            @close="handlePopupClose"
        />
    </div>
</template>

<script setup lang="ts">
import {
    formatPrice,
    getSizeStringFromCode,
    getTrigram,
} from "@/commons/convertion";
import {
    getCurrentClient,
    getOrderFromId,
    getUserFromId,
} from "@/commons/firebase";
import { getRdvForDate } from "@/commons/reporting";
import Title from "@/components/customVuetify/Title.vue";
import MeetingFollowupPopup from "@/components/MeetingFollowupPopup.vue";
import { db } from "@/main";
import { rdvTypes } from "@/types";
import { doc, getDoc } from "firebase/firestore";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const meetings = ref<any[]>([]);
const isLoading = ref(false);
const filters = ref({
    Type: "",
    startDate: new Date(),
    endDate: new Date(),
    ArchivedMeetings: true,
});
const statePopup = ref(false);
const selectedMeetingState = ref<{
    OrderId?: string;
    ProspectId?: string;
    DocId?: string;
}>({});

const filteredMeetings = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return meetings.value;
    }
    return meetings.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    await getData();
}

async function getData() {
    isLoading.value = true;
    const rdvList = await getRdvForDate(
        getCurrentClient().value.id,
        filters.value.startDate,
        filters.value.endDate,
        filters.value.ArchivedMeetings
    );

    meetings.value = await Promise.all(
        rdvList.map(async (rdv: any) => {
            const user = (await getUserFromId(rdv.EmployeeId)).data();
            let date;
            let hours;
            let minutes;
            if (rdv.AppointmentDate) {
                date = rdv.AppointmentDate.toDate();
                hours = date.getHours().toString().padStart(2, "0");
                minutes = date.getMinutes().toString().padStart(2, "0");
            }

            const order = (await getOrderFromId(rdv.OrderId)).data();

            let prospectRef = doc(
                db,
                "Orders",
                rdv.OrderId,
                "Prospects",
                rdv.ProspectId
            );
            let prospectSnap = await getDoc(prospectRef);
            let prospectData = prospectSnap.data();

            if (prospectData === undefined) {
                prospectRef = doc(
                    db,
                    "ArchivedOrders",
                    rdv.OrderId,
                    "ArchivedProspects",
                    rdv.ProspectId
                );
                prospectSnap = await getDoc(prospectRef);
                prospectData = prospectSnap.data();
            }

            const followupRef = doc(
                db,
                "Orders",
                rdv.OrderId,
                "Prospects",
                rdv.ProspectId,
                "Calls",
                rdv.DocId,
                "FollowUp",
                "Meeting"
            );
            const followupSnap = await getDoc(followupRef);
            const followupData = followupSnap.exists()
                ? followupSnap.data()
                : {};

            return {
                Type: rdvTypes[rdv.RdvType as keyof typeof rdvTypes],
                Associate: rdv.AssociateId,
                Company: prospectData?.Name,
                Contact: rdv.FirstName + " " + rdv.LastName.toUpperCase(),
                FirstName: rdv.FirstName,
                LastName: rdv.LastName,
                Position: rdv.Position,
                Phone: rdv.Phone,
                Mail: rdv.Mail,
                DateToBeDefined: rdv.DateToBeDefined,
                Order: order?.Name,
                Comments: rdv.Comments,
                Date: date ? date.toLocaleDateString("fr-FR") : "",
                Hour: hours ? hours + ":" + minutes : "",
                DateTaken: rdv.Date.toDate().toLocaleDateString("fr-FR"),
                Employee: getTrigram(user?.FirstName, user?.LastName),
                PostalCode: prospectData?.PostalCode,
                City: prospectData?.City,
                Turnover: formatPrice(prospectData?.Turnover),
                Website: prospectData?.Website,
                Size: getSizeStringFromCode(prospectData?.Size),
                OrderId: rdv.OrderId,
                ProspectId: rdv.ProspectId,
                Salesman: rdv.Salesman,
                DocId: rdv.DocId,
                Address: prospectData?.Address,
                CompanyPhone: prospectData?.Phone,
                APECode: prospectData?.APECode,
                MeetingState: followupData?.MeetingState,
                ProspectInterestForMeeting:
                    followupData?.ProspectInterestForMeeting,
                Need: followupData?.Need,
                State: followupData?.State,
                MeetingComment: followupData?.MeetingComment,
            };
        })
    );
    isLoading.value = false;
}

function selectMeeting(meeting: any) {
    selectedMeetingState.value = {
        OrderId: meeting.OrderId,
        ProspectId: meeting.ProspectId,
        DocId: meeting.DocId,
    };
    statePopup.value = true;
}

function handlePopupClose() {
    statePopup.value = false;
    getData();
}

onMounted(async () => {
    await getData();
});
</script>
