<template>
    <Dialog
        :title="$t('users.edit')"
        width="50em"
        :isLoading="isDataLoading"
        @submit="editUser"
        @close="emit('close')"
    >
        <template #text>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.LastName"
                        :label="$t('users.lastname')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.FirstName"
                        :label="$t('users.firstname')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.Phone"
                        :label="$t('users.phone')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.Mail"
                        :label="$t('users.email')"
                        :rules="[rules.required, rules.email]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="password"
                        :label="$t('users.password')"
                        :rules="[rules.password]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupAutocompleteField
                        v-model="selectedClient"
                        :items="clients"
                        :label="$t('users.client')"
                        :rules="[rules.required]"
                        :disabled="!isToviaUser"
                        item-title="name"
                        item-value="id"
                        return-object
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <Switch
                        v-model="isAdmin"
                        :label="$t('users.isAdmin')"
                        @change="
                            isSupervisor = false;
                            isOperator = false;
                        "
                    />
                </v-col>
                <v-col cols="4" class="d-flex align-center">
                    <Switch
                        v-model="isSupervisor"
                        :label="$t('users.isSupervisor')"
                        @change="
                            isAdmin = false;
                            isOperator = false;
                        "
                    />
                </v-col>
                <v-col cols="4">
                    <Switch
                        v-model="isOperator"
                        :label="$t('users.isOperator')"
                        @change="
                            isAdmin = false;
                            isSupervisor = false;
                        "
                    />
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-btn
                class="delete-button"
                @click="confirmDisableDialog = true"
                :loading="isLoading"
                :disabled="isLoading || user.Disabled"
                prepend-icon="mdi-account-off-outline"
                :text="$t('common.disable')"
            />
            <v-spacer></v-spacer>
            <Span class="error--text">{{ roleError }}</Span>
            <Span class="error--text">{{
                user.Disabled ? $t("users.userDisabled") : ""
            }}</Span>
            <v-btn
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
                prepend-icon="mdi-check"
                :text="$t('common.edit')"
            />
        </template>
        <template #footer>
            <PasswordConfirmationDialog
                v-model="confirmDisableDialog"
                :text="$t('users.confirmDisable')"
                @success="disableUser"
                @close="confirmDisableDialog = false"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import {
    getClientListNameId,
    getUserClientNameId,
    isTovia,
} from "@/commons/firebase";
import { Span } from "@/components/customVuetify";
import PopupAutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import Switch from "@/components/customVuetify/Switch.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import PasswordConfirmationDialog from "@/components/PasswordConfirmationDialog.vue";
import { db } from "@/main";
import rules from "@/rules";
import { IdName, UserDB } from "@/types";
import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const isAdmin = ref(false);
const isSupervisor = ref(false);
const isOperator = ref(false);

const confirmDisableDialog = ref(false);

const password = ref("");

const props = defineProps({
    userId: {
        type: String,
        required: true,
    },
    props: Object,
});

const user = ref<Partial<UserDB>>({
    LastName: "",
    FirstName: "",
    Phone: "",
    Mail: "",
    Role: 3,
    SuperiorId: "",
    FirebaseUID: "",
    ClientId: "",
    Disabled: false,
});

const isLoading = ref(false);

const clients = ref<IdName[]>([]);
const selectedClient = ref<IdName | null>(null);
const userDocId = ref("");
const isDataLoading = ref(false);
const isToviaUser = ref(false);

const roleError = ref("");

async function editUser() {
    if (!isAdmin.value && !isSupervisor.value && !isOperator.value) {
        roleError.value = $t("users.roleError");
        return;
    }

    isLoading.value = true;
    try {
        const userDoc = doc(db, "Users", userDocId.value);
        let superiorId = user.value.SuperiorId;
        if (isAdmin.value) {
            superiorId = "";
        }
        await updateDoc(userDoc, {
            LastName: user.value.LastName,
            FirstName: user.value.FirstName,
            Phone: user.value.Phone,
            Mail: user.value.Mail,
            Role: isAdmin.value
                ? 1
                : isSupervisor.value
                ? 2
                : isOperator.value
                ? 3
                : 3,
            SuperiorId: superiorId,
            ClientId: selectedClient.value?.id,
        });

        const userData: {
            uid: string | undefined;
            email: string | undefined;
            claims: { admin: boolean; supervisor: boolean; operator: boolean };
            password?: string;
        } = {
            uid: user.value.FirebaseUID,
            email: user.value.Mail,
            claims: {
                admin: isAdmin.value,
                supervisor: isSupervisor.value,
                operator: isOperator.value,
            },
        };
        if (password.value) {
            userData.password = password.value;
        }
        const auth = getAuth();
        if (!auth.currentUser) {
            throw new Error("User not authenticated");
        }
        const idToken = await getIdToken(auth.currentUser);
        const response = await axios.post(
            "https://europe-west1-youscalesaas.cloudfunctions.net/editUserHttp",
            userData,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            }
        );

        if (response.data.message !== "User updated successfully") {
            throw new Error("Failed to update user");
        }

        emit("close");
    } catch (error) {
        console.error("Error editing user: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function disableUser() {
    isLoading.value = true;
    try {
        const auth = getAuth();
        if (!auth.currentUser) {
            throw new Error("User not authenticated");
        }
        if (!userDocId.value) {
            throw new Error("No user doc id found");
        }
        const idToken = await getIdToken(auth.currentUser);

        await axios.post(
            "https://europe-west1-youscalesaas.cloudfunctions.net/archiveUserHttp",
            { uid: userDocId.value },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            }
        );

        const userDoc = doc(db, "Users", userDocId.value);
        await updateDoc(userDoc, {
            AssignedClients: [],
            Disabled: true,
        });
        emit("close");
    } catch (error) {
        console.error("Error disabling user: ", error);
    } finally {
        isLoading.value = false;
    }
}

onMounted(async () => {
    isDataLoading.value = true;
    clients.value = await getClientListNameId();

    const userDoc = doc(db, "Users", props.userId);
    const userDocSnapshot = await getDoc(userDoc);
    const userInfos = userDocSnapshot.data();

    if (userInfos) {
        userDocId.value = userDocSnapshot.id;
        selectedClient.value = await getUserClientNameId(userInfos);
        isToviaUser.value = await isTovia();
        user.value = {
            LastName: userInfos.LastName,
            FirstName: userInfos.FirstName,
            Phone: userInfos.Phone,
            Mail: userInfos.Mail,
            SuperiorId: userInfos.SuperiorId,
            FirebaseUID: userInfos.FirebaseUID,
            ClientId: userInfos.ClientId,
            Disabled: userInfos.Disabled,
        };
        if (userInfos.Role == 1) {
            isAdmin.value = true;
        } else if (userInfos.Role == 2) {
            isSupervisor.value = true;
        } else if (userInfos.Role == 3) {
            isOperator.value = true;
        }
    } else {
        console.error(`No user found with id ${props.userId}`);
    }

    isDataLoading.value = false;
});
</script>

<style scoped>
.error--text {
    color: #f44336;
    padding-right: 1em;
}
</style>
