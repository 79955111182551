<template>
    <v-app-bar height="60" location="bottom" class="bottom-bar">
        <v-row>
            <v-col cols="auto" class="only-you">
                <v-btn
                    class="button-bg-secondary"
                    prependIcon="mdi-clipboard-text-outline"
                    :text="$t('home.finishDay')"
                    :disabled="false"
                    @click="finishDayDialog = true"
                />
            </v-col>
            <v-col cols="auto">
                <v-btn
                    class="button-bg-white"
                    :text="$t('home.myDay')"
                    @click="$router.push('/home')"
                />
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="only-you">
                <v-btn
                    class="button-bg-white"
                    :text="$t('home.db')"
                    @click="$router.push('/db')"
                />
            </v-col>
            <v-col cols="auto" class="only-you">
                <v-btn
                    class="button-bg-white"
                    :text="$t('home.orders')"
                    @click="$router.push('/orders')"
                />
            </v-col>
            <v-col cols="auto">
                <v-btn
                    class="button-bg-secondary"
                    prependIcon="mdi-phone-in-talk"
                    :text="$t('home.startCalls')"
                    @click="$router.push('/calls')"
                />
            </v-col>
        </v-row>
        <PopupFinishDay
            v-model="finishDayDialog"
            @close="finishDayDialog = false"
        />
    </v-app-bar>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import PopupFinishDay from "./FinishDay/PopupFinishDay.vue";

const { t: $t } = useI18n();
const finishDayDialog = ref(false);

defineEmits(["close", "confirm"]);
</script>

<style scoped>
.v-row {
    padding: 0 3vw;
}

.v-col {
    padding-left: 0;
    padding-right: 0;
}

.only-you {
    padding-right: 1em;
}

.bottom-bar {
    color: rgb(var(--v-theme-text)) !important;
    background-color: rgb(var(--v-theme-bar)) !important;
    box-shadow: 0 1px 4px -1px rgb(var(--v-theme-text)) !important;
}
</style>
