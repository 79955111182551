<template>
    <Card
        v-if="
            contactData.LastName || contactData.FirstName || contactData.Phone
        "
        :title="$t('calls.personToCall')"
    >
        <template #content>
            <v-row justify="space-between" class="pb-2">
                <v-col cols="auto">
                    <Span>{{ $t("calls.salutation") }}</Span>
                </v-col>
                <v-col cols="auto">
                    <RadioButton
                        class="mr-4"
                        :text="$t('calls.mr')"
                        :selected="contactData.Salutations === 1"
                        @click="contactData.Salutations = 1"
                        :readonly="true"
                    />
                    <RadioButton
                        :text="$t('calls.mrs')"
                        :selected="contactData.Salutations === 2"
                        @click="contactData.Salutations = 2"
                        :readonly="true"
                    />
                </v-col>
            </v-row>

            <CardTextField
                v-model="contactData.LastName"
                label="calls.name"
                :readonly="true"
            />
            <CardTextField
                v-model="contactData.FirstName"
                label="calls.firstName"
                :readonly="true"
            />
            <CardTextField
                v-model="contactData.Phone"
                label="calls.phone"
                :readonly="true"
            />
            <CardTextField
                v-model="contactData.Position"
                label="calls.position"
                :readonly="true"
            />
        </template>
    </Card>
</template>

<script setup lang="ts">
import { formatPhoneNumber } from "@/commons/convertion";
import { getCallNumberForProspect } from "@/commons/reporting";
import { RadioButton, Span } from "@/components/customVuetify";
import Card from "@/components/customVuetify/Card.vue";
import CardTextField from "@/components/customVuetify/CardTextField.vue";
import { db } from "@/main";
import { doc, getDoc } from "firebase/firestore";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const props = defineProps({
    orderId: {
        type: String,
        required: true,
    },
    prospectId: {
        type: String,
        required: true,
    },
});

const callNumber = ref();

const contactData = ref({
    LastName: "",
    FirstName: "",
    Phone: "",
    Position: "",
    Salutations: 0,
});

async function getLastCallDetails() {
    if (!props.prospectId || !props.orderId) {
        return;
    }
    try {
        contactData.value = {
            LastName: "",
            FirstName: "",
            Phone: "",
            Position: "",
            Salutations: 0,
        };

        if (callNumber.value > 0) {
            const callDocRef = doc(
                db,
                "Orders",
                props.orderId,
                "Prospects",
                props.prospectId,
                "Calls",
                String(callNumber.value)
            );
            const callDoc = await getDoc(callDocRef);

            if (callDoc.exists()) {
                const callData = callDoc.data();
                if (callData.NextAction === 1) {
                    contactData.value = {
                        LastName: callData?.LastName || "",
                        FirstName: callData?.FirstName || "",
                        Phone: callData?.Phone
                            ? formatPhoneNumber(callData.Phone)
                            : "",
                        Position: callData?.Position || "",
                        Salutations: callData?.Salutation || "",
                    };
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
}

watch(
    () => props,
    async () => {
        callNumber.value = await getCallNumberForProspect(
            props.orderId,
            props.prospectId
        );
        getLastCallDetails();
    },
    { immediate: true }
);
</script>
