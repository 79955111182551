import { db } from "@/main";
import { doc, getDoc } from "firebase/firestore";

export async function getClientName(clientId: string) {
    const client = await getDoc(doc(db, "Clients", clientId));
    return client.exists() ? client.data().Name : "N/A";
}

export async function getClientFromId(clientId: string) {
    const clientRef = doc(db, "Clients", clientId);
    const clientDoc = await getDoc(clientRef);
    return clientDoc.data();
}
export async function getClientNameIdFromId(clientId: string) {
    const clientRef = doc(db, "Clients", clientId);
    const clientDoc = await getDoc(clientRef);
    if (clientDoc.exists()) {
        return {
            name: clientDoc.data().Name,
            id: clientDoc.id,
        };
    } else {
        return {
            name: "ERROR",
            id: "ERROR",
        };
    }
}
