<template>
    <Card :title="$t('calls.contactList')">
        <template #content>
            <Transition :name="transitionName" mode="out-in">
                <div :key="transitionKey">
                    <div v-if="isData">
                        <v-row justify="space-between" class="pb-2">
                            <v-col cols="auto">
                                <Span>{{ $t("calls.salutation") }}</Span>
                            </v-col>
                            <v-col cols="auto">
                                <RadioButton
                                    class="mr-4"
                                    :text="$t('calls.mr')"
                                    :selected="contact.Salutation === 1"
                                    @click="contact.Salutation = 1"
                                    :readonly="readonly"
                                />
                                <RadioButton
                                    :text="$t('calls.mrs')"
                                    :selected="contact.Salutation === 2"
                                    @click="contact.Salutation = 2"
                                    :readonly="readonly"
                                />
                            </v-col>
                        </v-row>
                        <CardTextField
                            v-model="contact.FirstName"
                            :readonly="readonly"
                            label="calls.firstName"
                        />
                        <CardTextField
                            v-model="contact.LastName"
                            :readonly="readonly"
                            label="calls.lastName"
                        />
                        <CardTextField
                            v-model="contact.Mail"
                            :readonly="readonly"
                            label="calls.mail"
                        />
                        <CardTextField
                            v-model="contact.Phone"
                            :readonly="readonly"
                            label="calls.phone"
                        />
                        <CardTextField
                            v-model="contact.Position"
                            :readonly="readonly"
                            label="calls.position"
                        />
                        <v-row
                            class="d-flex justify-center position-relative modify-infos"
                        >
                            <Span
                                class="bold position-absolute"
                                style="left: 0; padding-left: 12px"
                                >{{
                                    currentIndex + 1 + "/" + contacts.length
                                }}</Span
                            >
                            <v-btn
                                v-if="readonly"
                                class="button-bg-white"
                                prepend-icon="mdi-pencil-outline"
                                :text="$t('common.modifyInfos')"
                                @click="readonly = false"
                            />
                            <v-btn
                                v-else
                                class="button-bg-secondary"
                                :loading="isLoading || isDeleting"
                                prepend-icon="mdi-check"
                                :text="$t('common.validateModifications')"
                                @click="updateData"
                            />
                            <v-btn
                                v-if="!readonly"
                                class="icon-btn button-bg-white position-absolute"
                                style="right: 0"
                                icon="mdi-trash-can-outline"
                                height="5vh"
                                width="2.5vw"
                                :loading="isDeleting"
                                @click="deleteContact"
                            />
                        </v-row>
                    </div>
                    <div v-else>
                        <Span
                            class="center-content"
                            style="font-weight: bold"
                            >{{ $t("calls.noContacts") }}</Span
                        >
                    </div>
                </div>
            </Transition>

            <v-row class="divider-row">
                <v-col class="divider-col">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="space-between">
                    <v-btn
                        class="big-icon icon-btn button-bg-white"
                        icon="mdi-chevron-left"
                        @click="previousContact"
                        :disabled="
                            currentIndex === 0 ||
                            contacts.length === 0 ||
                            !readonly
                        "
                    />
                    <v-btn
                        class="button-bg-white"
                        prepend-icon="mdi-plus"
                        :text="$t('calls.addContact')"
                        @click="addContact"
                    />
                    <v-btn
                        class="big-icon icon-btn button-bg-white"
                        icon="mdi-chevron-right"
                        @click="nextContact"
                        :disabled="
                            currentIndex === contacts.length - 1 ||
                            contacts.length === 0 ||
                            !readonly ||
                            isCurrentContactEmpty
                        "
                    />
                </v-col>
            </v-row>
        </template>
    </Card>
</template>

<script setup lang="ts">
import { RadioButton, Span } from "@/components/customVuetify";
import Card from "@/components/customVuetify/Card.vue";
import CardTextField from "@/components/customVuetify/CardTextField.vue";
import { db } from "@/main";
import { Contact } from "@/types";
import { Salutations } from "@/types/call";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    setDoc,
} from "firebase/firestore";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const prospectId = route.params.id as string;
const readonly = ref(true);
const isLoading = ref(false);
const isData = ref(false);
const currentContactId = ref("");

const props = defineProps<{
    orderId: string;
}>();

const isCurrentContactEmpty = computed(() => {
    return (
        !contact.value.FirstName &&
        !contact.value.LastName &&
        !contact.value.Phone &&
        !contact.value.Mail &&
        !contact.value.Position
    );
});

const contact = ref<Contact>({
    Salutation: 0 as Salutations,
    FirstName: "",
    LastName: "",
    Phone: "",
    Mail: "",
    Position: "",
    DocId: "",
});

const contacts = ref<Contact[]>([]);
const currentIndex = ref(0);
const transitionName = ref("");
const transitionKey = ref(0);

const isDeleting = ref(false);

function changeState(name: string) {
    transitionName.value = name;
    transitionKey.value++;
}

async function loadContacts() {
    if (!prospectId || !props.orderId) {
        return;
    }
    const contactsCollection = collection(
        db,
        "Orders",
        props.orderId,
        "Prospects",
        prospectId,
        "Contacts"
    );

    onSnapshot(contactsCollection, (snapshot) => {
        contacts.value = snapshot.docs
            .map((doc) => ({ DocId: doc.id, ...doc.data() } as Contact))
            .filter(
                (contact) =>
                    contact.FirstName ||
                    contact.LastName ||
                    contact.Phone ||
                    contact.Mail ||
                    contact.Position
            );
        if (contacts.value.length > 0) {
            contact.value = contacts.value[currentIndex.value];
            isData.value = true;
        }
    });
}

function previousContact() {
    changeState("slide-left-fast");
    if (currentIndex.value > 0) {
        currentIndex.value--;
        contact.value = contacts.value[currentIndex.value];
    }
}

function nextContact() {
    changeState("slide-right-fast");
    if (currentIndex.value < contacts.value.length - 1) {
        currentIndex.value++;
        contact.value = contacts.value[currentIndex.value];
    }
}

function addContact() {
    contact.value = {
        Salutation: 0 as Salutations,
        FirstName: "",
        LastName: "",
        Phone: "",
        Mail: "",
        Position: "",
        DocId: "",
    };
    isData.value = true;
    readonly.value = false;
    currentIndex.value = contacts.value.length;
}

async function updateData() {
    isLoading.value = true;
    try {
        if (!prospectId || isCurrentContactEmpty.value || !props.orderId) {
            throw new Error("Invalid data");
        }

        const contactsCollection = collection(
            db,
            "Orders",
            props.orderId,
            "Prospects",
            prospectId,
            "Contacts"
        );

        const { DocId, ...contactData } = contact.value;

        if (DocId) {
            const contactDoc = doc(contactsCollection, DocId);
            await setDoc(contactDoc, contactData);
            contacts.value[currentIndex.value] = { ...contactData, DocId };
        } else {
            const contactDocRef = await addDoc(contactsCollection, contactData);
            const newDocId = contactDocRef.id;
            contact.value.DocId = newDocId;
            currentContactId.value = newDocId;
        }
    } catch (error) {
        console.error("Error updating document:", error);
    } finally {
        isLoading.value = false;
        readonly.value = true;
    }
}

async function deleteContact() {
    try {
        isDeleting.value = true;
        if (!prospectId || isCurrentContactEmpty.value || !props.orderId) {
            throw new Error("Invalid data");
        }

        const contactsCollection = collection(
            db,
            "Orders",
            props.orderId,
            "Prospects",
            prospectId,
            "Contacts"
        );
        const contactDoc = doc(contactsCollection, contact.value.DocId);

        await deleteDoc(contactDoc);
        if (contacts.value.length > 0) {
            currentIndex.value = Math.min(
                currentIndex.value,
                contacts.value.length - 1
            );
            contact.value = contacts.value[currentIndex.value];
        } else {
            isData.value = false;
        }
    } catch (error) {
        console.error("Error deleting contact:", error);
    } finally {
        isDeleting.value = false;
        readonly.value = true;
    }
}

onMounted(() => {
    loadContacts();
});
</script>

<style scoped>
.divider-row {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
}

.modify-infos {
    padding-top: 0.5rem;
}

.divider-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.db-comment-title {
    font-size: 1rem;
    font-weight: bold !important;
    padding-top: 1rem;
}
</style>
