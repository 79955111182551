<template>
    <div class="container">
        <Title :title="$t('db.title')" />
        <Filters
            :countTotalProspects="countTotalProspects"
            @applyFilters="applyFilters"
        />
        <List :orders="filteredOrders" @buttonClicked="supplyDatabase" />
    </div>
    <Localisation
        v-if="localisationDialogOpen"
        v-model="localisationDialogOpen"
        :order="selectedOrder"
        @close="localisationDialogOpen = false"
        @confirm="confirm"
    ></Localisation>
</template>

<script setup lang="ts">
import {
    getKoForOrder,
    getMailForOrder,
    getRdvForOrder,
} from "@/commons/reporting";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import router from "@/router";
import { store } from "@/store";
import { IdName, Order } from "@/types";
import {
    collection,
    collectionGroup,
    doc,
    getCountFromServer,
    onSnapshot,
    query,
    where,
} from "firebase/firestore";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";
import Localisation from "./LocalisationPopup.vue";

const { t: $t } = useI18n();

const orders = ref<any[]>([]);
const filters = ref({});
const currentClient = ref<IdName | null>(null);
const localisationDialogOpen = ref(false);
const selectedOrder = ref<Order>(null as unknown as Order);
const isLoading = ref(false);
const countTotalProspects = ref({
    totalProspects: 0,
    alreadyCalled: 0,
    leftToCall: 0,
    meetings: 0,
});

const filteredOrders = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return orders.value;
    }
    return orders.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

function confirm() {
    router.push({ name: "dbOrder", params: { id: selectedOrder.value.DocId } });
}

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

function supplyDatabase(item: any) {
    selectedOrder.value = item;
    router.push({ name: "dbOrder", params: { id: selectedOrder.value.DocId } });
}

async function addAdditionalProperties(order: any): Promise<Order> {
    const prospectsRef = collection(
        doc(db, "Orders", order.DocId),
        "Prospects"
    );

    const totalNumber = await getCountFromServer(prospectsRef);

    const callsRef = collectionGroup(db, "Calls");
    const alreadyCalled = await getCountFromServer(
        query(
            callsRef,
            where("OrderId", "==", order.DocId),
            where("FirstCall", "==", true)
        )
    );

    const rdvNumber = await getRdvForOrder(
        order.DocId,
        new Date("01/01/1970"),
        new Date()
    );

    const mailsNumber = await getMailForOrder(
        order.DocId,
        new Date("01/01/1970"),
        new Date()
    );

    const koNumber = await getKoForOrder(
        order.DocId,
        new Date("01/01/1970"),
        new Date()
    );

    return {
        ...order,
        TotalNumber: totalNumber.data().count.toString(),
        AlreadyCalled: alreadyCalled.data().count.toString(),
        LeftToCall: (
            totalNumber.data().count - alreadyCalled.data().count
        ).toString(),
        TotalRdv: rdvNumber.toString(),
        TotalMail: mailsNumber.toString(),
        TotalKo: koNumber.toString(),
    };
}

function setupFirestoreListener() {
    isLoading.value = true;
    const clientCollection = collection(db, "Orders");

    const unsubscribe = onSnapshot(clientCollection, async (snapshot) => {
        const allOrders = snapshot.docs.map(
            (doc) => ({ DocId: doc.id, ...doc.data() } as any)
        );
        orders.value = await Promise.all(
            allOrders
                .filter((order: any) => {
                    if (!currentClient.value) return;
                    return order.ClientId == currentClient.value.id;
                })
                .map(addAdditionalProperties)
        );

        countTotalProspects.value.totalProspects = orders.value.reduce(
            (sum, order) => sum + parseInt(order.TotalNumber || 0),
            0
        );
        countTotalProspects.value.alreadyCalled = orders.value.reduce(
            (sum, order) => sum + parseInt(order.AlreadyCalled || 0),
            0
        );
        countTotalProspects.value.leftToCall = orders.value.reduce(
            (sum, order) => sum + parseInt(order.LeftToCall || 0),
            0
        );
        countTotalProspects.value.meetings = orders.value.reduce(
            (sum, order) => sum + parseInt(order.TotalRdv || 0),
            0
        );

        isLoading.value = false;
    });

    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsubscribe();
        });
    });
}

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            orders.value = [];
            currentClient.value = newClient;
            setupFirestoreListener();
        }
    }
);

onMounted(() => {
    currentClient.value = store.state.currentClient;
    setupFirestoreListener();
});
</script>
