<template>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Name"
                :disableMessage="true"
                :label="$t('clients.name')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.City"
                :disableMessage="true"
                :label="$t('clients.city')"
                :clearable="true"
            ></TextField>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import TextField from "@/components/customVuetify/TextField.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["addClient", "applyFilters"]);

const filters = ref({
    Name: "",
    City: "",
});

onMounted(() => {
    emit("applyFilters", filters.value);
});
</script>
