<template>
    <v-btn icon small color="#dd2733" class="back-button" @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <img class="logo" src="@/assets/YouScale-salesLogo.png" alt="Logo" />
    <v-card class="forgot-card" elevation="4">
        <v-card-title class="text-center">{{
            $t("login.forgot")
        }}</v-card-title>
        <v-card-text>
            <v-form @submit.prevent="forgotPassword">
                <v-text-field
                    v-model="email"
                    :label="$t('login.email')"
                    required
                ></v-text-field>
                <v-btn color="primary" type="submit" block>{{
                    $t("login.send")
                }}</v-btn>
            </v-form>
        </v-card-text>
        <v-alert v-if="error" class="error" type="error" outlined>{{
            error
        }}</v-alert>
    </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import {
    getAuth,
    sendPasswordResetEmail,
    onAuthStateChanged,
} from "firebase/auth";
import router from "@/router";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const email = ref("");
const error = ref<string | null>(null);
const auth = getAuth();

const currentUser = ref(auth.currentUser);

onAuthStateChanged(auth, (user) => {
    currentUser.value = user;
});

const goBack = () => {
    router.go(-1);
};

const forgotPassword = async () => {
    try {
        await sendPasswordResetEmail(auth, email.value);
        router.push("/login");
    } catch (err) {
        error.value = err as string;
    }
};
</script>

<style>
.back-button {
    position: absolute;
    left: 40px;
    top: 40px;
}
.logo {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: auto;
}
.v-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.forgot-card {
    top: 30%;
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}
</style>
