<template>
    <v-row class="filters-padding-top">
        <v-col cols="auto">
            <v-btn
                class="button-bg-white"
                :text="$t('extracts.meetings')"
                @click="$router.push('/extracts/meetings')"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-white"
                :text="$t('extracts.mails')"
                @click="$router.push('/extracts/mails')"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn class="button-bg-secondary" :text="$t('extracts.calls')" />
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pb-0 pt-0">
            <Checkbox v-model="callsArchived">
                <template v-slot:label>
                    <v-tooltip
                        :text="$t('extracts.showCallsAdditionally')"
                        location="top"
                    >
                        <template #activator="{ props }">
                            <div v-bind="props" ref="targetRef">
                                {{ $t("extracts.showArchivedCalls") }}
                            </div>
                        </template>
                    </v-tooltip>
                </template>
            </Checkbox>
        </v-col>
    </v-row>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Company"
                :disableMessage="true"
                :label="$t('extracts.company')"
                :clearable="true"
            />
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.Contact"
                :disableMessage="true"
                :label="$t('extracts.contact')"
                :clearable="true"
            />
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.Employee"
                :disableMessage="true"
                :label="$t('extracts.employee')"
                :clearable="true"
            />
        </v-col>
        <v-spacer />
        <v-col cols="4">
            <DateFilter
                v-model:start-date="startDate"
                v-model:end-date="endDate"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import Checkbox from "@/components/customVuetify/Checkbox.vue";
import TextField from "@/components/customVuetify/TextField.vue";
import DateFilter from "@/components/DateFilter.vue";
import { store } from "@/store";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const emit = defineEmits(["applyFilters"]);

const startDate = ref(store.state.extractStartDate);
const endDate = ref(store.state.extractEndDate);
const callsArchived = ref(true);

const filters = ref({
    Company: "",
    Contact: "",
    Employee: "",
    startDate: ref(new Date(store.state.extractStartDate)),
    endDate: ref(new Date(store.state.extractEndDate)),
    ArchivedCalls: true,
});

watch(
    () => store.state.extractStartDate,
    (newVal) => {
        if (newVal !== undefined) {
            startDate.value = newVal;
        }
    }
);

watch(
    () => store.state.extractEndDate,
    (newVal) => {
        if (newVal !== undefined) {
            endDate.value = newVal;
        }
    }
);

watch(callsArchived, (newVal) => {
    filters.value.ArchivedCalls = newVal;
    emit("applyFilters", filters.value);
});

watch(startDate, (newVal) => {
    store.commit("setExtractStartDate", newVal);
    filters.value.startDate = new Date(newVal);
    emit("applyFilters", filters.value);
});

watch(endDate, (newVal) => {
    store.commit("setExtractEndDate", newVal);
    filters.value.endDate = new Date(newVal);
    emit("applyFilters", filters.value);
});

onMounted(async () => {
    emit("applyFilters", filters.value);
});
</script>
