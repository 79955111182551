<template>
    <Dialog
        v-model="createOrderOpen"
        :title="$t('orders.modify')"
        width="65vw"
        :clickOutside="!isLoading"
        :closable="!isLoading"
        @submit="editOrder"
        @close="close()"
    >
        <template #text>
            <v-row>
                <v-col cols="6">
                    <PopupTextField
                        v-model="order.Name"
                        :label="$t('orders.orderName')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="6">
                    <SectionField
                        v-model="order.Sections"
                        :label="$t('orders.section')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <DivisionField
                        v-model="order.Divisions"
                        :sections="order.Sections"
                        :disabled="!order.Sections"
                        multiple
                        :label="$t('orders.division')"
                        @newval="setNewValue($event, 'Divisions')"
                    />
                </v-col>
                <v-col cols="6">
                    <GroupField
                        v-model="order.Groups"
                        multiple
                        :divisions="order.Divisions"
                        :disabled="
                            order.Divisions === undefined ||
                            order.Divisions.length === 0
                        "
                        :label="$t('orders.group')"
                        @newval="setNewValue($event, 'Groups')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <ClassField
                        v-model="order.Classes"
                        multiple
                        :groups="order.Groups"
                        :disabled="
                            order.Groups === undefined ||
                            order.Groups.length === 0
                        "
                        :label="$t('orders.class')"
                        @newval="setNewValue($event, 'Classes')"
                    />
                </v-col>
                <v-col cols="6">
                    <ApeField
                        v-model="order.ApeCodes"
                        :label="$t('orders.apeCode')"
                        clearable
                        multiple
                        restricted
                        :classes="order.Classes"
                        :disabled="
                            order.Classes === undefined ||
                            order.Classes.length === 0
                        "
                        @newval="setNewValue($event, 'ApeCodes')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="order.Turnover"
                        :label="$t('orders.turnover')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <SelectField
                        v-model="order.SectorType"
                        :items="orderSectorType"
                        :label="$t('orders.sectorType')"
                        item-title="name"
                        item-value="id"
                        :returnObject="false"
                        :isI18n="true"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <SelectField
                        v-model="order.Phase"
                        :items="orderPhase"
                        :label="$t('orders.phase')"
                        item-title="name"
                        item-value="id"
                        :returnObject="false"
                        :isI18n="true"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" style="padding-bottom: 20px !important">
                    <div class="tree">
                        <Treeselect
                            v-model="localisation"
                            :multiple="true"
                            :options="options"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <SizeField
                        v-model="order.Sizes"
                        multiple
                        clearable
                        :label="$t('orders.sizes')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="6">
                    <SelectField
                        v-model="order.Priority"
                        :items="orderPriority"
                        :label="$t('orders.priority')"
                        item-title="name"
                        item-value="id"
                        :returnObject="false"
                        :isI18n="true"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <TextArea
                        v-model="order.Comments"
                        :label="$t('orders.comment')"
                    />
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-btn
                class="delete-button"
                @click="confirmDeleteDialog = true"
                :loading="isLoading"
                :disabled="isLoading"
                prepend-icon="mdi-delete"
                :text="$t('common.delete')"
            />
            <v-spacer></v-spacer>
            <v-btn
                class="button-bg-secondary"
                @click="confirmUnarchiveDialog = true"
                :loading="isLoading"
                :disabled="isLoading"
                prepend-icon="mdi-archive-arrow-down-outline"
                :text="$t('common.unarchive')"
            />
            <v-btn
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
                prepend-icon="mdi-check"
                :text="$t('common.edit')"
            />
        </template>
        <template #footer>
            <PasswordConfirmationDialog
                v-model="confirmUnarchiveDialog"
                :text="$t('orders.confirmArchive')"
                @success="unarchiveOrder"
                @close="confirmUnarchiveDialog = false"
            />
            <PasswordConfirmationDialog
                v-model="confirmDeleteDialog"
                :text="$t('orders.confirmDelete')"
                @success="deleteOrder"
                @close="confirmDeleteDialog = false"
            />
            <LoadingDialog
                v-model="isUnarchiving"
                :title="$t('orders.unarchivingOrder')"
                :progress="(docProgress / docCount) * 100"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import {
    ApeField,
    LoadingDialog,
    PasswordConfirmationDialog,
    SizeField,
} from "@/components";
import ClassField from "@/components/cpf/ClassField.vue";
import DivisionField from "@/components/cpf/DivisionField.vue";
import GroupField from "@/components/cpf/GroupField.vue";
import SectionField from "@/components/cpf/SectionField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import SelectField from "@/components/customVuetify/SelectField.vue";
import TextArea from "@/components/customVuetify/TextArea.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import { db } from "@/main";
import rules from "@/rules";
import {
    departmentRegionTypeMapping,
    orderPhase,
    orderPriority,
    orderSectorType,
} from "@/types";
import { defaultOrderData, Departments, Order } from "@/types/order";
import Treeselect from "@zanmato/vue3-treeselect";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const createOrderOpen = ref(false);
const confirmDeleteDialog = ref(false);
const confirmUnarchiveDialog = ref(false);

const order = ref(defaultOrderData);
const orderDocId = ref("");

const regions = new Map();
const departments = ref<Departments[]>([]);
const localisation = ref<(string | null)[]>([]);
const options = ref<
    {
        id: (string | null)[];
        label: string;
        children: { id: string; label: string }[];
    }[]
>([]);

const props = defineProps({
    orderId: {
        type: String,
        required: true,
    },
});

function setNewValue(newVal: any, objToChange: keyof Order) {
    order.value[objToChange] = newVal;
}

Object.entries(departmentRegionTypeMapping).forEach(
    ([deptCode, { region, department, type }]) => {
        const category =
            type === "DOM"
                ? "Outre-Mer - DOM"
                : type === "TOM"
                ? "Outre-Mer - TOM"
                : "Métropole";

        if (!regions.has(category)) {
            regions.set(category, {
                id: category,
                label: category,
                children: [],
            });
        }

        if (
            !regions
                .get(category)
                .children.some((sub: any) => sub.id === region)
        ) {
            regions.get(category).children.push({
                id: region,
                label: region,
                children: [],
            });
        }

        const regionGroup = regions
            .get(category)
            .children.find((sub: any) => sub.id === region);
        regionGroup.children.push({ id: deptCode, label: department });
    }
);

const treeselectOptions = Array.from(regions.values());
const listSelectedCodes = ref<string[]>([]);

function close() {
    order.value = defaultOrderData;
    emit("close");
}

const isLoading = ref(false);
const isUnarchiving = ref(false);
const docProgress = ref(0);
const docCount = ref(0);

async function editOrder() {
    try {
        isLoading.value = true;
        const orderId = orderDocId.value;
        const orderDoc = doc(db, "ArchivedOrders", orderId);
        order.value.Departments = listSelectedCodes.value.map((dept) => ({
            EmployeeID: "",
            DepartmentNumber: dept || "",
        }));
        await updateDoc(orderDoc, order.value);
        emit("close");
    } catch (error) {
        console.error("Error editing order: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function unarchiveOrder() {
    confirmUnarchiveDialog.value = false;
    isLoading.value = true;
    isUnarchiving.value = true;
    const orderId = orderDocId.value;
    const archivedOrderRef = doc(db, "ArchivedOrders", orderId);
    const orderRef = doc(db, "Orders", orderId);

    try {
        const archivedOrderDoc = await getDoc(archivedOrderRef);
        if (!archivedOrderDoc.exists()) {
            return;
        }
        const orderData = archivedOrderDoc.data();
        await setDoc(orderRef, orderData);

        const subcollections = { old: "ArchivedProspects", new: "Prospects" };
        const nestedSubcollections = [
            { old: "ArchivedCalls", new: "Calls" },
            { old: "ArchivedContacts", new: "Contacts" },
        ];

        const archivedSubcollectionRef = collection(
            archivedOrderRef,
            subcollections.old
        );
        const subcollectionRef = collection(orderRef, subcollections.new);

        const subcollectionDocs = await getDocs(archivedSubcollectionRef);
        docCount.value = subcollectionDocs.docs.length;
        docProgress.value = 0;
        for (const docSnapshot of subcollectionDocs.docs) {
            const docData = docSnapshot.data();
            await setDoc(doc(subcollectionRef, docSnapshot.id), docData);

            for (const nestedSubcollection of nestedSubcollections) {
                const nestedArchivedSubcollectionRef = collection(
                    archivedSubcollectionRef,
                    docSnapshot.id,
                    nestedSubcollection.old
                );
                const nestedSubcollectionRef = collection(
                    subcollectionRef,
                    docSnapshot.id,
                    nestedSubcollection.new
                );

                const nestedSubcollectionDocs = await getDocs(
                    nestedArchivedSubcollectionRef
                );
                for (const nestedDocSnapshot of nestedSubcollectionDocs.docs) {
                    const nestedDocData = nestedDocSnapshot.data();
                    await setDoc(
                        doc(nestedSubcollectionRef, nestedDocSnapshot.id),
                        nestedDocData
                    );
                }
                for (const nestedDocSnapshot of nestedSubcollectionDocs.docs) {
                    await deleteDoc(
                        doc(
                            nestedArchivedSubcollectionRef,
                            nestedDocSnapshot.id
                        )
                    );
                }
            }
            docProgress.value++;
        }
        for (const docSnapshot of subcollectionDocs.docs) {
            await deleteDoc(doc(archivedSubcollectionRef, docSnapshot.id));
        }

        await deleteDoc(archivedOrderRef);
        emit("close");
    } catch (error) {
        console.error("Error restoring order:", error);
    } finally {
        isLoading.value = false;
    }
}

async function deleteOrder() {
    confirmDeleteDialog.value = false;
    try {
        isLoading.value = true;
        const orderId = orderDocId.value;
        const orderDoc = doc(db, "ArchivedOrders", orderId);
        await deleteDoc(orderDoc);
        emit("close");
    } catch (error) {
        console.error("Error deleting order: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function getOrderData() {
    try {
        const orderDoc = doc(db, "ArchivedOrders", props.orderId);
        const orderSnapshot = await getDoc(orderDoc);
        if (orderSnapshot.exists()) {
            const orderInfos = orderSnapshot.data();
            order.value = {
                ...orderInfos,
                Sizes: orderInfos.Sizes || [],
                ApeCodes: orderInfos.ApeCodes || [],
                Departments: orderInfos.Departments || [],
            };
            orderDocId.value = orderSnapshot.id;
            localisation.value = orderInfos.Departments.map(
                ({ DepartmentNumber }: any) => DepartmentNumber
            );
        }
    } catch (error) {
        console.error("Error getting order data: ", error);
    }
}

watch(
    localisation,
    (newValue) => {
        const selectedCodes = newValue
            .map((item: any) => {
                if (typeof item !== "string") {
                    return [];
                }

                let lastThreeChars = item.split(" - ").pop();

                if (
                    item === "Métropole" ||
                    lastThreeChars === "DOM" ||
                    lastThreeChars === "TOM"
                ) {
                    return Object.entries(departmentRegionTypeMapping)
                        .filter(
                            ([, { type }]) =>
                                type === item || type === lastThreeChars
                        )
                        .map(([code]) => code);
                } else if (isNaN(Number(item))) {
                    return Object.entries(departmentRegionTypeMapping)
                        .filter(([, { region }]) => region === item)
                        .map(([code]) => code);
                } else {
                    return item;
                }
            })
            .flat();
        listSelectedCodes.value = selectedCodes;
    },
    { immediate: true }
);

onMounted(async () => {
    if (props.orderId) {
        await getOrderData();
    }
    departments.value = [];
    options.value = treeselectOptions;
});
</script>

<style scoped>
.v-row {
    height: auto !important;
}

.v-col {
    padding: 4px 12px !important;
}
</style>
