<template>
    <List
        :headers="headers"
        :items="users"
        :sort-by="[{ key: 'LastName', order: 'asc' }]"
        @rowClicked="editUser"
    />
    <EditUser
        v-if="selectedUser && editDialog == true"
        :userId="selectedUser"
        v-model="editDialog"
        @close="editDialog = false"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { UserList } from "@/types";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import EditUser from "./EditUser.vue";

const { t: $t } = useI18n();

defineProps({
    users: Array as () => UserList[] | DocumentData[],
});

const selectedUser: any = ref();
const editDialog = ref(false);

function editUser(user: any) {
    selectedUser.value = user.DocId;
    editDialog.value = true;
}

const headers = [
    { title: $t("users.lastname"), value: "LastName" },
    { title: $t("users.firstname"), value: "FirstName" },
    { title: $t("users.email"), value: "Mail" },
    { title: $t("users.phone"), value: "Phone" },
    { title: $t("users.company"), value: "ClientName" },
    { title: $t("users.role"), value: "Role" },
    { title: $t("users.superiorName"), value: "SuperiorName" },
];
</script>
