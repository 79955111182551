<template>
    <v-checkbox
        v-bind="$attrs"
        color="rgb(var(--v-theme-secondary))"
        class="disable-message"
    >
        <template v-slot:label>
            <slot name="label"></slot>
        </template>
    </v-checkbox>
</template>

<style scoped>
.v-checkbox {
    color: rgb(var(--v-theme-text)) !important;
}
</style>
