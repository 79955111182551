<template>
    <v-app>
        <TopBar
            v-if="isNoDisplayPage === false && user"
            :navbarOpen="toggleNavbar"
            @toggleNavbar="toggle"
            @toggleDark="toggleTheme"
        />
        <BottomBar v-if="!isBottomBar && !isNoDisplayPage && user" />
        <NavBar v-if="!isNoDisplayPage && user" v-model="toggleNavbar" />
        <v-main>
            <router-view />
        </v-main>
        <SnackBar
            :show="snackbar.show"
            :text="snackbar.message"
            :timeout="snackbar.timeout"
            :color="snackbar.color"
        />
    </v-app>
</template>

<script setup lang="ts">
import { useSnackbar } from "@/composables/useSnackbar";
import { db, vuetify } from "@/main";
import { store } from "@/store";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useTheme } from "vuetify";
import { getCurrentClient } from "./commons/firebase";
import BottomBar from "./components/BottomBar.vue";
import NavBar from "./components/NavBar.vue";
import SnackBar from "./components/SnackBar.vue";
import TopBar from "./components/TopBar.vue";

//START Stop error resizeObserver DO NOT DELETE
const debounce = (callback: (...args: any[]) => void, delay: number) => {
    let tid: any;
    return function (...args: any[]) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
            callback.apply(ctx, args);
        }, delay);
    };
};
const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
    constructor(callback: (...args: any[]) => void) {
        callback = debounce(callback, 20);
        super(callback);
    }
};
//END Stop error resizeObserver

const auth = getAuth();
const user = ref(auth.currentUser);

const currentClient = getCurrentClient();

const colors = ref({
    primary: "#000000",
    secondary: "#000000",
    background: "#000000",
    backgroundDark: "#000000",
    text: "#000000",
});

onAuthStateChanged(auth, (currentUser) => {
    user.value = currentUser;
});

const toggleNavbar = ref(false);
const route = useRoute();
const isNoDisplayPage = computed(
    () =>
        route.name === "login" ||
        route.name === "forgot" ||
        route.name === "notfound" ||
        route.name === "internalError" ||
        route.name === "forbidden" ||
        route.name === "changePassword" ||
        route.name === "callScript"
);

const isBottomBar = computed(() => route.name === "callInterface");

const toggle = () => {
    toggleNavbar.value = !toggleNavbar.value;
};

const theme = useTheme();

function toggleTheme() {
    theme.global.name.value = theme.global.current.value.dark
        ? "light"
        : "dark";
    store.commit("setTheme", theme.global.name.value);
}

watch(
    () => colors.value,
    (newColors) => {
        vuetify.theme.themes.value.light.colors.primary = newColors.primary;
        vuetify.theme.themes.value.light.colors.text = newColors.text;
        vuetify.theme.themes.value.light.colors.secondary = newColors.secondary;
        vuetify.theme.themes.value.light.colors.text_secondary =
            newColors.secondary;
        vuetify.theme.themes.value.light.colors.button = newColors.secondary;
        vuetify.theme.themes.value.light.colors.background =
            newColors.background;

        vuetify.theme.themes.value.dark.colors.primary = newColors.primary;
        vuetify.theme.themes.value.dark.colors.secondary = newColors.secondary;
        vuetify.theme.themes.value.dark.colors.background =
            newColors.backgroundDark;
        vuetify.theme.themes.value.dark.colors.button_white =
            newColors.backgroundDark;
        vuetify.theme.themes.value.dark.colors.even_row =
            newColors.backgroundDark;
    },
    { deep: true }
);

watch(currentClient, () => {
    getColors();
});

function getColors() {
    if (!currentClient.value.id) {
        return;
    }
    const clientDoc = doc(db, "Clients", currentClient.value.id);
    const unsubscribe = onSnapshot(clientDoc, (doc) => {
        if (!doc.exists() || !doc.data().Colors) {
            colors.value.primary = "#1a1d34";
            colors.value.secondary = "#5964b6";
            colors.value.background = "#f0f5f8";
            colors.value.backgroundDark = "#1a1d34";
            colors.value.text = "#1a1d34";
            return;
        }
        colors.value.primary = doc.data().Colors.Primary ?? "#1a1d34";
        colors.value.secondary = doc.data().Colors.Secondary ?? "#5964b6";
        colors.value.background = doc.data().Colors.Background ?? "#f0f5f8";
        colors.value.backgroundDark =
            doc.data().Colors.BackgroundDark ?? "#1a1d34";
        colors.value.text = doc.data().Colors.Text ?? "#1a1d34";
    });

    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsubscribe();
        });
    });
}

onMounted(() => {
    document.title = "YouScale App";
    if (!store.state.theme) {
        store.commit("setTheme", "light");
    }
    const storedTheme = store.state.theme;
    if (storedTheme) {
        theme.global.name.value = storedTheme;
    }

    getColors();
});

const { snackbar } = useSnackbar();
</script>
