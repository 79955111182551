<template>
    <v-snackbar
        v-model="showSnackbar"
        :color="color"
        :timeout="timeout"
        close-on-content-click
        bottom
    >
        {{ text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";

const props = defineProps({
    show: Boolean,
    text: String,
    timeout: {
        type: Number,
        default: 3000,
    },
    color: {
        type: String,
        default: "green",
    },
});

const showSnackbar = ref(false);

watchEffect(() => {
    showSnackbar.value = props.show;
});
</script>

<style scoped>
.v-snackbar {
    z-index: 1000;
    left: 75%;
}
</style>
