<template>
    <div class="container">
        <Title :title="$t('clients.titleArchived')" />
        <Filters @applyFilters="applyFilters" />
        <List :clients="filteredClients" />
    </div>
</template>

<script setup lang="ts">
import { formatPrice, formatSiret } from "@/commons/convertion";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { Client, Sizes } from "@/types";
import { collection, onSnapshot } from "firebase/firestore";
import { computed, onMounted, ref, watchEffect } from "vue";
import Filters from "./Filters.vue";
import List from "./List.vue";

const clients = ref<Client[]>([]);
const filters = ref({});

const filteredClients = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return clients.value;
    }
    return clients.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true; // skip filtering by id
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

onMounted(() => {
    const clientCollection = collection(db, "ArchivedClients");
    const unsubscribe = onSnapshot(clientCollection, (snapshot) => {
        clients.value = snapshot.docs.map((doc) => {
            const client = { id: doc.id, ...doc.data() } as any;
            if (client.Capital) {
                client.Capital = formatPrice(client.Capital);
            }
            if (client.Turnover) {
                client.Turnover = formatPrice(client.Turnover);
            }
            if (client.SIRET) {
                client.SIRET = formatSiret(client.SIRET);
            }
            if (client.Size) {
                client.Size = Sizes.find(
                    (size) => size.id === client.Size
                )?.name;
            }
            return client;
        });
    });

    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsubscribe();
        });
    });
});
</script>
