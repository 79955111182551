<template>
    <v-container>
        <v-row>
            <v-col cols="8">
                <v-data-table :headers="headers" :items="items">
                    <template #bottom />
                </v-data-table>
            </v-col>
            <v-col cols="4" class="text-end">
                <v-chip>{{ counter }} rdv pris aujourd'hui</v-chip>
                <v-btn @click="decreaseCount">Decrease Count</v-btn>
            </v-col>
            <v-btn @click="allowAudio = true">
                <v-icon>mdi-volume-high</v-icon>
            </v-btn>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { db } from "@/main";
import { differenceInCalendarWeeks, startOfMonth } from "date-fns";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    increment,
    onSnapshot,
    query,
    updateDoc,
} from "firebase/firestore";
import { onMounted, ref } from "vue";

const counter = ref(0);
const items = ref<{ [key: string]: string | number }[]>([]);

const audio = new Audio("../buzzer-sound.mp3");
const allowAudio = ref(false);

const decreaseCount = async () => {
    if (counter.value <= 0) return;
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const start_of_month = startOfMonth(now);
    const week_number = differenceInCalendarWeeks(now, start_of_month) + 1;

    const buzzerCollection = collection(db, "Buzzer");
    const buzzerQuery = query(buzzerCollection);

    const docSnapshot = await getDocs(buzzerQuery);
    docSnapshot.docs.forEach(async (doc) => {
        const docData = doc.data();
        if (docData.Year === year && docData.Month === month) {
            const weekField = `Week${week_number}`;
            if (docData[weekField] > 0) {
                await updateDoc(doc.ref, {
                    [weekField]: increment(-1),
                });
            }
        }
    });

    const counterDocRef = doc(db, "Buzzer", "ButtonPresses");
    const counterDoc = await getDoc(counterDocRef);
    if (counterDoc.exists() && counterDoc.data().count > 0) {
        await updateDoc(counterDocRef, {
            count: increment(-1),
        });
    }
};

onMounted(() => {
    const buzzerCollection = collection(db, "Buzzer");
    const buzzerQuery = query(buzzerCollection);

    onSnapshot(buzzerQuery, (snapshot) => {
        const weeks = [
            "Semaine 1",
            "Semaine 2",
            "Semaine 3",
            "Semaine 4",
            "Semaine 5",
        ];
        const months = ["january", "february", "march", "april", "may", "june"];
        const data: { [key: string]: { [key: string]: number | string } } = {};
        const totals: { [key: string]: number } = {};

        snapshot.docs.forEach((doc) => {
            const docData = doc.data();
            weeks.forEach((week, index) => {
                const weekNumber = `Week${index + 1}`;
                if (!data[week]) {
                    data[week] = { year: week };
                }
                if (docData[weekNumber]) {
                    const month = months[docData.Month - 1];
                    data[week][month] = docData[weekNumber];
                    totals[month] = (totals[month] || 0) + docData[weekNumber];
                }
            });
        });

        data["Total"] = { year: "Total", ...totals };
        items.value = Object.values(data);
    });

    const counterDocRef = doc(db, "Buzzer", "ButtonPresses");

    onSnapshot(counterDocRef, (doc) => {
        if (doc.exists()) {
            const prevCount = counter.value;
            const newCount = doc.data().count;
            counter.value = newCount;

            if (newCount > prevCount && allowAudio.value) {
                audio.play();
            }
        }
    });
});

const headers = [
    { title: "2024", value: "year" },
    { title: "Janvier", value: "january" },
    { title: "Février", value: "february" },
    { title: "Mars", value: "march" },
    { title: "Avril", value: "april" },
    { title: "Mai", value: "may" },
    { title: "Juin", value: "june" },
];
</script>
