<template>
    <Dialog
        v-model="createClientOpen"
        :title="$t('clients.create')"
        width="60vw"
        @submit="createClient"
        @close="close()"
    >
        <template #text>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Name"
                        :label="$t('clients.name')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupAddressField
                        v-model="client.Address"
                        :rules="[rules.required]"
                        @address-selected="onAddressSelected"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.City"
                        :label="$t('clients.city')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.PostalCode"
                        :label="$t('clients.postalCode')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Country"
                        :label="$t('clients.country')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Region"
                        :label="$t('clients.region')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Department"
                        :label="$t('clients.department')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Capital"
                        :label="$t('clients.capital')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.SIRET"
                        :label="$t('clients.siret')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.APECode"
                        :label="$t('clients.apeCode')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <SizeField
                        v-model="client.Size"
                        :label="$t('clients.size')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Turnover"
                        :label="$t('clients.turnover')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <!--v-row style="height: 100%">
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.primaryColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Primary"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.secondaryColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Secondary"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.textColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Text"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
            </v-row>
            <v-row style="height: 100%">
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.backgroundColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.Background"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <Span class="bold">{{
                                $t("clients.darkBackgroundColor")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-color-picker
                        v-model="colors.BackgroundDark"
                        width="auto"
                        :modes="['hex']"
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <v-row>
                        <v-col cols="12" class="pb-0 pt-0">
                            <v-file-input
                                v-model="logo"
                                density="compact"
                                variant="outlined"
                                :bg-color="`rgb(var(--v-theme-field))`"
                                :label="$t('clients.logo')"
                                accept="image/*"
                                :show-size="true"
                                prepend-icon=""
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            class="pb-0 pt-0 d-flex justify-center"
                        >
                            <v-img
                                v-if="client.LogoUrl"
                                :src="client.LogoUrl"
                                clearable
                                max-height="200"
                                max-width="200"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row-->
        </template>
        <template #actions>
            <v-spacer></v-spacer>
            <v-btn
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
            >
                <template v-slot:prepend>
                    <v-icon>mdi-check</v-icon>
                </template>
                {{ $t("common.create") }}
            </v-btn>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import SizeField from "@/components/SizeField.vue";
import PopupAddressField from "@/components/customVuetify/AddressField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import { db } from "@/main";
import rules from "@/rules";
import { defaultClient } from "@/types/client";
import { collection, doc, setDoc } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const createClientOpen = ref(false);

const client = ref({ ...defaultClient });

// const colors = ref({
//     Primary: "",
//     Secondary: "",
//     Text: "",
//     Background: "",
//     BackgroundDark: "",
// });
// const logo = ref<File | null>(null);
// const MAX_FILE_SIZE = 10 * 1048576;

function close() {
    client.value = { ...defaultClient };
    emit("close");
}

const isLoading = ref(false);

function onAddressSelected(addressComponents: any) {
    const route = getAddressComponent(addressComponents, "route");
    client.value.Address =
        getAddressComponent(addressComponents, "street_number") + " " + route;
    client.value.City = getAddressComponent(addressComponents, "locality");
    client.value.PostalCode = getAddressComponent(
        addressComponents,
        "postal_code"
    );
    client.value.Country = getAddressComponent(addressComponents, "country");
    client.value.Region = getAddressComponent(
        addressComponents,
        "administrative_area_level_1"
    );
    client.value.Department = getAddressComponent(
        addressComponents,
        "administrative_area_level_2"
    );
}

function getAddressComponent(components: any, type: any) {
    for (let i = 0; i < components.length; i++) {
        if (components[i].types.includes(type)) {
            return components[i].long_name;
        }
    }
    return "";
}

function generateFirestoreDocId() {
    const newDocRef = doc(collection(db, "Clients"));
    return newDocRef.id;
}

async function createClient() {
    try {
        isLoading.value = true;
        const clientId = generateFirestoreDocId();
        // if (logo.value instanceof File) {
        //     const storage = getStorage();
        //     const logoRef = storageRef(storage, `Logos/${clientId}`);
        //     await uploadBytes(logoRef, logo.value);
        //     const logoDownloadUrl = await getDownloadURL(logoRef);
        //     client.value.LogoUrl = logoDownloadUrl;
        // }
        //client.value.Colors = colors.value;
        await setDoc(doc(db, "Clients", clientId), client.value);
        close();
    } catch (error) {
        console.error("Error creating client: ", error);
    } finally {
        isLoading.value = false;
    }
}

// watch(logo, async (newLogo) => {
//     if (logo.value && logo.value.size > MAX_FILE_SIZE) {
//         alert($t("clients.fileTooLarge"));
//         logo.value = null;
//         return;
//     }
//     if (newLogo instanceof File) {
//         const url = URL.createObjectURL(newLogo);
//         client.value.LogoUrl = url;
//     }
// });
</script>
