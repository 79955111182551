<template>
    <div class="container">
        <Title :title="$t('extracts.titleMeetings')" />
        <Filters
            @applyFilters="applyFilters"
            :filteredMeetings="filteredMeetings"
        />
        <List :meetings="filteredMeetings" :loading="isLoading" />
    </div>
</template>

<script setup lang="ts">
import { formatPrice, getTrigram } from "@/commons/convertion";
import {
    getCurrentClient,
    getOrderFromId,
    getUserFromId,
} from "@/commons/firebase";
import { getRdvForDate } from "@/commons/reporting";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import { MunicipalitySizes, rdvTypes, Sizes } from "@/types";
import { doc, getDoc } from "firebase/firestore";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const meetings = ref<any>([]);
const isLoading = ref(false);
const filters = ref({
    Type: "",
    startDate: new Date(),
    endDate: new Date(),
    ArchivedMeetings: true,
});

const filteredMeetings = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return meetings.value;
    }
    return meetings.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    await getData();
}

async function getData() {
    isLoading.value = true;
    const rdvList = await getRdvForDate(
        getCurrentClient().value.id,
        filters.value.startDate,
        filters.value.endDate,
        filters.value.ArchivedMeetings
    );

    const getSizeName = (sizeId: string | undefined) => {
        if (!sizeId) return null;
        const size =
            Sizes.find((s) => s.id === sizeId) ||
            MunicipalitySizes.find((m) => m.id === sizeId);
        return size ? size.name : null;
    };

    meetings.value = await Promise.all(
        rdvList.map(async (rdv: any) => {
            const user = (await getUserFromId(rdv.EmployeeId)).data();
            let date;
            let hours;
            let minutes;
            if (rdv.AppointmentDate) {
                date = rdv.AppointmentDate.toDate();
                hours = date.getHours().toString().padStart(2, "0");
                minutes = date.getMinutes().toString().padStart(2, "0");
            }

            const order = (await getOrderFromId(rdv.OrderId)).data();

            let prospectRef = doc(
                db,
                "Orders",
                rdv.OrderId,
                "Prospects",
                rdv.ProspectId
            );
            let prospectSnap = await getDoc(prospectRef);
            let prospectData = prospectSnap.data();

            if (prospectData === undefined) {
                prospectRef = doc(
                    db,
                    "ArchivedOrders",
                    rdv.OrderId,
                    "ArchivedProspects",
                    rdv.ProspectId
                );
                prospectSnap = await getDoc(prospectRef);
                prospectData = prospectSnap.data();
            }

            return {
                Type: rdvTypes[rdv.RdvType as keyof typeof rdvTypes],
                Associate: rdv.AssociateId,
                Company: prospectData?.Name,
                Contact: rdv.FirstName + " " + rdv.LastName.toUpperCase(),
                FirstName: rdv.FirstName,
                LastName: rdv.LastName,
                Position: rdv.Position,
                Phone: rdv.Phone,
                Mail: rdv.Mail,
                DateToBeDefined: rdv.DateToBeDefined,
                Order: order?.Name,
                Comments: rdv.Comments,
                Date: date ? date.toLocaleDateString("fr-FR") : "",
                Hour: hours ? hours + ":" + minutes : "",
                DateTaken: rdv.Date.toDate().toLocaleDateString("fr-FR"),
                Employee: getTrigram(user?.FirstName, user?.LastName),
                PostalCode: prospectData?.PostalCode,
                City: prospectData?.City,
                Turnover: formatPrice(prospectData?.Turnover),
                Website: prospectData?.Website,
                Size: getSizeName(prospectData?.Size),
                OrderId: rdv.OrderId,
                ProspectId: rdv.ProspectId,
                Salesman: rdv.Salesman,
                DocId: rdv.DocId,
                Address: prospectData?.Address,
                CompanyPhone: prospectData?.Phone,
                APECode: prospectData?.APECode,
            };
        })
    );
    isLoading.value = false;
}

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            getData();
        }
    }
);

onMounted(async () => {
    await getData();
});
</script>
