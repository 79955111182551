<template>
    <span v-bind="$attrs" class="span-text">
        <slot />
    </span>
</template>

<script setup lang="ts"></script>

<style scoped>
.span-text {
    color: rgb(var(--v-theme-text)) !important;
}
</style>
